import { getRSSAppSettings } from '@/helpers/utils';

export default {
  computed: {
    isHorizontal() {
      return this.$store.state.player.layout.settings.isHorizontal;
    },

    layoutRotation() {
      return this.$store.getters.getPlayerRotation;
    },
  },

  methods: {
    getRSSAppSettings,

    defaultStyling(widget) {
      const position = widget.position[this.layoutRotation];
      const { usePixels, isHidden } = position;

      return {
        left: usePixels ? `${position.xPixels}px` : `${position.x}%`,
        top: usePixels ? `${position.yPixels}px` : `${position.y}%`,
        height: usePixels ? `${position.heightPixels}px` : `${position.height}%`,
        width: usePixels ? `${position.widthPixels}px` : `${position.width}%`,
        borderRadius: `${widget.object.borderRadius || 0}px`,
        zIndex: 100 + position.zIndex,
        zoom: this.scaleValue(1),
        opacity: isHidden ? 0 : 1,
      };
    },

    scaleValue(value) {
      const { height } = this.$store.getters.getPlayerResolution;
      const mainAxis = this.layoutRotation === 'horizontal' ? 'height' : 'width';

      const screenHeight = this.$store.state.player.screenSize[mainAxis];

      return screenHeight < height && this.$store.state.player.isPreviewPlayer
        ? (screenHeight * value) / height
        : value;
    },
  },
};
