<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'CreateTenantsWidgetModal',
  });
</script>

<script setup lang="ts">
  import { ref, computed, onMounted } from 'vue';
  import Modal from '@/components/common/Modal.vue';
  import { Address } from '@/types/widgets/tenantsWidget/tenantsWidget';
  import { Multiselect } from 'vue-multiselect';
  import { useToasted } from '@/helpers';
  import { apiGetAddresses } from '@/api/tenants';
  import { demoTenantAddress } from '@/constant/tenants';

  const emits = defineEmits<{
    (event: 'createTenantsWidget', value: { name: string; address: Address }): void;
    (event: 'closeModal', value: void): void;
  }>();

  const toasted = useToasted();

  const showModal = ref(false);
  const isSaving = ref(false);
  const widgetName = ref('Tenants');
  const organisationAddresses = ref<Address[]>([demoTenantAddress]);
  const selectedAddress = ref<Address | null>(null);
  const loading = ref(false);

  const disableOkButton = computed(() => {
    return widgetName.value.length < 3 || !selectedAddress.value;
  });

  onMounted(() => {
    showModal.value = true;
  });

  async function getAddresses() {
    try {
      loading.value = true;
      const response = await apiGetAddresses();

      organisationAddresses.value = response.map((building) => ({
        id: building.externalId,
        name: building.streetAddress,
      }));

      organisationAddresses.value.unshift(demoTenantAddress);
    } catch (error) {
      toasted.global.general_error({
        message: "Error getting your organisation's addresses",
      });
    }

    loading.value = false;
  }

  async function createTenantsWidget() {
    if (!selectedAddress.value) return;

    isSaving.value = true;

    const data = {
      name: widgetName.value,
      address: selectedAddress.value,
    };

    emits('createTenantsWidget', data);
    isSaving.value = false;
    closeModal();
  }

  function closeModal() {
    showModal.value = false;
    emits('closeModal');
  }

  getAddresses();
</script>

<template>
  <Modal
    @closeModal="closeModal"
    :isSaving="isSaving"
    title="Create Tenants Widget"
    :okFunction="createTenantsWidget"
    :modalStyles="{ width: '500px' }"
    :disabled="disableOkButton"
    avoidClickOutside
    noScrollbar
  >
    <template v-slot:body>
      <div class="group-body">
        <div class="form-group">
          <label for="widgetName">
            <div class="label">Widget Name</div>
            <input
              type="text"
              name="widgetName"
              id="widgetName"
              required
              minlength="3"
              maxlength="40"
              v-model="widgetName"
              placeholder="Set widget's name"
            />
          </label>
        </div>

        <div class="form-group">
          <BaseInput label="Address">
            <Multiselect
              :options="organisationAddresses"
              track-by="name"
              label="name"
              v-model="selectedAddress"
              placeholder="Select an Address"
              :searchable="true"
              :allow-empty="false"
              open-direction="bottom"
              :loading="loading"
              :showLabels="false"
            >
              <template v-slot="{ option }">
                <div>{{ option.name }}</div>
              </template>
              <template #noResult>
                <span>No addresses found</span>
              </template>
              <template #noOptions>
                <span>No addresses found</span>
              </template>
            </Multiselect>
          </BaseInput>
        </div>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
  .group-body {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;

    margin-right: -16px;
    padding-right: 16px;

    .form-group {
      width: 100%;
      margin-bottom: 0px;
      max-width: 480px;

      label {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        gap: 8px;

        font-weight: 500;
        line-height: 24px;

        .label {
          display: flex;
          gap: 8px;
          align-items: center;

          font-size: 14px;
        }

        input,
        select {
          padding: 8px 16px;

          border: 2px solid $borderGrey;
          border-radius: 8px;
          font-size: 16px;
        }
      }
    }

    .description {
      grid-column: 1/3;
    }
  }

  ::v-deep {
    .multiselect {
      &__select {
        padding: 0;
        z-index: 501;
      }
      &__tags {
        border: 2px solid #e8e8e8;
        border-radius: 8px;
        font-size: 16px;
        padding: 6px 16px;
        .multiselect__input {
          padding: 0;
          margin: 0;
          font-size: 16px;
          padding-right: 26px;
          box-sizing: border-box;
          z-index: 500;
          border-radius: 0;
          height: 24px;
          &::placeholder {
            color: #95a6a6;
          }
        }
        .multiselect__placeholder {
          margin: 0;
          padding: 0;
          color: #95a6a6;
        }
      }

      &__content-wrapper {
        border-width: 2px;

        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &--above {
        .multiselect__content-wrapper {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &__single {
        margin: 0;
        padding: 0;
        line-height: 24px;
      }

      .multiselect__spinner {
        background: none;
        right: 25px;

        &:before,
        &:after {
          border-top-color: $primaryRed;
        }
      }
    }
  }
</style>
