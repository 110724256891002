<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isLoading="isDeleting"
    title="Delete Confirmation"
    :okFunction="confirmDelete"
  >
    <template v-slot:body>
      <div class="delete-description">{{ confirmationText }}</div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/Modal.vue';

  export default {
    name: 'DeleteConfirmationModal',

    components: { Modal },

    props: {
      confirmationText: {
        type: String,
        default: 'Are you sure you want to delete this?',
      },
      isDeleting: {
        type: Boolean,
        default: false,
      },
      showModal: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      closeModal() {
        this.$emit('closeModal');
      },
      confirmDelete() {
        this.$emit('deletionConfirmed');
      },
    },
  };
</script>
