<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="form-group">
    <label :for="id">
      <span v-if="icon" class="material-icons-outlined align-middle icon">{{ icon }}</span>
      <span class="text">{{ label }}</span>
      <span v-if="small" class="small">{{ small }}</span>
    </label>
    <select
      :class="['form-control', { 'is-invalid': !!error }]"
      :id="id"
      @input="$emit('input', $event.target.value)"
      v-model="value"
      :disabled="disabled"
    >
      <slot>
        <option v-for="option in options" :value="option.value" :key="option.value">
          {{ option.label }}
        </option>
      </slot>
    </select>
    <div class="invalid-feedback" v-if="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BaseSelect',
    emits: ['input'],
    props: ['id', 'label', 'error', 'value', 'options', 'icon', 'small', 'disabled'],
  };
</script>

<style lang="scss" scoped>
  .form-group {
    width: 100%;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    gap: 8px;

    label {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0;

      .icon {
        color: #151515;
        font-size: 22px;
        margin-right: 8px;
      }
      .text {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
      }

      .small {
        font-size: 12px;
        color: #6a6b6a;
        margin-left: 3px;
      }
    }
    select.form-control {
      padding: 6px 16px;

      border: 2px solid $borderGrey;
      border-radius: 8px;
      font-size: 16px;

      color: #151515;
      height: auto;

      &:focus {
        box-shadow: none;
        border-color: #696969;
      }
    }
  }
</style>
