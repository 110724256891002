<template>
  <div class="layer-widget-panel scrollbar">
    <div class="form-group template-input name">
      <label for="template-name">Name</label>
      <input
        class="form-control"
        type="text"
        id="template-name"
        placeholder="Name"
        v-model="value.position.data.name"
        disabled
      />
    </div>

    <div class="template-input form-group">
      <label for="button-text-opacity">Duration (seconds):</label>
      <input
        type="number"
        class="form-control"
        id="layer-x"
        :disabled="isWidgetLockedByOverride"
        v-model="pageDuration"
      />
    </div>

    <image-mode v-model="value.position.data.imageMode" :disabled="isWidgetLockedByOverride" />

    <div class="measurements-container">
      <div class="measurements">
        <div class="form-group hidden position">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">X</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-x"
                v-model="xPosition"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">Y</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="yPosition"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>

        <div class="form-group hidden size">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-width"
                v-model="width"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">H</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="height"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="extra-icons">
        <LockIcon v-show="isWidgetLocked" :locked="isWidgetLocked" />
        <VisibilityIcon :isVisible="isWidgetVisible" @clicked="updateVisibility" />
        <PixelIcon :usePixels="usePixels" @clicked="changeValuesMode" />
      </div>
    </div>

    <button
      type="button"
      class="button-2-secondary"
      :class="{ disabled: isWidgetLockedByOverride }"
      :disabled="isWidgetLockedByOverride"
      @click="openContentBrowser"
    >
      <i class="material-icons-outlined align-middle">picture_as_pdf</i>
      Replace File
    </button>

    <ContentBrowserModal
      ref="content_browser_modal"
      @submitted="[replacePdf($event), closeModal()]"
      :allow-multiple-item-select="false"
      :filterItemTypes="appFilter"
    />
  </div>
</template>

<script>
  import PixelIcon from '@/components/layoutDesigner/editor/settingPanel/PixelIcon.vue';
  import LockIcon from '@/components/layoutDesigner/editor/settingPanel/LockIcon.vue';
  import VisibilityIcon from '@/components/layoutDesigner/editor/settingPanel/VisibilityIcon.vue';

  import ColorPicker from '@/components/ColorPicker';
  import ImageMode from '@/components/templates/editor/settingPanel/formControls/ImageMode.vue';
  import ContentBrowserModal from '@/components/content/ContentBrowserModal.vue';

  import { WIDGET_CONTENT_TYPE, WIDGET_TYPES } from '@/models/layoutDesigner';

  import widgetMixin from '@/components/templates/editor/settingPanel/widgetMixin';
  import { TEMPLATE_REPLACE_CHILD_ITEM } from '@/store/actions/templateDesigner';

  export default {
    name: 'PdfWidgetPanel',

    mixins: [widgetMixin],

    components: {
      ColorPicker,
      ImageMode,
      ContentBrowserModal,
      PixelIcon,
      LockIcon,
      VisibilityIcon,
    },

    data() {
      return {
        appFilter: [WIDGET_CONTENT_TYPE.PDF],
      };
    },

    computed: {
      pageDuration: {
        get() {
          return this.value.position.data.pageDuration || 10;
        },
        set(value) {
          this.$set(this.value.position.data, 'pageDuration', Number(value));
        },
      },
    },

    methods: {
      openContentBrowser() {
        this.$refs.content_browser_modal.open();
      },

      async replacePdf(selectedItems) {
        if (selectedItems.length === 0) return;

        const item = selectedItems[0];

        const newWidget = {
          ...this.value,
          itemType: WIDGET_TYPES.PDF,
          object: {
            ...this.value.object,
            ...item,
            type: WIDGET_TYPES.PDF,
            item_id: item.item_id,
            wid: this.value.object.wid,
            itemType: WIDGET_TYPES.PDF,
          },
          position: {
            ...this.value.position,
            data: {
              ...this.value.position.data,
              ...item,
              ...(item.pdf_content || {}),
            },
          },
        };

        await this.$store.dispatch(TEMPLATE_REPLACE_CHILD_ITEM, {
          widget: newWidget,
          newItemId: this.inOverrideMode ? this.value.object.item_id : item.item_id,
        });
      },

      closeModal() {
        this.$refs.content_browser_modal.close();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layer-widget-panel {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0px;
    padding-right: 10px;

    .button-2-secondary {
      margin-top: 24px;
      width: 100%;

      &.disabled {
        background-color: $backgroundGrey2;
      }
    }
  }
</style>
