/* eslint-disable no-bitwise */
import { generateWidgetId, WIDGET_TYPES } from '@/models/layoutDesigner';
import { globalFilters } from './filters';
import { getWidgetItemType, getRSSAppSettings } from '@/helpers/utils';
import config from '@/config';
import { getCloudfrontUrl } from '@/helpers/utils';

// Convert global filters to a mixin for use as methods
export const globalFiltersMixin = {
  methods: globalFilters.reduce((obj, item) => {
    // eslint-disable-next-line no-param-reassign
    obj[item.name] = item.filter;
    return obj;
  }, {}),
};

// https://stackoverflow.com/a/8260383/1662806
export const youtubeIdFromUrl = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};
export const getEncodedURL = (baseUrl, uri = null) => {
  if (!uri) {
    return encodeURI(baseUrl);
  }
  return `${baseUrl}/${encodeURI(uri)}`;
};

export const getSimpleType = (mimeType) => {
  let retType = 'image';

  switch (mimeType) {
    case 'image/jpeg':
      retType = 'image';
      break;
    case 'image/png':
      retType = 'image';
      break;
    case 'video/mp4':
      retType = 'video';
      break;
    case 'video/webm':
      retType = 'video';
      break;
    case 'audio/mpeg':
      retType = 'audio';
      break;
    case 'youtube':
      retType = 'youtube';
      break;
    case 'app/rss':
      retType = 'rss';
      break;
    case 'app/table':
      retType = 'table';
      break;
    case 'app/tenant':
      retType = 'tenant';
      break;
    case 'application/pdf':
      retType = 'pdf';
      break;
    case 'app/pdf':
      retType = 'pdf';
      break;
    case 'app/trafiklab':
      retType = 'trafiklab';
      break;
    case 'app/weather':
      retType = 'weather';
      break;
    case 'app/text':
      retType = 'text';
      break;
    case 'app/local-template':
      retType = 'local-template';
      break;
    case 'app/template':
      retType = 'template';
      break;
    case 'app/button':
      retType = 'button';
      break;
    case 'app/clock':
      retType = 'clock';
      break;
    case 'app/richtext':
      retType = 'richtext';
      break;
    case 'layer':
      retType = 'layer';
      break;
    default:
      retType = 'unknown';
  }

  return retType;
};

export const getTitleFromType = (mimeType) => {
  let retType = 'image';

  switch (mimeType) {
    case 'image':
      retType = 'Image';
      break;
    case 'video':
      retType = 'Video';
      break;
    case 'audio':
      retType = 'Audio';
      break;
    case 'youtube':
      retType = 'Youtube';
      break;
    case 'rss':
      retType = 'Rss';
      break;
    case 'table':
      retType = 'Table';
      break;
    case 'pdf':
      retType = 'Pdf';
      break;
    case 'pdf':
      retType = 'Pdf';
      break;
    case 'trafiklab':
      retType = 'Trafiklab';
      break;
    case 'weather':
      retType = 'Weather';
      break;
    case 'text':
      retType = 'Moving Text';
      break;
    case 'template':
      retType = 'Template';
      break;
    case 'button':
      retType = 'Button';
      break;
    case 'clock':
      retType = 'Clock';
      break;
    case 'richtext':
      retType = 'Text';
      break;
    case 'layer':
      retType = 'Layer';
      break;
    default:
      retType = '';
  }

  return retType;
};

export const getWidgetIcons = (widgetType) => {
  switch (widgetType) {
    case WIDGET_TYPES.IMAGE:
      return 'image';
    case WIDGET_TYPES.VIDEO:
      return 'play_circle_outline';
    case WIDGET_TYPES.AUDIO:
      return 'audio_file';
    case WIDGET_TYPES.APP:
      return 'apps';
    case WIDGET_TYPES.TENANTS:
      return 'table_view';
    case WIDGET_TYPES.PDF:
      return 'picture_as_pdf';
    case WIDGET_TYPES.TEXT:
      return 'text_fields';
    case WIDGET_TYPES.BUTTON:
      return 'smart_button';
    case WIDGET_TYPES.CLOCK:
      return 'access_time';
    case WIDGET_TYPES.LAYER:
      return 'layers';
    case WIDGET_TYPES.PLAYLIST:
      return 'playlist_play';
    case WIDGET_TYPES.TEMPLATE:
      return 'photo_size_select_large';
    case WIDGET_TYPES.RICH_TEXT:
      return 'edit_note';
    case WIDGET_TYPES.TRAFIKLABV2:
      return 'train';
  }
};

export const getMimes = (type) => {
  let retType = [];

  switch (type) {
    case 'image':
    case 'images':
      retType = ['image/png', 'image/jpeg'];
      break;
    case 'video':
    case 'videos':
      retType = ['video/mp4', 'video/webm', 'youtube'];
      break;
    case 'app':
    case 'apps':
      retType = ['app/rss'];
      break;
    default:
      retType = [];
  }

  return retType;
};

export const getPlaylistItemName = (playlistItem) => {
  const itemType = getSimpleType(playlistItem.item_type);

  if (itemType === 'image' || itemType === 'video') {
    const temp = playlistItem.item_url.split('/');
    return temp[temp.length - 1];
  }

  if (itemType === 'rss' || itemType === 'table') {
    return playlistItem.app.name;
  }

  return playlistItem.item_url;
};

export const openUrl = (url) => {
  window.open(url, '_blank');
};

export const isCategory = (item) => !!item.category_id && !item.playlist_id;

export const isPlaylist = (item) => !!item.playlist_id;

// Return a simple string like 'image' or 'video' for various content types
export const globalMethodsMixin = {
  data() {
    return {
      baseUrl: config.baseUrl,
    };
  },

  methods: {
    youtubeIdFromUrl,
    getSimpleType,
    getMimes,
    getEncodedURL,
    getPlaylistItemName,
    openUrl,
    isCategory,
    isPlaylist,
    getTitleFromType,
    getRSSAppSettings,
  },
};

export const simpleTypeMixin = globalMethodsMixin;

export async function getFont(font) {
  try {
    const newFont = new FontFace(font.name, `url(${getCloudfrontUrl(font.key)})`);
    document.fonts.add(newFont);
    await newFont.load();
  } catch (error) {
    console.error('error: ', error);
  }
}

export const formatTemplates = (templateWidgets) => {
  return templateWidgets.map((widget) => {
    const isAnApp = widget.object.item_type?.includes('app');

    const itemType = getWidgetItemType(widget);

    if (isAnApp) {
      if (widget.object.config?.override) delete widget.object.config.override;
      if (!!widget.object.override?.config.override) delete widget.object.override.config.override;
    }

    let layouObject = isAnApp
      ? { ...widget.object, config: undefined, ...widget.object.config }
      : widget.object;

    if (widget.object.override) {
      if (
        itemType === WIDGET_TYPES.TEXT ||
        itemType === WIDGET_TYPES.LAYER ||
        itemType === WIDGET_TYPES.RICH_TEXT ||
        itemType === WIDGET_TYPES.TENANTS ||
        (itemType === WIDGET_TYPES.APP && layouObject.item_type.includes('table'))
      ) {
        layouObject = { ...layouObject, ...layouObject.override.config };
      }
    }

    return {
      ...widget,
      assoc_id: widget.assoc_id,
      object: { ...layouObject, type: itemType },
      itemType,
    };
  });
};

export const formatTileWidgets = (tileWidgets) => {
  return tileWidgets.map((widget) => {
    const isAnApp = widget.object.item_type?.includes('app');

    const itemType = getWidgetItemType(widget);

    if (isAnApp) {
      if (widget.object.config?.override) delete widget.object.config.override;
      if (!!widget.object.override?.config.override) delete widget.object.override.config.override;
    }

    let layouObject = isAnApp
      ? {
          ...widget.object,
          ...(widget.config || {}),
          ...(widget.config?.config || {}),
          config: undefined,
        }
      : widget.object;

    if (widget.object.override?.config.position) {
      widget.position = widget.object.override.config.position;
    }

    if (widget.object.override?.config.object) {
      layouObject = {
        ...layouObject,
        ...widget.object.override.config.object,
        override: { ...widget.object.override, config: widget.object.override.config.object },
      };
    }

    const formatedTile = {
      ...widget,
      config: undefined,
      assoc_id: widget.assoc_id,
      object: { ...layouObject, type: itemType, wid: generateWidgetId() },
      itemType,
    };

    return formatedTile;
  });
};

export const getUsedTemplateIds = (layoutWidgets) => {
  const templatesUsed = Object.values(layoutWidgets).filter(
    (widget) => widget.itemType === 'template' || widget.type === 'template',
  );

  return templatesUsed.map((template) => template.object.template_id);
};

export const validationsMixin = {
  methods: {
    maxLength(maxLength) {
      function validate(value) {
        if (value.length > maxLength) {
          return [false, `Value cannot be longer than ${maxLength} characters.`];
        }
        return [true, null];
      }
      return validate;
    },
  },
};
