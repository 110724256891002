var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screens-view-container",attrs:{"id":"PlaylistsView"}},[_c('div',{staticClass:"content"},[_c('ScreenPublishStatus'),(!_vm.loadingGroups && !_vm.loadingData)?_c('div',{staticClass:"content-body"},[_c('div',{staticClass:"screen-groups"},[_c('div',{staticClass:"screen-groups-header section-header"},[_c('div',{staticClass:"header-top"},[_c('div',{staticClass:"title"},[_vm._v("Screen Groups")]),_c('i',{staticClass:"material-icons-outlined align-middle button",attrs:{"title":"Add new screen group"},on:{"click":_vm.openScreenGroupCreateModal}},[_vm._v(" add ")])]),_c('div',{staticClass:"header-bottom"},[_c('SearchButton',{model:{value:(_vm.screenGroupSearchQuery),callback:function ($$v) {_vm.screenGroupSearchQuery=$$v},expression:"screenGroupSearchQuery"}})],1)]),_c('div',{staticClass:"screen-groups-body scrollbar"},[_c('div',{staticClass:"all-screens group-card",class:{
              active: !_vm.selectedRootGroup,
              focused: !_vm.selectedRootGroup && !_vm.selectedScreen,
            },on:{"click":_vm.handleSelectAllScreens}},[_c('div',{staticClass:"all-header"},[_c('div',{staticClass:"title"},[_vm._v(" Ungrouped screens "),_c('span',[_vm._v("("+_vm._s(this.allScreensCount)+")")])]),_c('ScreenRotationIcons',{attrs:{"verticalScreens":_vm.allScreensVerticalCount,"horizontalScreens":_vm.allScreensHorizontalCount,"showEmptyScreens":true}})],1),_c('ConnectionLights',{attrs:{"connected":_vm.allScreensConnectedCount,"unknown":_vm.allScreensUnknownCount,"disconnected":_vm.allScreensDisconnectedCount,"size":12,"show-empty-lights":true}})],1),_vm._l((_vm.filteredScreenGroups.filter(
              (group) => !group.parent_id && !group.locked,
            )),function(group){return _c('GroupCard',{key:group.id,attrs:{"group":group,"all-groups":_vm.screenGroups,"related-screens":_vm.getRelatedScreens(group.id),"selected-group":_vm.selectedGroup,"selected-root-group":_vm.selectedRootGroup,"active":_vm.selectedRootGroup?.id === group.id,"focused":_vm.selectedRootGroup?.id === group.id &&
              _vm.selectedGroup?.id === group.id &&
              !_vm.selectedScreen},on:{"clickHeader":_vm.handleHeaderClicked,"selectGroup":_vm.handleGroupSelect,"deleteGroup":_vm.openDeleteGroupModal}})}),(_vm.screenGroups.length <= 0)?_c('Tip',{attrs:{"title":"Screen Groups"}},[_vm._v(" No screen groups found. Click the create screen button to add a new screen group. ")]):_vm._e()],2)]),_c('div',{staticClass:"screens"},[_c('div',{staticClass:"screens-header section-header"},[_c('div',{staticClass:"header-top"},[_c('div',{staticClass:"title"},[_vm._v("Screens")]),_c('i',{staticClass:"material-icons-outlined align-middle button",attrs:{"title":"Add new screen"},on:{"click":() => _vm.showCreateScreenModal(true)}},[_vm._v(" add ")])]),_c('div',{staticClass:"header-bottom"},[_c('i',{staticClass:"material-icons-outlined align-middle button",class:{ active: _vm.screenFiltersOpen },on:{"click":_vm.toggleScreenFilters}},[_vm._v(" filter_alt ")]),_c('SearchButton',{model:{value:(_vm.screensSearchQuery),callback:function ($$v) {_vm.screensSearchQuery=$$v},expression:"screensSearchQuery"}})],1),(_vm.screenFiltersOpen)?_c('div',{staticClass:"header-filters"},[_c('SelectFilter',{attrs:{"label":"Status","options":[
                { label: 'All', value: 'all' },
                { label: 'Connected', value: 'connected' },
                { label: 'Disconnected', value: 'disconnected' },
                { label: 'Unknown', value: 'unknown' },
              ]},model:{value:(_vm.screenStatusFilter),callback:function ($$v) {_vm.screenStatusFilter=$$v},expression:"screenStatusFilter"}}),_c('SelectFilter',{attrs:{"label":"Orientation","options":[
                { label: 'All', value: 'all' },
                { label: 'Vertical', value: 'vertical' },
                { label: 'Horizontal', value: 'horizontal' },
              ]},model:{value:(_vm.screenOrientationFilter),callback:function ($$v) {_vm.screenOrientationFilter=$$v},expression:"screenOrientationFilter"}})],1):_vm._e()]),(_vm.groupScreens.length > 0)?_c('div',{staticClass:"screens-body scrollbar"},_vm._l((_vm.filteredGroupScreens),function(screen){return _c('ScreenCard',{key:screen.id,attrs:{"screen":screen,"selected":_vm.selectedScreen?.id === screen.id,"screen-group-name":_vm.getGroup(screen.screen_group)?.name},on:{"selectScreen":_vm.handleSelectScreen}})}),1):(_vm.loadingScreens[_vm.selectedRootGroup?.id])?_c('div',{staticClass:"screens-body"},[_c('Loader')],1):_c('div',{staticClass:"screens-body scrollbar"},[_c('Tip',{attrs:{"title":"Screens"}},[_vm._v(" No screens found. Click the create screen button to add a new screen. ")])],1)])]):_c('div',{staticClass:"content-body loading"},[_c('Loader')],1)],1),(
      !_vm.loadingData &&
      !_vm.loadingGroups &&
      !_vm.selectedRootGroup &&
      !_vm.selectedScreen &&
      _vm.groupScreens.length === 0
    )?_c('ScreenPlaceholderSidebar'):(
      !_vm.loadingGroups &&
      !_vm.loadingData &&
      !_vm.selectedRootGroup &&
      !_vm.selectedScreen &&
      _vm.groupScreens.length > 0
    )?_c('ScreensSidebar'):(!_vm.loadingGroups && !_vm.loadingData && _vm.selectedRootGroup && !_vm.selectedScreen)?_c('ScreenGroupSidebar',{key:_vm.selectedGroup.id,on:{"updateGroup":_vm.handleGroupUpdated}}):(!!_vm.selectedScreen)?_c('ScreenSidebar',{key:_vm.selectedScreen?.id,attrs:{"screen":_vm.selectedScreen,"screenGroups":_vm.screenGroups}}):_vm._e(),_c('CreateGroupModal',{ref:"createGroupModal",attrs:{"screen-groups":_vm.screenGroups}}),_c('DeleteGroupModal',{ref:"deleteGroupModal",attrs:{"screenGroupName":_vm.selectedGroup?.name,"screenGroupId":_vm.selectedGroup?.id},on:{"groupDeleted":_vm.handleGroupDeleted}}),_c('DeleteScreenModal',{ref:"deleteScreenModal",attrs:{"screenName":_vm.selectedScreen?.name,"screenId":_vm.selectedScreen?.id},on:{"screenDeleted":_vm.handleScreenDeleted}}),(_vm.isShowCreateScreenModal)?_c('ScreenAddModal',{ref:"createScreenModal",attrs:{"screenGroupName":_vm.selectedGroup?.name,"screenGroupId":_vm.selectedGroup?.id,"is-open":_vm.isShowCreateScreenModal},on:{"screenAdded":_vm.fetchData,"close":() => _vm.showCreateScreenModal(false)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }