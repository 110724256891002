<template>
  <div>
    <SidebarContainer>
      <template #default>
        <div
          class="app-setting-placeholder h-100 d-flex flex-column justify-content-center bg-white align-items-center"
        >
          <h3 class="page-name text-primary-danger">SELECTED CONTENT</h3>

          <div class="mt-3">
            <p>
              Items Selected:
              <span class="font-weight-bold text-primary-danger">{{ items.length }}</span>
            </p>
          </div>
          <Select
            label="Folder"
            id="folder"
            :value="contentFolder"
            @input="setContentFolder"
            :options="folders"
          />
        </div>
      </template>
      <template #buttons>
        <Button :loading="isSaving" icon="save" @click="saveContentChanges"> Save Changes </Button>
        <Button theme="secondary" icon="arrow_right_alt" @click.native="deselectAll">{{
          items.length <= 0 ? 'Cancel' : 'Deselect All'
        }}</Button>
        <Button
          theme="secondary"
          icon="delete_outline"
          @click.native="() => (isFolder ? deleteAllFolders() : deleteAllFiles())"
          :disabled="items.length <= 0"
          >Delete {{ isFolder ? 'Folders' : 'Files' }}</Button
        >
      </template>
    </SidebarContainer>
    <MultipleContentDeleteModal
      v-if="showDeleteModal && !isFolder"
      :contents="items"
      @onClose="showDeleteModal = false"
      @onDelete="handleDeleted"
    />
    <MultipleFolderDeleteModal
      v-if="showDeleteModal && isFolder"
      :files="items"
      @onClose="showDeleteModal = false"
      @onDelete="handleDeleted"
    />
  </div>
</template>

<script>
  import MultipleContentDeleteModal from '@/components/content/MultipleContentDeleteModal.vue';
  import MultipleFolderDeleteModal from '@/components/content/MultipleFolderDeleteModal.vue';
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import Button from '@/components/common/Button.vue';
  import Select from '@/components/common/Select.vue';
  import { apiMultiMoveContent } from '@/api/content';

  export default {
    name: 'MultiSelectSettings',

    components: {
      SidebarContainer,
      MultipleContentDeleteModal,
      MultipleFolderDeleteModal,
      Button,
      Select,
    },

    props: {
      items: {
        type: Array,
        default: () => [],
      },
      isFolder: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        showDeleteModal: false,
        showDirectoryModal: false,
        contentFolder: null,
        isSaving: false,
      };
    },

    watch: {
      items(newItems) {
        if (newItems.length) {
          this.contentFolder = newItems[0].item_folder;
        }
      },
    },

    computed: {
      folders() {
        return this.$store.getters.getAllContentFolders.map((folder) => {
          return {
            label: folder.name,
            value: folder.id,
          };
        });
      },
    },

    methods: {
      deleteAllFiles() {
        this.showDeleteModal = true;
      },
      deleteAllFolders() {
        this.showDeleteModal = true;
      },
      deselectAll() {
        this.$emit('onClose');
      },
      handleDeleted() {
        this.showDeleteModal = false;
        this.$emit('onDelete');
      },
      setContentFolder(folder) {
        this.contentFolder = folder;
      },
      async saveContentChanges() {
        this.isSaving = true;
        try {
          await apiMultiMoveContent(
            this.items.map((item) => item.item_id),
            this.contentFolder,
          );
          this.$toasted.global.general_success({ message: 'Contens successfully updated' });
          this.$emit('onUpdate');
        } catch (err) {
          this.$toasted.global.general_error({ message: 'Failed to update the contents' });
        } finally {
          this.isSaving = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .btn {
    width: 200px;
  }

  .btn-transparent-blue {
    margin-bottom: 1rem;
  }

  p {
    font-size: 0.9375rem;
  }
</style>
