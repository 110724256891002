<template>
  <vue-draggable-resizable
    v-if="!hideParent"
    :parent="true"
    :x="position.left"
    :y="position.top"
    :w="position.width"
    :h="position.height"
    :draggable="!isSavingTemplate && !isEditMode && !isWidgetLocked && !isWidgetLockedByOverride"
    :resizable="!isSavingTemplate && !isEditMode && !isWidgetLocked && !isWidgetLockedByOverride"
    :grid="gridScale"
    :snap="isSnapActive"
    :active="isWidgetSelected"
    :z="(isWidgetSelected && keepZIndexUp ? 1000 : 100) + widget.position.zIndex"
    :onDragStart="(x, y) => onChangePosition(true, x, y)"
    :onResizeStart="() => onChangePosition(false)"
    @dragstop="onWidgetDrag"
    @resizestop="onWidgetResize"
    @activated="() => onSelected(widget)"
    @deactivated="handleDeactivation"
    @refLineParams="getRefLineParams"
  >
    <div class="widget-item rich-text-widget" :style="style" :class="{ 'dark-theme': darkTheme }">
      <div class="toolbar" v-show="isWidgetSelected">
        <div v-show="isWidgetLockedByOverride" class="overridden">
          <i class="material-icons-outlined button lock"> lock </i>
          Overridden by group
        </div>
        <template v-if="!isWidgetLockedByOverride">
          <template v-if="!isEditMode">
            <div class="toolbar-group">
              <!-- Z index + -->
              <div
                class="styled-button"
                title="Move Up"
                @click="!isMoveUpDisabled && changeZIndex(1)"
              >
                <i class="material-icons" :class="{ disabled: isMoveUpDisabled }">
                  keyboard_arrow_up
                </i>
              </div>
              <!-- Z index - -->
              <div
                class="styled-button"
                title="Move Down"
                @click="!isMoveDownDisabled && changeZIndex(-1)"
              >
                <i class="material-icons" :class="{ disabled: isMoveDownDisabled }">
                  keyboard_arrow_down
                </i>
              </div>
              <!-- Delete -->
              <div class="styled-button" title="Delete" @click="deleteWidget">
                <i class="material-icons-outlined">delete</i>
              </div>
              <div
                class="styled-button"
                :title="isWidgetLocked ? 'Unlock position' : 'Lock position'"
                @click="updateLock"
              >
                <i
                  class="material-icons-outlined button"
                  :class="isWidgetLocked ? 'unlock' : 'lock'"
                >
                  {{ isWidgetLocked ? 'lock_open' : 'lock' }}
                </i>
              </div>

              <div class="styled-button" title="Duplicate" @click="duplicateWidget()">
                <i class="material-icons-outlined">content_copy</i>
              </div>

              <div
                class="styled-button"
                @click="updateVisibility"
                :title="isWidgetVisible ? 'Hide on Player' : 'Show on Player'"
              >
                <i class="material-icons-outlined" :class="isWidgetVisible ? 'visible' : 'hidden'">
                  {{ isWidgetVisible ? 'visibility' : 'visibility_off' }}
                </i>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="toolbar-group">
              <div class="select-container">
                <button
                  class="select-button"
                  ref="fontFamilyButton"
                  @click="showFontFamily(!toolbar.fontFamily.isActive)"
                >
                  <BaseText :style="{ fontFamily: toolbar.fontFamily.selectedValue }">{{
                    toolbar.fontFamily.selectedValue
                  }}</BaseText>
                  <i class="material-icons">keyboard_arrow_down</i>
                </button>
                <transition name="fade-top">
                  <OnClickOutside
                    class="select-list scrollbar"
                    v-if="toolbar.fontFamily.isActive"
                    :options="{ ignore: [$refs.fontFamilyButton] }"
                    @trigger="
                      () => {
                        setFontFamily(toolbar.fontFamily.selectedValue);
                        showFontFamily(false);
                      }
                    "
                  >
                    <div class="fontfamily-section">
                      <BaseText class="fontfamily-header" variant="caption1">Default</BaseText>
                      <div
                        class="select-list-item"
                        v-for="v in toolbar.fontFamily.defaultValues"
                        :key="v"
                        @click="
                          () => {
                            setFontFamily(v);
                            showFontFamily(false);
                          }
                        "
                      >
                        <BaseText :style="{ fontFamily: v }">{{ v }}</BaseText>
                      </div>
                      <BaseDivider />
                    </div>

                    <div class="fontfamily-section">
                      <BaseText class="fontfamily-header" variant="caption1">Font</BaseText>
                      <div
                        class="select-list-item"
                        v-for="font in userFonts"
                        :key="font.id"
                        @click="
                          () => {
                            setFontFamily(font.name);
                            showFontFamily(false);
                          }
                        "
                      >
                        <BaseText :style="{ fontFamily: font.name }">{{ font.name }}</BaseText>
                      </div>
                    </div>
                  </OnClickOutside>
                </transition>
              </div>
              <div class="select-container">
                <NumberInput
                  ref="textSizeInput"
                  title="Font size"
                  :min="toolbar.textSize.min"
                  :max="toolbar.textSize.max"
                  v-model="toolbar.textSize.selectedValue"
                  @click="
                    () => {
                      if (toolbar.textSize.isActive === true) {
                        setTextSize(toolbar.textSize.selectedValue);
                      }

                      showTextSize(!toolbar.textSize.isActive);
                    }
                  "
                  @increase="setTextSize(toolbar.textSize.selectedValue)"
                  @decrease="setTextSize(toolbar.textSize.selectedValue)"
                />
                <transition name="fade-top">
                  <OnClickOutside
                    class="select-list scrollbar"
                    :style="{ left: '32px' }"
                    v-if="toolbar.textSize.isActive"
                    :options="{ ignore: [$refs.textSizeInput] }"
                    @trigger="
                      () => {
                        setTextSize(toolbar.textSize.selectedValue);
                        showTextSize(false);
                      }
                    "
                  >
                    <div
                      class="select-list-item"
                      v-for="v in toolbar.textSize.values"
                      :key="v"
                      @click="
                        () => {
                          setTextSize(v);
                          showTextSize(false);
                        }
                      "
                    >
                      <BaseText> {{ v }} </BaseText>
                    </div>
                  </OnClickOutside>
                </transition>
              </div>
              <div class="select-container">
                <button
                  class="styled-button"
                  ref="colorPickerButton"
                  title="Text color"
                  @click="showTextColor(!toolbar.textColor.isActive)"
                >
                  <BaseText
                    class="text-color-format-icon"
                    :style="{ borderBottomColor: toolbar.textColor.selectedValue }"
                    variant="h6"
                    >A
                  </BaseText>
                </button>
                <transition name="fade-top">
                  <OnClickOutside
                    class="color-picker-popover"
                    v-if="toolbar.textColor.isActive"
                    :options="{ ignore: [$refs.colorPickerButton] }"
                    @trigger="showTextColor(false)"
                  >
                    <Sketch
                      :disableAlpha="true"
                      :value="toolbar.textColor.selectedValue"
                      @input="
                        (color) => {
                          setTextColor(color.hex);
                        }
                      "
                    />
                  </OnClickOutside>
                </transition>
              </div>
            </div>
            <div class="toolbar-group">
              <!-- Bold -->
              <button
                class="typography-button"
                :class="[{ active: isBold }]"
                title="Bold"
                @click="setBold(!isBold)"
              >
                <i class="material-icons">format_bold</i>
              </button>
              <!-- Italic -->
              <button
                class="typography-button"
                :class="[{ active: isItalic }]"
                title="Italic"
                @click="setItalic(!isItalic)"
              >
                <i class="material-icons">format_italic</i>
              </button>
              <!-- Underline -->
              <button
                class="typography-button"
                :class="[{ active: isUnderline }]"
                title="Underline"
                @click="setUnderline(!isUnderline)"
              >
                <i class="material-icons">format_underlined</i>
              </button>
            </div>
            <div class="toolbar-group">
              <div class="select-container">
                <button
                  class="styled-button"
                  ref="alignButton"
                  title="Alignment"
                  @click="showAlign(!toolbar.align.isActive)"
                >
                  <i class="material-icons">{{ textAlignIcon }}</i>
                </button>
                <transition name="fade-top">
                  <OnClickOutside
                    class="select-list scrollbar"
                    v-if="toolbar.align.isActive"
                    :options="{ ignore: [$refs.alignButton] }"
                    @trigger="showAlign(false)"
                  >
                    <div
                      class="select-list-item"
                      v-for="(v, index) in toolbar.align.values"
                      @click="setTextAlign(v.id)"
                      :key="index"
                    >
                      <i class="material-icons">{{ v.icon }}</i>
                    </div>
                  </OnClickOutside>
                </transition>
              </div>
              <div class="select-container">
                <button
                  class="styled-button"
                  ref="spacingButton"
                  title="Spacing"
                  @click="showTextSpacing(!toolbar.textSpacing.isActive)"
                >
                  <span class="material-symbols-outlined"> format_line_spacing </span>
                </button>
                <transition name="fade-top">
                  <OnClickOutside
                    class="select-list scrollbar"
                    v-if="toolbar.textSpacing.isActive"
                    :options="{ ignore: [$refs.spacingButton] }"
                    @trigger="showTextSpacing(false)"
                  >
                    <div class="text-spacing-item">
                      <BaseText>Letter spacing</BaseText>
                      <NumberInput
                        :min="toolbar.letterSpacing.min"
                        :max="toolbar.letterSpacing.max"
                        :step="1"
                        v-model="toolbar.letterSpacing.selectedValue"
                        @increase="setLetterSpacing(toolbar.letterSpacing.selectedValue)"
                        @decrease="setLetterSpacing(toolbar.letterSpacing.selectedValue)"
                      />
                    </div>
                    <div class="text-spacing-item">
                      <BaseText>Line Height</BaseText>
                      <NumberInput
                        :min="toolbar.lineHeight.min"
                        :max="toolbar.lineHeight.max"
                        :step="0.01"
                        v-model="toolbar.lineHeight.selectedValue"
                        @increase="setLineHeight(toolbar.lineHeight.selectedValue)"
                        @decrease="setLineHeight(toolbar.lineHeight.selectedValue)"
                      />
                    </div>
                  </OnClickOutside>
                </transition>
              </div>
            </div>
            <div class="toolbar-group">
              <button class="styled-button" title="Clear text format" @click="clearTextFormat">
                <span class="material-symbols-outlined"> format_clear </span>
              </button>
            </div>
            <div class="toolbar-group">
              <div class="toggle-button-wrapper">
                <div class="toggle-button" title="Change theme" @click="toggleDarkTheme">
                  <span class="material-symbols-outlined toggle-icon">
                    {{ darkTheme ? 'light_mode' : 'dark_mode' }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
      <EditorContent
        v-if="isEditMode"
        ref="editorCanvas"
        :editor="editor"
        class="editor-content content-styles"
        :class="[{ isEditing: isEditMode }]"
        :style="{
          zoom: templateScaleFactor,
        }"
        @keydown.native="onEnterKeyDown"
      />
      <div v-else class="text-container">
        <div
          class="placeholder-content content-styles"
          v-html="widget.object.userText"
          @dblclick="() => setEditMode(true)"
          :style="{
            zoom: templateScaleFactor,
            fontSize: `${baseFontSize}px`,
            fontFamily: `${widget.object.textFont}, sans-serif`,
          }"
        />
      </div>
    </div>
  </vue-draggable-resizable>
</template>

<script>
  import { EditorContent } from '@tiptap/vue-2';
  import { Sketch } from 'vue-color';
  import { OnClickOutside } from '@vueuse/components';
  import {
    setFontFamily,
    setTextSize,
    setTextColor,
    setLetterSpacing,
    setLineHeight,
    tryGetFontFamily,
    tryGetTextSize,
    tryGetTextColor,
    tryGetLetterSpacing,
    tryGetLineHeight,
    hasCompleteAttributes,
    isTextStyle,
    isActive,
    createEditor,
    clearTextFormat,
    isEmptyParentNode,
    getParentNode,
    clearNodeFormat,
    setBold,
    setItalic,
    setUnderline,
  } from '@/helpers/tiptap.ts';
  import VueDraggableResizable from '@/components/common/VueDraggableResizable.vue';
  import { NumberInput } from '@/components/common/toolbar';
  import { BaseText } from '@ui/atoms/baseText';
  import { BaseDivider } from '@ui/atoms/baseDivider';
  import { REFRESH_LAYOUT_STATUS } from '@/config/constants';
  import { RICH_TEXT } from '@/constant/richText.ts';
  import {
    SET_SAVE_Z_INDEXES,
    TEMPLATE_DESIGNER_DUPLICATE_WIDGET,
    TEMPLATE_DESIGNER_SET_ZINDEX,
    UPDATE_TEMPLATE_WIDGET_STATE,
    TEMPLATE_DESIGNER_SELECT_WIDGET,
  } from '@/store/actions/templateDesigner';
  import { preProcessRichTextContentWithBaseTextSize } from '@/helpers/richText';
  import { simpleTypeMixin } from '@/helpers';
  import draggableWidget from '@/components/templates/mixins/draggableWidget';
  import { duplicateTemplateRichTextWidget } from '@/helpers/widgets/richTextWidget/richTextWidget';

  export default {
    name: 'RichTextWidget',

    mixins: [simpleTypeMixin, draggableWidget],

    components: {
      VueDraggableResizable,
      EditorContent,
      Sketch,
      BaseText,
      BaseDivider,
      OnClickOutside,
      NumberInput,
    },

    inject: ['deleteWidget'],

    props: {
      widget: {
        type: Object,
      },
    },

    data() {
      return {
        editor: null,
        isEditMode: false,
        toolbar: {
          textColor: {
            isActive: false,
            selectedValue: RICH_TEXT.DefaultFontColor,
          },
          fontFamily: {
            isActive: false,
            selectedValue: RICH_TEXT.DefaultFontFamily,
            defaultValues: [RICH_TEXT.DefaultFontFamily],
          },
          textSize: {
            isActive: false,
            selectedValue: RICH_TEXT.DefaultFontSize, // px
            min: 1,
            max: 1000,
            values: [
              8, 10, 12, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48, 56, 64, 72, 80, 88, 96, 104, 120,
              144,
            ],
          },
          textSpacing: {
            isActive: false,
          },
          letterSpacing: {
            selectedValue: RICH_TEXT.DefaultLetterSpacing, // %
            min: -0.2,
            max: 1000,
          },
          lineHeight: {
            selectedValue: RICH_TEXT.DefaultLineHeight,
            min: 0,
            max: 1000,
          },
          align: {
            isActive: false,
            values: [
              { id: 'left', icon: 'format_align_left' },
              { id: 'center', icon: 'format_align_center' },
              { id: 'right', icon: 'format_align_right' },
            ],
          },
        },
        darkTheme: false,
      };
    },

    async mounted() {
      let content = this.$props.widget.object.userText;

      // handle base text size migration
      if (this.widget.object.textSize !== -1) {
        const processedContent = preProcessRichTextContentWithBaseTextSize(
          content,
          this.widget.object.textSize,
        );

        if (processedContent) {
          content = processedContent;
        }
      }

      this.editor = createEditor({
        content: content,
        onUpdate: (_) => {
          this.handleNonEmptyNode();
          this.widget.object.userText = this.editor.getHTML();
        },
      });
    },

    beforeDestroy() {
      this.editor.destroy();
    },

    computed: {
      fixZIndexes() {
        return this.$store.state.templateDesigner.fixZIndexes;
      },

      widgetsByZIndex() {
        return this.$store.getters.getWidgetsByZIndexInTemplates;
      },

      widgetCurrentIndex() {
        return this.widgetsByZIndex.findIndex((widget) => this.widget.assoc_id === widget.assoc_id);
      },

      isMoveUpDisabled() {
        return this.widgetCurrentIndex === this.widgetsByZIndex.length - 1;
      },

      isMoveDownDisabled() {
        return this.widgetCurrentIndex === 0;
      },

      style() {
        return {
          width: '100%',
          height: '100%',
          lineHeight: 1,
          borderRadius: `${this.widget.object.borderRadius}px`,
          zIndex: 5000 + this.widget.position.zIndex,
        };
      },

      position() {
        return this.getPosition(this.widget);
      },

      isBold() {
        return this.editor.isActive('bold');
      },

      isItalic() {
        return this.editor.isActive('italic');
      },

      isUnderline() {
        return this.editor.isActive('underline');
      },

      textAlignIcon() {
        if (!this.editor) return 'format_align_left';
        if (this.editor.isActive({ textAlign: 'left' })) {
          return 'format_align_left';
        }
        if (this.editor.isActive({ textAlign: 'center' })) {
          return 'format_align_center';
        }
        if (this.editor.isActive({ textAlign: 'right' })) {
          return 'format_align_right';
        }
        return 'format_align_left';
      },

      baseFontSize() {
        return this.widget.object.textSize;
      },

      userFonts() {
        return this.$store.getters.userFonts;
      },

      rotation() {
        return this.$store.getters.getLayoutRotation;
      },

      isWidgetLocked() {
        return !!this.widget.position.isLocked;
      },

      isWidgetVisible() {
        return !this.widget?.position.isHidden;
      },

      shouldActivateEditor() {
        if (this.isWidgetSelected) {
          return (
            this.$store.state.templateDesigner.refreshingTemplateStatus ===
              REFRESH_LAYOUT_STATUS.MOVING ||
            (this.isEditMode && this.isWidgetLocked)
          );
        }
        return false;
      },

      currentCursorStyling() {
        let textStyleOrDefault = null;

        if (isActive(this.editor)) {
          let typeOrName = 'textStyle';
          if (isEmptyParentNode(this.editor)) {
            typeOrName = getParentNode(this.editor).name;
          }

          textStyleOrDefault = {
            fontFamily:
              tryGetFontFamily(this.editor, typeOrName) ?? this.toolbar.fontFamily.selectedValue,
            textSize:
              tryGetTextSize(this.editor, typeOrName) ?? this.toolbar.textSize.selectedValue,
            textColor:
              tryGetTextColor(this.editor, typeOrName) ?? this.toolbar.textColor.selectedValue,
            letterSpacing:
              tryGetLetterSpacing(this.editor, typeOrName) ??
              this.toolbar.letterSpacing.selectedValue,
            lineHeight:
              tryGetLineHeight(this.editor, typeOrName) ?? this.toolbar.lineHeight.selectedValue,
          };
        }

        return textStyleOrDefault;
      },
    },

    watch: {
      isEditMode(value) {
        if (value) {
          setTimeout(() => this.editor.commands.focus('end'), 10);
        }
      },

      currentCursorStyling(newTextStyle, oldTextStyle) {
        const shouldUpdateTextStyle =
          isTextStyle(this.editor) &&
          (!_.isEqual(newTextStyle, oldTextStyle) || !hasCompleteAttributes(this.editor));

        // explicitly apply text style when not already present such on new paragraph
        const addTextStyle = !isTextStyle(this.editor);

        if (shouldUpdateTextStyle || addTextStyle) {
          this.runTextStyleCommands(newTextStyle);
        }
      },

      isWidgetLockedByOverride() {
        const isScreenOverrideMode = !!this.$route.query.screen && !this.$route.query.group;
        const isGroupOverride = this.widget?.object.override?.screen_group;

        return !!isScreenOverrideMode && isGroupOverride;
      },
    },

    methods: {
      duplicateWidget() {
        const newWidget = duplicateTemplateRichTextWidget(this.widget);

        this.$store.commit(TEMPLATE_DESIGNER_DUPLICATE_WIDGET, newWidget);
      },

      setEditMode(state = false) {
        if (this.isEditMode) {
          this.darkTheme = false;
        }

        this.isEditMode = state;
      },

      showFontFamily(value) {
        this.toolbar.fontFamily.isActive = value;
      },

      showTextSize(value) {
        this.toolbar.textSize.isActive = value;
      },

      showTextSpacing(value) {
        const onInactivate = !this.toolbar.textSpacing.isActive;

        if (onInactivate) {
          this.setLetterSpacing(this.toolbar.letterSpacing.selectedValue);
          this.setLineHeight(this.toolbar.lineHeight.selectedValue);
        }

        this.toolbar.textSpacing.isActive = value;
      },

      showAlign(value) {
        this.toolbar.align.isActive = value;
      },

      showTextColor(value) {
        const onInactivate = !this.toolbar.textColor.isActive;

        if (onInactivate) {
          this.toolbar.textColor.selectedValue =
            tryGetTextColor(this.editor) ?? RICH_TEXT.DefaultFontColor;
        }

        this.toolbar.textColor.isActive = value;
      },

      setTextAlign(align) {
        this.editor.chain().focus().setTextAlign(align).run();
        this.toolbar.align.isActive = false;
      },

      setTextColor(hex) {
        setTextColor(this.editor, hex);
        this.toolbar.textColor.selectedValue = hex;
      },

      setTextSize(pixelSize) {
        setTextSize(this.editor, pixelSize);
        this.toolbar.textSize.selectedValue = pixelSize;
      },

      setLetterSpacing(letterSpacing) {
        setLetterSpacing(this.editor, letterSpacing / 100);
        this.toolbar.letterSpacing.selectedValue = letterSpacing;
      },

      setLineHeight(lineHeight) {
        setLineHeight(this.editor, lineHeight);
        this.toolbar.lineHeight.selectedValue = lineHeight;
      },

      setFontFamily(font) {
        setFontFamily(this.editor, font);
        this.toolbar.fontFamily.selectedValue = font;
      },

      setBold(value) {
        setBold(this.editor, value);
      },

      setItalic(value) {
        setItalic(this.editor, value);
      },

      setUnderline(value) {
        setUnderline(this.editor, value);
      },

      clearTextFormat() {
        clearTextFormat(this.editor);
        this.toolbar.fontFamily.selectedValue = RICH_TEXT.DefaultFontFamily;
        this.toolbar.textColor.selectedValue = RICH_TEXT.DefaultFontColor;
        this.toolbar.textSize.selectedValue = RICH_TEXT.DefaultFontSize;
        this.toolbar.letterSpacing.selectedValue = RICH_TEXT.DefaultLetterSpacing;
        this.toolbar.lineHeight.selectedValue = RICH_TEXT.DefaultLineHeight;
      },

      runTextStyleCommands(newTextStyle) {
        if (newTextStyle) {
          this.setFontFamily(newTextStyle.fontFamily);
          this.setTextSize(newTextStyle.textSize);
          this.setTextColor(newTextStyle.textColor);
          this.setLetterSpacing(newTextStyle.letterSpacing);
          this.setLineHeight(newTextStyle.lineHeight);
        }
      },

      onEnterKeyDown(event) {
        if (!event.shiftKey && event.key === 'Enter') {
          this.handleNewNode();
        }
      },

      handleNewNode() {
        const parentNode = getParentNode(this.editor);

        if (
          parentNode &&
          isEmptyParentNode(this.editor) &&
          (tryGetTextSize(this.editor, parentNode.type) === null ||
            tryGetLineHeight(this.editor, parentNode.type) === null ||
            tryGetLetterSpacing(this.editor, parentNode.type) === null ||
            tryGetFontFamily(this.editor, parentNode.type) === null ||
            tryGetTextColor(this.editor, parentNode.type) === null)
        ) {
          this.editor
            .chain()
            .focus()
            .updateAttributes(parentNode.type, {
              fontSize: `${this.toolbar.textSize.selectedValue}px`,
              lineHeight: `${this.toolbar.lineHeight.selectedValue}`,
              letterSpacing: `${this.toolbar.letterSpacing.selectedValue / 100}`,
              fontFamily: this.toolbar.fontFamily.selectedValue,
              color: this.toolbar.textColor.selectedValue,
            })
            .run();
        }
      },

      handleNonEmptyNode() {
        const parentNode = getParentNode(this.editor);
        // clear empty node "empty line" styling
        if (
          !isEmptyParentNode(this.editor) &&
          (tryGetTextSize(this.editor, parentNode.type) !== null ||
            tryGetLineHeight(this.editor, parentNode.type) !== null ||
            tryGetLetterSpacing(this.editor, parentNode.type) !== null ||
            tryGetFontFamily(this.editor, parentNode.type) !== null ||
            tryGetTextColor(this.editor, parentNode.type) !== null)
        ) {
          clearNodeFormat(this.editor, parentNode.type);
        }
      },

      async changeZIndex(value) {
        if (this.fixZIndexes) {
          this.fixIndexes(); // Indexes were broke for a few months so we have this kind of setting 0 0 2 3 4 6 6 that changeZIndex function can't handle
          this.$store.commit(SET_SAVE_Z_INDEXES, false);
          return;
        }

        const widgetToMove = this.widgetsByZIndex[this.widgetCurrentIndex + value];

        let oldIndex = this.widget.position.zIndex;
        let newIndex = widgetToMove.position.zIndex;

        if (newIndex === oldIndex && newIndex > 0) {
          newIndex = newIndex + value;
        }

        if (newIndex === oldIndex && oldIndex <= 0) {
          oldIndex = value > 0 ? 0 : 1;
          newIndex = value > 0 ? 1 : 0;
        }

        const widgetToMoveUpdated = {
          ...widgetToMove,
          position: {
            ...widgetToMove.position,
            zIndex: oldIndex,
          },
        };

        const currentWidget = {
          ...this.widget,
          position: {
            ...this.widget.position,
            zIndex: newIndex,
          },
        };

        this.$store.commit(TEMPLATE_DESIGNER_SET_ZINDEX, {
          widgetsToUpdate: [widgetToMoveUpdated, currentWidget],
          widgetToSelect: currentWidget,
          selectWidget: true,
        });
      },

      fixIndexes() {
        this.widgetsByZIndex.forEach((widget, index) => {
          const currentZIndex = widget.position.zIndex;

          if (currentZIndex === index) return;

          const widgetToUpdate = {
            ...widget,
            position: {
              ...widget.position,
              zIndex: index,
            },
          };

          this.$store.commit(TEMPLATE_DESIGNER_SET_ZINDEX, {
            widgetsToUpdate: [widgetToUpdate],
            widgetToSelect: widgetToUpdate,
            selectWidget: false,
          });

          if (this.widget.object.wid === widget.object.wid) {
            this.$store.commit(TEMPLATE_DESIGNER_SELECT_WIDGET, {
              ...widget,
              position: {
                ...widget.position,
                zIndex: index,
              },
            });
          }
        });
      },

      handleDeactivation() {
        this.setEditMode(false);
        this.keepZIndexUp = false;
      },

      updateLock() {
        const updatedWidget = {
          ...this.widget,
          position: {
            ...this.widget.position,
            isLocked: !this.isWidgetLocked,
          },
        };

        this.$store.commit(UPDATE_TEMPLATE_WIDGET_STATE, { widget: updatedWidget });
      },

      updateVisibility() {
        const updatedWidget = {
          ...this.widget,
          position: {
            ...this.widget.position,
            isHidden: !this.widget?.position.isHidden,
          },
        };

        this.$store.commit(UPDATE_TEMPLATE_WIDGET_STATE, { widget: updatedWidget });
      },

      toggleDarkTheme() {
        this.darkTheme = !this.darkTheme;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .styled-button {
    display: flex;
    padding: 0;
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid transparent;
    background-color: white;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #f1f1f1;
    }

    &:active {
      background-color: $borderGrey;
    }
  }

  .typography-button {
    @extend .styled-button;

    &.active {
      color: $primaryRed;
    }
  }

  .font-size-button {
    @extend .styled-button;
    min-width: 36px;
    padding: 2px;
    border-radius: 0;
    cursor: text;
  }

  .text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .select-button {
    @extend .styled-button;
    padding: 4px;
    justify-content: space-between;
    min-width: 160px;
    max-width: 160px;
    box-shadow: 0 0 0 1px $borderGrey2;

    > :first-child {
      @extend .text-ellipsis;
      float: left;
      max-width: 85%;
    }

    > :last-child {
      float: right;
      max-width: 15%;
    }
  }

  .fontfamily-section {
    display: flex;
    flex-direction: column;
  }

  .fontfamily-header {
    display: flex;
    padding: 0 16px;
    margin-bottom: 4px;
    cursor: default;
    font-weight: bold;
  }

  .select-container {
    position: relative;

    .fade-top-enter-active,
    .fade-top-leave-active {
      transition: all 0.3s ease;
    }

    .fade-top-enter {
      opacity: 0;
      transform: translateY(-20px);
    }

    .fade-top-leave-to {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  .select-list {
    z-index: 100;
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: auto;
    padding: 8px 0;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  .select-list-item {
    padding: 4px 16px;
    cursor: pointer;

    &:hover {
      background: #f1f1f1;
    }
  }

  .text-spacing-item {
    display: flex;
    flex-direction: column;
    padding: 4px 16px;
    row-gap: 4px;
  }

  .rich-text-editor {
    display: flex;
    flex-direction: column;
    border: 2px solid $borderGrey2;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .toolbar-group,
  .overridden {
    display: flex;
    gap: 4px;
  }

  .color-picker-popover {
    user-select: none;
    z-index: 101;
    position: relative;

    // v deep
    ::v-deep {
      .vc-sketch {
        position: absolute;
        right: 0;
      }
    }
  }

  .text-color-format-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 1rem;
    padding: 0 4px;
    border-bottom: 5px solid;
  }

  .rich-text-widget {
    position: relative;
  }

  .content-styles {
    ::v-deep {
      /* Headings */
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: inherit;
        margin-bottom: 0.1rem;

        &:empty:before {
          content: ' ';
          white-space: pre;
        }
      }
      h1 {
        font-size: 2em;
      }

      h2 {
        font-size: 1.5em;
      }

      h3 {
        font-size: 1.17em;
      }

      h4 {
        font-size: 1em;
      }

      h5 {
        font-size: 0.83em;
      }

      h6 {
        font-size: 0.67em;
      }

      /* Other Text Styling */
      p {
        margin-bottom: 0;
        line-height: 1.2em;

        &:empty:before {
          content: ' ';
          white-space: pre;
        }
      }

      strong {
        font-weight: bold;
      }

      em {
        font-style: italic;
      }

      u {
        text-decoration: underline;
      }

      /* Lists */
      ul,
      ol {
        margin-bottom: 1em;
        padding-left: 2em;
      }

      li {
        margin: 0.5em 0;
      }
    }
  }

  .editor-content {
    height: 100%;
    overflow: hidden;

    &.isEditing {
      cursor: auto;
    }

    ::v-deep .ProseMirror {
      height: 100%;
      padding: 4px;
      word-wrap: normal;
      white-space: normal;

      // Set basic styles for elements
      &:focus {
        outline: none;
      }
    }
  }

  .text-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .placeholder-content {
    padding: 4px;
    width: 100%;
    height: 100%;
  }

  .toolbar {
    z-index: 4000;
    position: absolute;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 4px 8px;
    width: fit-content;
    border-radius: 4px;
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    > :not(:first-child) {
      padding: 0 4px;
    }

    > :first-child {
      padding-right: 4px;
    }

    > :last-child {
      padding-right: 0;
    }

    &:hover {
      cursor: pointer;
    }

    i.disabled {
      color: $fillGrey !important;
    }
  }
  .dark-theme {
    background-color: $secondaryText;
  }
</style>
