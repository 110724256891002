import { ActionContext } from 'vuex';
import {
  GET_PUBLISH_STATUS,
  SET_PUBLISH_BAR_DISPLAY,
  SET_PUBLISH_ID,
  SET_PUBLISH_STATUS,
} from '@/store/actions/pulse';
import { PublishStatus } from '@/types/api/pulse';
import { apiGetScreenPublishStatus } from '@/api/pulse';

export interface PulseStateInterface {
  publish: {
    showPublishBar: boolean;
    publishId: string | null;
    publishStatus: Array<PublishStatus>;
  };
}

const initialState: PulseStateInterface = {
  publish: {
    showPublishBar: false,
    publishId: null,
    publishStatus: [],
  },
};

export default {
  state: initialState,
  getters: {
    getPublishId: (state: PulseStateInterface) => state.publish.publishStatus,
    getShowPublishBar: (state: PulseStateInterface) => state.publish.showPublishBar,
    getPublishStatus: (state: PulseStateInterface) => state.publish.publishStatus,
  },
  actions: {
    [SET_PUBLISH_BAR_DISPLAY]: (
      context: ActionContext<PulseStateInterface, any>,
      showPublishBar: boolean,
    ) => {
      context.commit(SET_PUBLISH_BAR_DISPLAY, showPublishBar);
    },
    [SET_PUBLISH_ID]: (context: ActionContext<PulseStateInterface, any>, publishId: string) => {
      context.commit(SET_PUBLISH_ID, publishId);
    },
    [GET_PUBLISH_STATUS]: async (context: ActionContext<PulseStateInterface, any>) => {
      try {
        if (context.state.publish.publishId) {
          const publishData = await apiGetScreenPublishStatus(context.state.publish.publishId);
          context.commit(SET_PUBLISH_STATUS, publishData);
        }
      } catch (err) {
        console.log('Error getting publish status', err);
      }
    },
  },
  mutations: {
    [SET_PUBLISH_BAR_DISPLAY]: (state: PulseStateInterface, showPublishBar: boolean) => {
      state.publish.showPublishBar = showPublishBar;
    },
    [SET_PUBLISH_ID]: (state: PulseStateInterface, publishId: string) => {
      state.publish.publishId = publishId;
    },
    [SET_PUBLISH_STATUS]: (state: PulseStateInterface, data: Array<PublishStatus>) => {
      state.publish.publishStatus = data;
    },
  },
};
