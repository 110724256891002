<template>
  <div class="template-view-container">
    <CreateTemplateFolderModal
      ref="addFolderModal"
      :parent_id="selectedFolder?.id"
      @folder-created="getTemplateFolders"
    />

    <div class="loader" v-if="showLoader && requestStatus === 'loading'">
      <Loader />
    </div>
    <div class="template" v-else>
      <div class="template-body">
        <div class="folders">
          <div class="folders-header section-header">
            <div class="header-top">
              <div class="title">Folders</div>
              <i class="material-icons-outlined align-middle button" @click="handleFolderAddModal">
                add
              </i>
            </div>
            <div class="header-bottom">
              <SearchButton v-model="folderSearchTerm" />
            </div>
          </div>
          <div class="folders-body scrollbar">
            <FolderItem
              :totals="templatesWithoutFolder"
              :isFolder="false"
              @select-folder="selectPath"
              :active="isRootFolderActive()"
            />
            <FolderItem
              v-for="folder in folders"
              :key="folder.full_path"
              :folder="folder"
              isFolder
              :active="isFolderActive(folder)"
              :selectedFolder="selectedFolder"
              :selectedRoot="selectedRoot"
              @select-folder="selectPath"
              @onDelete="handleDeleteUpdateFolder"
              @onEdit="handleDeleteUpdateFolder"
            />
          </div>
        </div>
        <div class="items">
          <div class="items-header section-header">
            <div class="header-top">
              <div class="title">Templates</div>
              <i class="material-icons-outlined align-middle button" @click="onNewTemplate">
                add
              </i>
            </div>
            <div class="header-bottom">
              <SearchButton v-model="templateSearchTerm" />
              <span
                class="material-icons-outlined button inline-block cursor-pointer ml-auto"
                title="Multiple selection"
                :class="{ 'text-primary-danger': multipleTemplates }"
                @click="handleMultipleItemSelection"
              >
                select_all
              </span>
            </div>
          </div>
          <div class="items-body scrollbar" v-if="filteredTemplates.length">
            <LayoutCard
              v-for="(template, index) in filteredTemplates"
              :key="index"
              :layout="template"
              :selected="isSelected(template)"
              showDetails
              @layoutSelected="handleLayoutSelected(template)"
            />
          </div>
        </div>
      </div>
    </div>
    <template v-if="!multipleTemplates">
      <TemplatesSidebar
        v-if="selectedTemplate"
        :template="selectedTemplate"
        @templateUpdated="getTemplateFolders(false)"
        @templateDeleted="handleTemplateDeleted"
        @updateTemplates="getTemplateFolders(false)"
        @onFolderUpdate="handleFolderUpdate"
      />

      <TemplatesSidebarPlaceholder v-else @onCreate="onNewTemplate" />
    </template>

    <template v-else>
      <MultiSelectSettings
        :items="multipleTemplateSelections"
        @onClose="cancelMultiSelection"
        @onDelete="cancelMultiSelection(true)"
        @onFolderUpdate="handleFolderUpdate"
      />
    </template>

    <Modal
      @closeModal="showNewTemplateModal = false"
      v-show="showNewTemplateModal"
      :isSaving="isSaving"
      :disabled="templateName.length < 3 || templateName.length > 40"
      :modalStyles="{ width: '400px' }"
      title="New Template"
      :okFunction="createTemplate"
      okButtonLabel="Create"
    >
      <template v-slot:body>
        <div class="form-group">
          <div class="form-group template-input name">
            <label for="template-name">Name</label>
            <input
              class="form-control"
              type="text"
              id="template-name"
              placeholder="Template's name"
              minlength="3"
              maxlength="40"
              v-model="templateName"
            />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import {
    GET_TEMPLATES_FOLDER,
    TEMPLATE_FOLDER_SEARCH_REQUEST,
  } from '@/store/actions/templateDesigner';
  import Loader from '@/components/common/Loader.vue';
  import SearchButton from '@/components/common/SearchButton.vue';
  import FolderItem from '@/components/templates/TemplateView/FolderItem.vue';
  import CreateTemplateFolderModal from '@/components/templates/CreateTemplateFolderModal.vue';
  import { CONTENT_TYPE_FOLDER } from '@/constant/const';
  import TemplatesSidebar from '@/components/templates/TemplatesSidebar.vue';
  import TemplatesSidebarPlaceholder from '@/components/templates/TemplatesSidebarPlaceholder.vue';
  import { TEMPLATE_DEFAULT_DATA } from '@/models/templates';
  import { apiCreateTemplate, apiUpdateTemplate } from '@/api/templates';
  import Modal from '@/components/common/Modal.vue';
  import LayoutCard from '@/components/layouts/LayoutCard';
  import MultiSelectSettings from '@/components/templates/MultiSelectSettings.vue';

  export default {
    name: 'TemplatesFolderView',
    components: {
      Loader,
      SearchButton,
      FolderItem,
      CreateTemplateFolderModal,
      TemplatesSidebarPlaceholder,
      TemplatesSidebar,
      Modal,
      LayoutCard,
      MultiSelectSettings,
    },

    data() {
      return {
        showNewTemplateModal: false,
        folderSearchTerm: '',
        templateSearchTerm: '',
        selectedFolder: null,
        selectedRoot: null,
        selectedTemplate: null,
        isSaving: false,
        templateName: '',
        showLoader: true,
        multipleTemplates: false,
        multipleTemplateSelections: [],
      };
    },

    watch: {
      requestStatus(status) {
        if (status === 'error') {
          this.$toasted.global.general_error({ message: 'Error Loading Templates' });
        }
      },
      folderSearchTerm(val) {
        this.$store.dispatch(TEMPLATE_FOLDER_SEARCH_REQUEST, val || '');
      },
    },

    mounted() {
      this.getTemplateFolders();
    },

    computed: {
      ...mapState({
        templates: (state) => state.templateDesigner.templatesFolder,
        requestStatus: (state) => state.templateDesigner.requestStatus,
      }),
      ...mapGetters({ folders: 'getFolderDetails' }),
      templatesWithoutFolder() {
        return this.templates.filter((item) => item.content_type !== CONTENT_TYPE_FOLDER).length;
      },
      filteredTemplates() {
        let templates = [];
        if (this.selectedFolder) {
          templates = this.findSelectedFolderTemplates(this.templates) ?? [];
        } else {
          templates = this.templates;
        }
        templates = templates.filter((item) => {
          let [filter1, filter2] = [true, true];
          filter1 = item.content_type !== CONTENT_TYPE_FOLDER;
          if (this.templateSearchTerm) {
            filter2 = item.name?.toLowerCase()?.includes(this.templateSearchTerm.toLowerCase());
          }
          return filter1 && filter2;
        });
        return templates.map((item) => {
          return {
            ...item,
            image: item.settings.image,
          };
        });
      },
    },

    methods: {
      async getTemplateFolders(showLoader = true) {
        this.showLoader = showLoader;
        try {
          await this.$store.dispatch(GET_TEMPLATES_FOLDER, {
            filterByRole: true,
          });
        } catch (error) {
          console.log('TemplatesFolderView.vue - getTemplateFolders error: ', error);
        }
        this.showLoader = true;
      },

      handleFolderAddModal() {
        this.$refs.addFolderModal.open();
      },
      selectPath(root, folder) {
        if (!root) {
          this.selectedRoot = null;
          this.selectedFolder = null;
          return;
        }
        this.selectedFolder = folder;
        this.selectedRoot = root;
        this.selectedTemplate = null;
      },
      isRootFolderActive() {
        return this.selectedFolder === null;
      },

      isFolderActive(folder) {
        return folder.full_path === this.selectedRoot?.full_path;
      },
      handleTemplateDeleted() {
        this.selectedTemplate = null;
        this.getTemplateFolders(false);
      },
      onNewTemplate() {
        this.showNewTemplateModal = true;
      },
      async updateTemplate(templateData) {
        try {
          const templateSettings = {
            ...templateData.settings,
            appId: templateData.app_id,
          };

          const successful = await apiUpdateTemplate(templateData.template_id, templateSettings);
        } catch (error) {
          console.log('TemplatesFolderView.vue - updateTemplate error: ', error);
        }
      },
      async createTemplate() {
        this.isSaving = true;
        try {
          const templateData = { ...TEMPLATE_DEFAULT_DATA, name: this.templateName };
          const response = await apiCreateTemplate(templateData, this.selectedFolder?.id);
          if (response) {
            await this.updateTemplate(response.data);
            this.$router.push({
              name: 'Template',
              params: { templateId: response.data.template_id },
            });
            this.closeModal();
          }
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }
        this.isSaving = false;
      },
      closeModal() {
        this.showNewTemplateModal = false;
      },
      handleMultipleItemSelection() {
        if (this.multipleTemplates) {
          this.cancelMultiSelection();
          return;
        }
        this.selectedTemplate = null;
        this.multipleTemplates = true;
      },
      cancelMultiSelection() {
        this.multipleTemplateSelections = [];
        this.multipleTemplates = false;
        this.getTemplateFolders(false);
      },
      handleFolderUpdate() {
        this.selectedTemplate = null;
        this.getTemplateFolders(false);
        this.multipleTemplateSelections = [];
      },
      findSelectedFolderTemplates(nestedItems) {
        for (const currentItem of nestedItems) {
          if (currentItem.content_type === CONTENT_TYPE_FOLDER) {
            if (currentItem.id === this.selectedFolder?.id) {
              return currentItem.contents;
            } else {
              const nestedResult = this.findSelectedFolderTemplates(currentItem.contents);
              if (nestedResult) {
                return nestedResult;
              }
            }
          }
        }
      },
      handleLayoutSelected(template) {
        if (!this.multipleTemplates) {
          this.selectedTemplate = template;
          this.multipleTemplateSelections = [];
        } else {
          this.selectedTemplate = null;
          const alreadyAdded = this.multipleTemplateSelections.find(
            (item) => item.template_id === template.template_id,
          );
          if (alreadyAdded) {
            this.multipleTemplateSelections = this.multipleTemplateSelections.filter(
              (item) => item.template_id !== template.template_id,
            );
          } else {
            this.multipleTemplateSelections.push(template);
          }
        }
      },
      isSelected(template) {
        if (!this.multipleTemplates) {
          return this.selectedTemplate?.template_id === template.template_id;
        } else {
          return !!this.multipleTemplateSelections.find(
            (item) => template.template_id === item.template_id,
          );
        }
      },
      handleDeleteUpdateFolder() {
        this.getTemplateFolders(false);
        this.selectedFolder = null;
        this.selectedRoot = null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
  }

  .template-view-container {
    font-family: 'Poppins';
    display: grid;
    grid-template-columns: auto 376px;
    width: 100%;
    height: 100vh;
    color: #151515;
    background-color: #f7f8f7;
    .template {
      height: 100%;
      overflow: hidden;
      .template-body {
        padding: 48px 10px 0px 24px;
        display: flex;
        height: 100%;
        overflow: hidden;
        .folders,
        .items {
          width: 50%;
        }
        .items {
          border-left: 2px solid #f0f0f0;
        }

        .folders {
          display: flex;
          flex-direction: column;
          .folders-header {
            box-sizing: border-box;
            padding: 0px 16px;
          }
          .folders-body {
            flex: 1;
            box-sizing: border-box;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 28px 16px;
          }
        }

        .items {
          display: flex;
          flex-direction: column;
          .items-header {
            padding: 0px 16px;
          }
          .items-body {
            flex: 1;
            padding: 28px 16px;
            display: grid;
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            grid-template-columns: repeat(2, minmax(1px, 1fr));
            align-content: start;
            align-items: stretch;
            gap: 16px;
            justify-items: center;
            .item {
              height: fit-content;
              width: 100%;
            }
          }
        }
      }
    }

    .section-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 4px;
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
      font-family: 'Whyte Inktrap';
      .header-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          color: #6a6b6a;
        }
      }

      .header-bottom {
        display: flex;
        align-items: center;
        vertical-align: middle;
        color: #6a6b6a;
        gap: 10px;

        .filter-icon {
          height: 30px;
        }
      }
    }
  }
</style>
