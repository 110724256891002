import axios from 'axios';
import config from '@/config';
import {
  IntegrationTypes,
  OrganisationIntegration,
  TenantIntegrationSettings,
} from '@/types/api/integrations';

export async function apiGetOrganisationIntegrations(
  organisationId: string,
): Promise<Array<OrganisationIntegration>> {
  const response = await axios.get(`${config.apiUrl}adminapi/integrations/${organisationId}`);
  return response.data;
}

export async function apiSaveOrganisationIntegration(
  integrationType: IntegrationTypes,
  organisationId: string,
  settings: TenantIntegrationSettings,
): Promise<Array<OrganisationIntegration>> {
  const response = await axios.post(
    `${config.apiUrl}adminapi/integrations/${integrationType}/${organisationId}`,
    settings,
  );
  return response.data;
}

export async function apiDeleteOrganisationIntegration(
  integrationType: IntegrationTypes,
  organisationId: string,
): Promise<Array<OrganisationIntegration>> {
  const response = await axios.delete(
    `${config.apiUrl}adminapi/integrations/${integrationType}/${organisationId}`,
  );
  return response.data;
}
