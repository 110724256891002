<template>
  <div class="button-widget-panel scrollbar">
    <div class="form-group layout-input name">
      <label for="button-name">Button Name</label>
      <input
        class="form-control"
        type="text"
        id="button-name"
        placeholder="Name"
        maxlength="40"
        v-model="value.object.name"
      />
    </div>

    <div class="form-group whole-screen">
      <div class="custom-control custom-switch layouts">
        <input
          type="checkbox"
          class="custom-control-input"
          id="button-whole-screen"
          :value="value.object.wholeScreen"
          @input="onWholeScreenToggle"
        />
        <label class="custom-control-label" for="button-whole-screen">Whole screen</label>
      </div>
    </div>

    <div class="form-group load-image">
      <div class="custom-control custom-switch layouts pb-2">
        <input
          type="checkbox"
          class="custom-control-input"
          id="button-load-image"
          v-model="value.object.loadImage"
        />
        <label class="custom-control-label" for="button-load-image">Load my image</label>
      </div>
    </div>

    <content-selector
      v-show="value.object.loadImage"
      label="Image"
      v-model="buttonImageContent"
      :filterItemTypes="['image']"
      @update="onButtonImage"
    />

    <image-mode v-model="value.object.imageMode" label="Image Display Mode" />

    <div class="measurements-container">
      <div class="measurements">
        <div class="form-group hidden position">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">X</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-x"
                v-model="xPosition"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">Y</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="yPosition"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-width"
                v-model="width"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">H</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="height"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>

          <div class="form-row w-50">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">R</span>
              </div>
              <input type="number" class="form-control" v-model="value.object.borderRadius" />
            </div>
          </div>
        </div>
      </div>

      <div class="extra-icons">
        <LockIcon v-show="isWidgetLocked" :locked="isWidgetLocked" />
        <VisibilityIcon :isVisible="isWidgetVisible" @clicked="updateVisibility" />
        <PixelIcon :usePixels="usePixels" @clicked="changeValuesMode" />
      </div>
    </div>

    <div class="sub-section-title">Text</div>

    <div class="form-group hidden">
      <div class="form-row text-css">
        <div class="col">
          <color-picker input-el-id="button-text-color" v-model="value.object.textColor" />
        </div>

        <div class="col input-group">
          <div class="input-label">
            <span class="input-group-text">
              <i class="material-icons-outlined align-middle">opacity</i>
            </span>
          </div>
          <input
            type="number"
            class="form-control"
            id="button-text-opacity"
            v-model="value.object.opacity"
          />
        </div>
      </div>
    </div>

    <div class="sub-section-title">Background</div>

    <div class="form-group hidden button-css">
      <div class="form-row">
        <div class="col">
          <color-picker input-el-id="button-color" v-model="value.object.buttonColor" />
        </div>

        <div class="col input-group">
          <div class="input-label">
            <span class="input-group-text">
              <i class="material-icons-outlined align-middle">opacity</i>
            </span>
          </div>
          <input
            type="number"
            class="form-control"
            id="button-opacity"
            v-model="value.object.buttonOpacity"
          />
        </div>
      </div>
    </div>

    <div class="form-group layout-input text">
      <label for="button-text">Button Text</label>
      <input type="text" class="form-control" id="button-text" v-model="value.object.text" />
    </div>

    <div class="form-group layout-input text-size input-la">
      <label for="text-size">Text size</label>

      <div class="input-group">
        <input
          type="number"
          min="0"
          max="100"
          class="form-control"
          id="text-size"
          v-model="textSize"
          ref="txtSizeInput"
        />
      </div>
    </div>

    <div class="form-group layout-input content-type-input">
      <label>Content Type</label>
      <select
        class="custom-select"
        id="content-mode"
        v-model="contentType"
        @change="clearContentSelection"
      >
        <option value="media-content">Media Content</option>
        <option value="url">URL</option>
      </select>
    </div>

    <content-selector
      v-show="contentType === 'media-content'"
      v-model="value.object"
      :filterItemTypes="['image', 'video']"
    ></content-selector>

    <div v-show="contentType === 'url'" class="form-group text-size">
      <label for="text-content-url">URL</label>
      <input
        v-model="value.object.contentLink"
        type="text"
        class="form-control"
        id="text-content-url"
      />
    </div>

    <div class="form-group layout-input idle-timeout">
      <label for="button-idle-timeout">Idle timeout</label>
      <input
        type="time"
        class="form-control"
        id="button-idle-timeout"
        v-model="value.object.idleTimeout"
      />
    </div>

    <hr class="separator" />

    <div class="close-button-form" v-show="value.object.closeButton">
      <div class="form-group whole-screen">
        <div class="custom-control custom-switch layouts">
          <input
            type="checkbox"
            class="custom-control-input"
            id="button-close-button"
            v-model="value.object.closeButton"
          />
          <label class="custom-control-label" for="button-close-button">Close button</label>
        </div>
      </div>

      <div class="form-group hidden close-button-position">
        <div class="form-row">
          <div class="input-group col">
            <div class="input-label">
              <span class="input-group-text">X</span>
            </div>
            <input
              type="number"
              class="form-control"
              id="button-close-x"
              v-model="value.object.closeButtonX"
            />
          </div>

          <div class="input-group col">
            <div class="input-label">
              <span class="input-group-text">Y</span>
            </div>
            <input type="number" class="form-control" v-model="value.object.closeButtonY" />
          </div>
        </div>

        <div class="form-row">
          <div class="input-group col">
            <div class="input-label">
              <span class="input-group-text">D</span>
            </div>
            <input type="number" class="form-control" v-model="value.object.closeButtonDiameter" />
          </div>
        </div>
      </div>

      <div class="sub-section-title">Symbol color</div>

      <div class="form-group hidden close-button-symbol-color">
        <div class="form-row">
          <color-picker
            class="col"
            input-el-id="button-close-symbol-color"
            v-model="value.object.closeButtonSymbolColor"
          />

          <div class="input-group col">
            <div class="input-label">
              <span class="input-group-text">
                <i class="material-icons-outlined align-middle">opacity</i>
              </span>
            </div>
            <input
              type="number"
              class="form-control"
              v-model="value.object.closeButtonSymbolOpacity"
            />
          </div>
        </div>
      </div>

      <div class="form-group hidden close-button-background-color">
        <div class="sub-section-title">Background</div>
        <div class="form-row">
          <color-picker
            class="col"
            input-el-id="button-close-background-color"
            v-model="value.object.closeButtonBackgroundColor"
          />

          <div class="input-group col">
            <div class="input-label">
              <span class="input-group-text">
                <i class="material-icons-outlined align-middle">opacity</i>
              </span>
            </div>
            <input
              type="number"
              class="form-control"
              v-model="value.object.closeButtonBackgroundOpacity"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import ColorPicker from '@/components/ColorPicker.vue';
  import ContentSelector from '@/components/layoutDesigner/editor/ContentSelector';
  import ImageMode from '@/components/layoutDesigner/editor/settingPanel/formControls/ImageMode';
  import PixelIcon from '@/components/layoutDesigner/editor/settingPanel/PixelIcon.vue';
  import LockIcon from '@/components/layoutDesigner/editor/settingPanel/LockIcon.vue';
  import VisibilityIcon from '@/components/layoutDesigner/editor/settingPanel/VisibilityIcon.vue';

  import widgetMixin from './widgetMixin';

  import { WIDGET_CONTENT_TYPE } from '@/models/layoutDesigner';

  export default {
    name: 'ButtonWidgetPanel',

    mixins: [widgetMixin],

    components: {
      ColorPicker,
      ContentSelector,
      PixelIcon,
      ImageMode,
      LockIcon,
      VisibilityIcon,
    },

    data() {
      return {
        // to be used for selecting button image
        buttonImageContent: {
          contentObj: this.value.object.imageContentObj,
          contentLink: this.value.object.imageUrl,
          contentType: WIDGET_CONTENT_TYPE.IMAGE,
        },
        contentType: 'media-content',
      };
    },

    computed: {
      ...mapGetters(['wholeScreenButton']),

      textSize: {
        get() {
          return this.value.object.textSize;
        },
        set(value) {
          this.value.object.textSize = Number(value);
        },
      },
    },

    methods: {
      init() {
        if (this.value.object.contentType === WIDGET_CONTENT_TYPE.URL) {
          this.buttonImageContent = {
            contentObj: {},
            contentLink: this.value.object.contentLink,
            contentType: WIDGET_CONTENT_TYPE.URL,
          };
          this.contentType = WIDGET_CONTENT_TYPE.URL;
        } else {
          this.buttonImageContent = {
            contentObj: this.value.object.imageContentObj,
            contentLink: this.value.object.imageUrl,
            contentType: WIDGET_CONTENT_TYPE.IMAGE,
          };
        }
      },

      clearContentSelection() {
        this.value.object.imageContentObj = {};
        this.value.object.contentObj = {};
        this.value.object.contentLink = '';
        if (this.contentType === WIDGET_CONTENT_TYPE.URL) {
          this.value.object.contentType = WIDGET_CONTENT_TYPE.URL;
        }
      },

      onWholeScreenToggle(e) {
        const checked = e.target.checked;

        if (this.wholeScreenButton && checked) {
          window.alert(
            `Button is already in whole-screen mode. Please turn the whole-screen mode for that button off first`,
          );
        } else {
          this.value.object.wholeScreen = checked;
        }
      },

      onButtonImage() {
        this.$set(this.value, 'imageUrl', this.buttonImageContent.contentLink);
        this.$set(this.value, 'imageContentObj', this.buttonImageContent.contentObj);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .button-widget-panel {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .color-picker-container {
      position: relative;
      top: 0;
      left: 0;

      #button-text-color {
        cursor: pointer;
      }

      .color-picker {
        position: absolute;
        top: 40px;
        z-index: 20;
      }
    }

    .sub-section-title {
      padding-top: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
    }
  }

  .button-widget-panel {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0px;
    padding-right: 10px;
  }
</style>
