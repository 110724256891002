<template>
  <div class="image-widget-panel scrollbar">
    <div class="form-group layout-input name">
      <label for="image-name">Name</label>
      <input
        class="form-control"
        type="text"
        id="image-name"
        placeholder="Name"
        disabled
        v-model="value.position.data.name"
      />
    </div>

    <div class="measurements-container">
      <div class="measurements">
        <div class="form-group hidden position">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">X</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-x"
                v-model="xPosition"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">Y</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="yPosition"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>

        <div class="form-group hidden size">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-width"
                v-model="width"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">H</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="height"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="lock-icon">
        <div class="extra-icons">
          <LockIcon :locked="isWidgetLocked" />

          <PixelIcon :usePixels="usePixels" @clicked="changeValuesMode" />
        </div>
      </div>
    </div>

    <button type="button" class="button-2-secondary" @click="openContentBrowser">
      <i class="material-icons-outlined align-middle">play_circle_outline</i>
      Replace Video
    </button>

    <image-mode v-model="value.position.data.imageMode" />

    <ContentBrowserModal
      ref="content_browser_modal"
      @submitted="[replaceVideo($event), closeModal()]"
      :allow-multiple-item-select="false"
      :filterItemTypes="imageFilter"
    />
  </div>
</template>

<script>
  import config from '@/config';

  import widgetMixin from '@/components/layoutDesigner/editor/settingPanel/widgetMixin';
  import ImageMode from '@/components/layoutDesigner/editor/settingPanel/formControls/ImageMode';
  import ContentBrowserModal from '@/components/content/ContentBrowserModal.vue';
  import PixelIcon from '@/components/layoutDesigner/editor/settingPanel/PixelIcon.vue';
  import LockIcon from '@/components/layoutDesigner/editor/settingPanel/LockIcon.vue';
  import VisibilityIcon from '@/components/layoutDesigner/editor/settingPanel/VisibilityIcon.vue';

  import { WIDGET_CONTENT_TYPE, WIDGET_TYPES } from '@/models/layoutDesigner';
  import { LAYOUT_REPLACE_CHILD_ITEM } from '@/store/actions/layoutDesigner';

  import { getEncodedURL } from '@/helpers/mixins';

  export default {
    name: 'VideoWidgetPanel',

    mixins: [widgetMixin],

    components: {
      ImageMode,
      ContentBrowserModal,
      PixelIcon,
      LockIcon,
      VisibilityIcon,
    },

    data() {
      return {
        imageFilter: [WIDGET_CONTENT_TYPE.VIDEO],
      };
    },

    methods: {
      openContentBrowser() {
        this.$refs.content_browser_modal.open();
      },

      async replaceVideo(selectedItems) {
        if (selectedItems.length === 0) return;

        const item = selectedItems[0];

        const newWidget = {
          ...this.value,
          object: {
            ...item,
            item_id: item.item_id,
            type: this.value.object.type,
            wid: this.value.position.data.wid,
            itemType: WIDGET_TYPES.VIDEO,
          },
          position: {
            ...this.value.position,
            data: {
              ...this.value.position.data,
              ...item,
              thumbnail_url: getEncodedURL(config.baseUrl, item.thumbnail_url),
              item_url: getEncodedURL(config.baseUrl, item.item_url),
            },
          },
        };

        await this.$store.dispatch(LAYOUT_REPLACE_CHILD_ITEM, {
          widget: newWidget,
          newItemId: item.item_id,
        });
      },

      closeModal() {
        this.$refs.content_browser_modal.close();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .image-widget-panel {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0px;
    padding-right: 10px;

    .content {
      background-color: #ecf0f1;
    }

    .button-2-secondary {
      margin-top: 20px;
      margin-bottom: 16px;
      width: 100%;

      &.disabled {
        background-color: $backgroundGrey2;
      }
    }
  }
</style>
