export interface OrganisationIntegration {
  type: 'TenantIntegration';
  settings: Record<string, string>;
  organisation_id: string;
  id: string;
}

export interface TenantIntegrationSettings {
  owner?: string;
}

export enum IntegrationTypes {
  TenantIntegration = 'TenantIntegration',
}
