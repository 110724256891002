<script lang="ts">
  export default {
    name: 'LabelledSelect',
  };
</script>

<script setup lang="ts">
  import { defineProps, withDefaults } from 'vue';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
  import { Options } from '../pulse/types';

  const emits = defineEmits<{
    (event: 'change', value: string | number | null): void;
  }>();

  const props = withDefaults(
    defineProps<{
      options?: Array<Options>;
      labelColor: string;
      value?: string | number | null;
      label: string;
      name: string;
      backgroundColor: string;
      labelOpacity: string;
    }>(),
    {
      labelColor: '#000',
      label: 'Select',
      backgroundColor: '#f8f8f8',
      labelOpacity: '1',
    },
  );

  function handleChange(e: Event) {
    emits('change', (e.target as HTMLSelectElement).value);
  }
</script>

<template>
  <div class="wrapper">
    <BaseText
      class="label"
      variant="subtitle2"
      :color="labelColor"
      :style="{ opacity: labelOpacity }"
      >{{ label }}</BaseText
    >
    <select
      v-model="value"
      @change="handleChange"
      :name="name"
      :style="{ 'background-color': backgroundColor }"
    >
      <option v-for="(option, index) in options" :value="option.value" :key="index">
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<style lang="scss" scoped>
  .wrapper {
    border-radius: 8px;
    border: 2px solid $borderGrey;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    > select {
      cursor: pointer;
      width: 100%;
      border: none;
      padding-right: 8px;
      &:focus-visible {
        outline: none;
      }
      font-size: 14px;
      font-weight: 500;
    }
  }
</style>
