<template>
  <div class="widget-toolbar align-content-center">
    <div class="title">Add Item to Layout:</div>

    <div class="top-buttons">
      <button type="button" class="button-2-primary new" @click="openCreatePlaylistModal">
        <i class="material-icons-outlined align-middle">add</i>
        Area
      </button>
    </div>

    <div v-if="showWidgetsList" class="widget-panel">
      <div class="widget-message">
        Advanced widget are widgets you can use individually ( not inside a playlist ) and can
        affect every screen attached to a layout.
      </div>

      <div class="widget-buttons">
        <div class="widget-button" title="Layer" @click.prevent="createLayer">
          <i class="material-icons-outlined align-middle">layers</i>
          <span>Layer</span>
        </div>

        <div class="widget-button" title="Moving Text" @click.prevent="createText">
          <i class="material-icons-outlined align-middle">text_fields</i>
          <span>Moving Text</span>
        </div>

        <div
          class="widget-button"
          title="Image"
          @click.prevent="openContentBrowser(WIDGET_CONTENT_TYPE.IMAGE)"
        >
          <i class="material-icons-outlined align-middle">image</i>
          <span>Image</span>
        </div>

        <div
          class="widget-button"
          title="Video"
          @click.prevent="openContentBrowser(WIDGET_CONTENT_TYPE.VIDEO)"
        >
          <i class="material-icons-outlined align-middle">play_circle_outline</i>
          <span>Video</span>
        </div>

        <div class="widget-button" title="Text" @click.prevent="createRichText">
          <i class="material-icons-outlined align-middle">edit_note</i>
          <span>Text</span>
        </div>

        <div
          class="widget-button"
          title="PDF"
          @click.prevent="openContentBrowser(WIDGET_CONTENT_TYPE.PDF)"
        >
          <i class="material-icons-outlined align-middle">picture_as_pdf</i>
          <span>PDF</span>
        </div>

        <div
          class="widget-button"
          title="APP"
          @click.prevent="openContentBrowser(WIDGET_CONTENT_TYPE.APP)"
        >
          <i class="material-icons-outlined align-middle">apps</i>
          <span>APP</span>
        </div>

        <div class="widget-button" title="Clock" @click.prevent="createClock">
          <i class="material-icons-outlined align-middle">access_time</i>
          <span>Clock</span>
        </div>

        <div class="widget-button" title="Add Template" @click.prevent="openTemplateBrowser">
          <i class="material-icons-outlined align-middle">photo_size_select_large</i>
          <span>Template</span>
        </div>
      </div>
    </div>

    <ContentBrowserModal
      ref="content_browser_modal"
      @submitted="[createContentWidget($event), closeContentBrowser()]"
      :allow-multiple-item-select="false"
      :filterItemTypes="filterItemTypes[selectedItemType]"
    >
    </ContentBrowserModal>

    <CreatePlaylistModal
      v-if="showCreatePlaylistModal"
      @createPlaylist="createPlaylist"
      @closeModal="showCreatePlaylistModal = false"
    />

    <TemplateFolderBrowserModal
      ref="template_folder_model"
      @attachTemplate="attachTemplate"
      :usedTemplatesIds="usedTemplatesIds"
    />
  </div>
</template>

<script>
  import config from '@/config';

  import {
    CREATE_DUMMY_LAYER,
    LAYOUT_DESIGNER_ADD_WIDGET,
    SET_PLAYLIST_CHILDREN,
  } from '@/store/actions/layoutDesigner';

  import {
    generateDefaultWidgetData,
    IMAGE_MODE,
    WIDGET_CONTENT_TYPE,
    WIDGET_TYPES,
  } from '@/models/layoutDesigner';

  import { getEncodedURL, getUsedTemplateIds } from '@/helpers/mixins';

  import ContentBrowserModal from '@/components/content/ContentBrowserModal.vue';
  import CreatePlaylistModal from '@/components/layoutDesigner/editor/CreatePlaylistModal.vue';
  import TemplateFolderBrowserModal from '@/components/layoutDesigner/editor/TemplateFolderBrowserModal.vue';

  export default {
    name: 'WidgetToolbar',

    components: {
      ContentBrowserModal,
      CreatePlaylistModal,
      TemplateFolderBrowserModal,
    },

    data() {
      return {
        filterItemTypes: {
          [WIDGET_CONTENT_TYPE.IMAGE]: [WIDGET_CONTENT_TYPE.IMAGE],
          [WIDGET_CONTENT_TYPE.APP]: [WIDGET_CONTENT_TYPE.APP],
          [WIDGET_CONTENT_TYPE.VIDEO]: [WIDGET_CONTENT_TYPE.VIDEO],
          [WIDGET_CONTENT_TYPE.AUDIO]: [WIDGET_CONTENT_TYPE.AUDIO],
          [WIDGET_CONTENT_TYPE.PDF]: [WIDGET_CONTENT_TYPE.PDF],
        },
        selectedItemType: 'image',
        showWidgetsList: false,
        showTemplateBrowser: false,
        showCreatePlaylistModal: false,
        WIDGET_CONTENT_TYPE,
      };
    },

    computed: {
      layoutResolution() {
        return this.$store.getters.getResolution;
      },

      usedTemplatesIds() {
        const layoutWidgets = this.$store.state.layoutDesigner.widgets;
        const usedTemplates = getUsedTemplateIds(layoutWidgets);

        return usedTemplates;
      },

      isHorizontal() {
        return this.$store.state.layoutDesigner.isLayoutHorizontal;
      },

      nextZIndex() {
        return Object.keys(this.$store.state.layoutDesigner.widgets).length;
      },
    },

    methods: {
      toggleWidgetPanel() {
        this.showWidgetsList = !this.showWidgetsList;
      },

      prepareAppData(widgetType) {
        const defaultWidget = generateDefaultWidgetData(widgetType);

        return {
          widget: defaultWidget,
          position: this.getPositionData(defaultWidget),
          widgetData: {
            type: widgetType,
            name: defaultWidget.name,
            config: defaultWidget,
          },
        };
      },

      createLayer() {
        const defaultLayerWidget = generateDefaultWidgetData(WIDGET_TYPES.LAYER);

        defaultLayerWidget.position = this.getPositionData(defaultLayerWidget);

        this.$store.dispatch(CREATE_DUMMY_LAYER, {
          widget: defaultLayerWidget,
        });
      },

      createButton() {
        const { widget, position, widgetData } = this.prepareAppData(WIDGET_TYPES.BUTTON);

        widgetData.buttonText = widget.text;

        const newWidget = {
          widgetData,
          type: WIDGET_TYPES.LAYOUT_ITEM,
          object: widget,
          position,
          itemType: WIDGET_TYPES.BUTTON,
        };

        this.$store.commit(LAYOUT_DESIGNER_ADD_WIDGET, newWidget);
      },

      createText() {
        const { widget, position, widgetData } = this.prepareAppData(WIDGET_TYPES.TEXT);

        widgetData.userText = widget.userText;

        const newWidget = {
          widgetData,
          type: WIDGET_TYPES.LAYOUT_ITEM,
          object: widget,
          position,
          itemType: WIDGET_TYPES.TEXT,
        };

        this.$store.commit(LAYOUT_DESIGNER_ADD_WIDGET, newWidget);
      },

      createRichText() {
        const { widget, position, widgetData } = this.prepareAppData(WIDGET_TYPES.RICH_TEXT);

        widgetData.userText = widget.userText;

        const newWidget = {
          widgetData,
          type: WIDGET_TYPES.LAYOUT_ITEM,
          object: widget,
          position,
          itemType: WIDGET_TYPES.RICH_TEXT,
        };

        this.$store.commit(LAYOUT_DESIGNER_ADD_WIDGET, newWidget);
      },

      createClock() {
        const { widget, position, widgetData } = this.prepareAppData(WIDGET_TYPES.CLOCK);

        widgetData.clockName = widget.name;

        const newWidget = {
          widgetData,
          type: WIDGET_TYPES.LAYOUT_ITEM,
          object: widget,
          position,
          itemType: WIDGET_TYPES.CLOCK,
        };

        this.$store.commit(LAYOUT_DESIGNER_ADD_WIDGET, newWidget);
      },

      calculatePositionAttribute(template) {
        const { width: layoutWidth, height: layoutHeight } = this.$store.getters.getResolution;
        const { width: templateWidth, height: templateHeight } = template.settings;

        const widgetOverflowsHorizontalLayout =
          templateWidth > layoutWidth || templateHeight > layoutHeight;
        const widgetOverflowsVerticalLayout =
          templateWidth > layoutHeight || templateHeight > layoutWidth;

        const defaultPosition = {
          x: 0,
          y: 0,
          xPixels: 0,
          yPixels: 0,
          zIndex: this.nextZIndex,
          usePixels: true,
        };

        const horizontalWidth = templateWidth > layoutWidth ? layoutWidth : templateWidth;
        const horizontalHeight = templateHeight > layoutHeight ? layoutHeight : templateHeight;
        const verticalWidth = templateWidth > layoutHeight ? layoutHeight : templateWidth;
        const verticalHeight = templateHeight > layoutWidth ? layoutWidth : templateHeight;

        return {
          horizontal: {
            ...defaultPosition,
            allowResizing: widgetOverflowsHorizontalLayout,
            widthPixels: horizontalWidth,
            heightPixels: horizontalHeight,
            width: (horizontalWidth * 100) / this.layoutResolution.width,
            height: (horizontalHeight * 100) / this.layoutResolution.height,
          },
          vertical: {
            ...defaultPosition,
            allowResizing: widgetOverflowsVerticalLayout,
            widthPixels: verticalWidth,
            heightPixels: verticalHeight,
            width: (verticalWidth * 100) / this.layoutResolution.height,
            height: (verticalHeight * 100) / this.layoutResolution.width,
          },
        };
      },

      attachTemplate(template) {
        const newWidget = {
          type: WIDGET_TYPES.TEMPLATE,
          position: this.calculatePositionAttribute(template),
          object: {
            ...template,
            wid: `${Date.now()}${Math.random()}`,
            type: WIDGET_TYPES.TEMPLATE,
            item_type: WIDGET_TYPES.TEMPLATE,
            template_id: template.template_id,
          },
          template: template.template_id,
          itemType: WIDGET_TYPES.TEMPLATE,
        };

        this.$store.commit(LAYOUT_DESIGNER_ADD_WIDGET, newWidget);
      },

      openTemplateBrowser() {
        this.$refs.template_folder_model.open();
      },

      async createImage(item) {
        const imageWidget = generateDefaultWidgetData(WIDGET_TYPES.IMAGE, {
          uuid: item.item_id,
          item_url: item.item_url,
          item_id: item.item_id,
        });

        const widgetPositions = this.getPositionData(imageWidget);

        const position = {
          ...imageWidget,
          ...item,
          ...widgetPositions,
        };

        const newWidget = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          object: imageWidget,
          position,
          itemType: WIDGET_TYPES.IMAGE,
        };

        this.$store.commit(LAYOUT_DESIGNER_ADD_WIDGET, newWidget);
      },

      async createApp(item) {
        const appInfo = this.prepareAppData(WIDGET_TYPES.APP);

        const newWidget = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          position: appInfo.position,
          object: {
            ...item,
            config: undefined,
            ...appInfo.widget,
            ...item.config,
            type: WIDGET_TYPES.APP,
            item_type: item.item_type,
            item_id: item.item_id,
          },
          itemType: WIDGET_TYPES.APP,
        };

        this.$store.commit(LAYOUT_DESIGNER_ADD_WIDGET, newWidget);
      },

      async createVideo(item) {
        const videoWidget = generateDefaultWidgetData(WIDGET_TYPES.VIDEO, {
          uuid: item.item_id,
          item_id: item.item_id,
        });

        const widgetPositions = this.getPositionData(videoWidget);

        const position = {
          ...widgetPositions,
          data: {
            ...videoWidget,
            ...item,
            thumbnail_url: getEncodedURL(config.baseUrl, item.thumbnail_url),
            item_url: getEncodedURL(config.baseUrl, item.item_url),
          },
        };

        const newWidget = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          position,
          object: videoWidget,
          itemType: WIDGET_TYPES.VIDEO,
        };

        this.$store.commit(LAYOUT_DESIGNER_ADD_WIDGET, newWidget);
      },

      async createAudio(item) {
        const audioWidget = generateDefaultWidgetData(WIDGET_TYPES.AUDIO, {
          uuid: item.item_id,
          item_id: item.item_id,
        });

        const widgetPositions = this.getPositionData(audioWidget);

        const position = {
          ...widgetPositions,
          data: {
            ...audioWidget,
            ...item,
            item_url: getEncodedURL(config.baseUrl, item.item_url),
          },
        };

        const newWidget = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          position,
          object: audioWidget,
          itemType: WIDGET_TYPES.AUDIO,
        };

        this.$store.commit(LAYOUT_DESIGNER_ADD_WIDGET, newWidget);
      },

      async createPdf(item) {
        if (!item.item_id) {
          this.$toasted.global.general_error({ message: 'Something is wrong with your PDF!' });

          return;
        }

        const pdfWidget = generateDefaultWidgetData(WIDGET_TYPES.PDF, {
          uuid: item.item_id,
          item_id: item.item_id,
        });

        const widgetPositions = this.getPositionData(pdfWidget);

        let position = {
          ...pdfWidget,
          ...widgetPositions,
          data: { ...item, pageDuration: 15, imageMode: IMAGE_MODE.CONTAIN },
        };

        if (item.pdf_content) {
          position = { ...position, data: { ...position.data, ...item.pdf_content } };
        }

        const newWidget = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          position,
          object: pdfWidget,
          itemType: WIDGET_TYPES.PDF,
        };

        this.$store.commit(LAYOUT_DESIGNER_ADD_WIDGET, newWidget);
      },

      async createContentWidget(selectedItems) {
        if (selectedItems.length === 0) return;

        const item = selectedItems[0];

        switch (this.selectedItemType) {
          case WIDGET_CONTENT_TYPE.IMAGE:
            await this.createImage(item);
            break;
          case WIDGET_CONTENT_TYPE.APP:
            await this.createApp(item);
            break;
          case WIDGET_CONTENT_TYPE.VIDEO:
            await this.createVideo(item);
            break;
          case WIDGET_CONTENT_TYPE.AUDIO:
            await this.createAudio(item);
            break;
          case WIDGET_CONTENT_TYPE.PDF:
            await this.createPdf(item);
            break;
        }
      },

      createPlaylist(playlist) {
        const defaultWidget = generateDefaultWidgetData(WIDGET_TYPES.PLAYLIST, {
          ...playlist,
        });

        const widgetData = {
          type: WIDGET_TYPES.PLAYLIST,
          itemType: WIDGET_TYPES.PLAYLIST,
          object: {
            ...defaultWidget,
          },
          position: this.getPositionData({ width: 50, height: 50, x: 0, y: 0 }),
        };

        this.$store.commit(LAYOUT_DESIGNER_ADD_WIDGET, widgetData);
        this.$store.commit(SET_PLAYLIST_CHILDREN, { wid: widgetData.object.wid });
      },

      openContentBrowser(type = 'image') {
        this.selectedItemType = type;
        this.$refs.content_browser_modal.open();
      },

      closeContentBrowser() {
        this.$refs.content_browser_modal.close();
      },

      getPositionData(widget) {
        const { width = 50, height = 50 } = widget;

        const commonPosition = {
          zIndex: this.nextZIndex,
          width,
          height,
          x: 0,
          y: 0,
          usePixels: true,
          xPixels: 0,
          yPixels: 0,
        };

        const xScale = width / 100;
        const yScale = height / 100;
        const widgetWidth = xScale * this.layoutResolution.width;
        const widgetHeight = yScale * this.layoutResolution.height;

        return {
          horizontal: {
            ...commonPosition,
            widthPixels: widgetWidth,
            heightPixels: widgetHeight,
          },
          vertical: {
            ...commonPosition,
            widthPixels: widgetHeight,
            heightPixels: widgetWidth,
          },
        };
      },

      openCreatePlaylistModal() {
        this.showCreatePlaylistModal = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .widget-toolbar {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .top-buttons {
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr;
    }

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    a.btn {
      width: 44px;
      height: 42px;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        background-color: #d0d0d0;
      }
    }
  }

  .widget-panel {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .widget-message {
      border-radius: 5px;
      background-color: $lightgrey;
      color: $secondaryText;
      font-size: 12px;
      line-height: 16px;
      padding: 10px;
    }
  }

  .widget-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 14px 8px;

    .widget-button {
      display: flex;
      align-items: center;
      gap: 6px;

      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      padding: 4px 12px;
      background-color: $highlightGrey;
      color: $tableText;
      border-radius: 20px;

      &:hover {
        cursor: pointer;
        background-color: $lineGrey2;
      }
    }
  }
</style>
