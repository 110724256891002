<script lang="ts">
  export default {
    name: 'OrganisationIntegrations',
  };
</script>

<script setup lang="ts">
  import {
    apiGetOrganisationIntegrations,
    apiSaveOrganisationIntegration,
    apiDeleteOrganisationIntegration,
  } from '@/api/integrations';
  import { useStore } from '@/store';
  import { useToasted } from '@/helpers';
  import {
    IntegrationTypes,
    OrganisationIntegration,
    TenantIntegrationSettings,
  } from '@/types/api/integrations';
  import { computed, onMounted, ref } from 'vue';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
  import DeleteConfirmationModal from '@/components/common/BaseModal/BaseDeleteConfirmModal.vue';

  const toasted = useToasted();
  const store = useStore();

  const integrations = ref<Array<OrganisationIntegration>>([]);
  const isDeleting = ref(false);
  const showDeleteConfirmation = ref(false);

  const tenantsIntegrationConfig = computed<TenantIntegrationSettings>(() => {
    const tenantIntegration = integrations.value.find(
      (int) => int.type === IntegrationTypes.TenantIntegration,
    );
    return tenantIntegration?.settings ?? ({ owner: undefined } as TenantIntegrationSettings);
  });
  const organisation = computed(() => {
    return store.state.organisation.organisation;
  });

  onMounted(() => {
    fetchOrganisationIntegrations();
  });

  async function fetchOrganisationIntegrations() {
    const orgId = organisation.value.organisation_id;
    try {
      integrations.value = await apiGetOrganisationIntegrations(orgId);
    } catch (err) {
      toasted.global.general_error({
        message: 'Unable to get organisation integrations',
      });
    }
  }

  async function saveTenantIntegration() {
    try {
      await apiSaveOrganisationIntegration(
        IntegrationTypes.TenantIntegration,
        organisation.value.organisation_id,
        tenantsIntegrationConfig.value,
      );
      await fetchOrganisationIntegrations();
      toasted.global.general_success({
        message: 'Integration saved successfully',
      });
    } catch (err) {
      toasted.global.general_error({
        message: 'Unable to save organisation integration',
      });
    }
  }

  async function deleteTenantIntegration() {
    try {
      isDeleting.value = true;
      await apiDeleteOrganisationIntegration(
        IntegrationTypes.TenantIntegration,
        organisation.value.organisation_id,
      );
      await fetchOrganisationIntegrations();
      toasted.global.general_success({
        message: 'Integration deleted successfully',
      });
      showDeleteConfirmation.value = false;
    } catch (err) {
      toasted.global.general_error({
        message: 'Unable to delete organisation integration',
      });
    } finally {
      isDeleting.value = false;
    }
  }
</script>

<template>
  <div class="integrations scrollbar">
    <div class="integration">
      <div class="header">
        <BaseText variant="subtitle2">Tenant</BaseText>
        <div class="action">
          <i class="material-icons-outlined align-middle" @click="saveTenantIntegration()">save</i>
          <i
            class="material-icons-outlined align-middle"
            v-if="tenantsIntegrationConfig.owner"
            @click="showDeleteConfirmation = true"
            >delete</i
          >
        </div>
      </div>
      <div class="settings">
        <div class="setting-item">
          <BaseText variant="body2">Owner</BaseText>
          <input
            type="text"
            name="tenantOwner"
            id="tenantOwner"
            v-model="tenantsIntegrationConfig.owner"
          />
        </div>
      </div>
    </div>
    <DeleteConfirmationModal
      confirmationText="Are you sure you want to delete this integration?"
      :isDeleting="isDeleting"
      @deletionConfirmed="deleteTenantIntegration"
      @closeModal="showDeleteConfirmation = false"
      :showModal="showDeleteConfirmation"
    />
  </div>
</template>

<style lang="scss" scoped>
  .integrations {
    padding: 0 8px;
    max-height: 400px;
    overflow-y: auto;
    .integration {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        .action {
          display: flex;
          gap: 4px;
          > i {
            color: $secondaryText;
            cursor: pointer;
            &:hover {
              color: $primaryRed;
            }
          }
        }
      }
      .settings {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 8px;
        .setting-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > input {
            border: 2px solid #e8e8e8;
            border-radius: 8px;
            padding: 8px 8px;
            font-size: 14px;
          }
        }
      }
    }
  }
</style>
