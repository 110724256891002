import Vue, { getCurrentInstance } from 'vue';
import Vuex from 'vuex';

import app from '@/store/modules/app';
import user from '@/store/modules/user';
import auth from '@/store/modules/auth';
import playlist from '@/store/modules/playlist';
import screens from '@/store/modules/screens';
import eventlog from '@/store/modules/eventlog';
import organisation from '@/store/modules/organisation';
import roles from '@/store/modules/roles';
import apps from '@/store/modules/apps';
import layoutDesigner from '@/store/modules/layoutDesigner';
import templateDesigner from '@/store/modules/templateDesigner';
import content from '@/store/modules/content';
import player from '@/store/modules/player';
import dashboard from '@/store/modules/dashboard';
import screenContentManagement from '@/store/modules/screenContentManagement';
import preview from '@/store/modules/preview';
import pulse from '@/store/modules/pulse';

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    app,
    user,
    auth,
    playlist,
    screens,
    eventlog,
    organisation,
    roles,
    apps,
    layoutDesigner,
    templateDesigner,
    content,
    player,
    dashboard,
    screenContentManagement,
    preview,
    pulse,
  },
  // strict: debug,
  strict: false, // this is necessary to avoid an error when editing forms
  // that contain vuex state data.
});

export const useStore = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$store;
};
