<script lang="ts">
  export default {
    name: 'PulseSetting',
  };
</script>

<script setup lang="ts"></script>

<template>
  <div class="coming-soon">Coming Soon</div>
</template>

<style lang="scss" scoped>
  .coming-soon {
    text-align: center;
    padding: 20px;
  }
</style>
