import axios, { AxiosResponse, AxiosError } from 'axios';
import config from '@/config';
import { PublishStatus } from '@/types/api/pulse';

export async function apiGetScreenPublishStatus(
  publishId: string | undefined,
): Promise<Array<PublishStatus>> {
  const response = await axios.get(`${config.apiUrl}userapi/pulse/publish-info`, {
    params: { ...(publishId && { publish_id: publishId }) },
  });
  return response.data;
}
