<template>
  <Modal
    @closeModal="closeModal"
    v-show="showModal"
    :isLoading="isLoading"
    title="Copy Template"
    :okFunction="copyTemplate"
    :modalStyles="{ width: '500px' }"
    :disabled="disableOkButton"
  >
    <template v-slot:body>
      <div class="group-body">
        <div class="form-group">
          <label for="templateName">
            <div class="label">Template Name</div>
            <input
              type="text"
              name="templateName"
              id="templateName"
              v-model="templateName"
              placeholder="Set new template's name"
              required
              minlength="3"
              maxlength="40"
            />
          </label>
        </div>

        <div class="form-group">
          <label for="templateName">
            <div class="label">Target Folder</div>
            <select id="templateFolders" class="form-control" v-model="folderId">
              <option value="">Select folder</option>
              <option v-for="(folder, index) in folders" :key="index" :value="folder.id">
                {{ folder.name }}
              </option>
            </select>
          </label>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
  import { apiAttachTemplateChild, apiCreateTemplate, apiUpdateTemplate } from '@/api/templates';
  import Modal from '@/components/common/Modal.vue';
  import { WIDGET_TYPES } from '@/models/layoutDesigner';
  import {
    CREATE_TEMPLATE_COMMON_WIDGET,
    CREATE_TEMPLATE_LAYER,
  } from '@/store/actions/templateDesigner';
  import { mapGetters } from 'vuex';

  export default {
    name: 'CopyTemplateModal',

    components: { Modal },

    props: {
      template: {
        type: Object,
        default: null,
      },
      widgets: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        showModal: false,
        isLoading: false,
        templateName: '',
        folderId: null,
      };
    },

    mounted() {
      this.showModal = true;
      this.templateName = `${this.template.name} - Copy`;
      this.folderId = this.template.item_folder;
    },

    computed: {
      folders() {
        return this.$store.getters.getAllTemplateFolders;
      },
      disableOkButton() {
        return this.templateName.length < 3;
      },
    },

    methods: {
      async updateTemplate(templateData) {
        try {
          const templateSettings = {
            ...templateData.settings,
            appId: templateData.app_id,
          };

          const successful = await apiUpdateTemplate(templateData.template_id, templateSettings);
        } catch (error) {
          console.log('TemplatesView.vue - updateTemplate error: ', error);
        }
      },

      async copyTemplate() {
        this.isLoading = true;

        try {
          const templateData = {
            ...this.template.settings,
            name: this.templateName || `${this.template.settings.name} copy`,
          };

          const response = await apiCreateTemplate(templateData, this.folderId);
          this.updateTemplate(response.data);

          const newTemplate = response.data;

          const createWidgetsPromises = [];
          const widgetsArray = this.widgets;

          for (let i = 0; i < widgetsArray.length; i++) {
            const widget = widgetsArray[i];
            const widgetData = {
              type: widget.type,
              position: widget.position,
              item_id:
                widget.type === WIDGET_TYPES.PLAYLIST
                  ? widget.object.playlist_id
                  : widget.object.item_id,
            };

            let attachApiCall;
            const appExtraField = {};

            switch (widget.itemType) {
              case WIDGET_TYPES.BUTTON:
                appExtraField.buttonText = widget.object.buttonText;
              case WIDGET_TYPES.TEXT:
              case WIDGET_TYPES.RICH_TEXT:
                appExtraField.userText = widget.object.userText;
              case WIDGET_TYPES.CLOCK:
                appExtraField.clockName = widget.object.clockName;
              case WIDGET_TYPES.TENANTS:
              case WIDGET_TYPES.TRAFIKLABV2:
                const appData = {
                  type: widget.itemType,
                  name: widget.object.name,
                  config: { ...widget.object, config: undefined, item_id: undefined },
                  ...appExtraField,
                };

                attachApiCall = this.$store.dispatch(CREATE_TEMPLATE_COMMON_WIDGET, {
                  templateId: newTemplate.template_id,
                  widget,
                  widgetData: appData,
                  position: widget.position,
                });
                break;
              case WIDGET_TYPES.PDF:
              case WIDGET_TYPES.IMAGE:
              case WIDGET_TYPES.VIDEO:
              case WIDGET_TYPES.AUDIO:
              case WIDGET_TYPES.APP:
                // case WIDGET_TYPES.PLAYLIST:
                attachApiCall = apiAttachTemplateChild({
                  templateId: newTemplate.template_id,
                  widgetData,
                });
                break;

              case WIDGET_TYPES.LAYER:
                attachApiCall = this.$store.dispatch(CREATE_TEMPLATE_LAYER, {
                  templateId: newTemplate.template_id,
                  widget: { ...widget.object, position: widget.position, item_id: undefined },
                });

                break;
            }

            createWidgetsPromises.push(attachApiCall);
          }

          await Promise.all(createWidgetsPromises);

          this.$router.push({
            name: 'Template',
            params: { templateId: newTemplate.template_id },
          });
        } catch (error) {
          this.$toasted.global.general_error({
            message: String(error),
          });
        }

        this.isLoading = false;
        this.closeModal();
      },

      closeModal() {
        this.showModal = false;
        this.$emit('closeModal');
      },
    },
  };
</script>

<style scoped lang="scss">
  .group-body {
    display: flex;
    gap: 20px;
    flex-direction: column;

    .form-group {
      width: 100%;
      margin-bottom: 0px;
      label {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        gap: 8px;
        font-weight: 500;
        line-height: 24px;
        .label {
          display: flex;
          gap: 8px;
          align-items: center;
          font-size: 14px;
        }

        input,
        select {
          padding: 8px 16px;
          border: 2px solid $borderGrey;
          border-radius: 8px;
          font-size: 16px;
          height: 44px;
        }
      }
    }
  }
</style>
