<template>
  <div class="tabs-container">
    <div class="tabs">
      <div
        :class="['tab', { active: isWidgetSettingTab }]"
        @click.prevent="toWidgetSettingTab(true)"
      >
        <span class="material-icons-outlined align-middle">tune</span>
        <span class="text">Parameters</span>
      </div>
      <div
        :class="['tab', { active: !isWidgetSettingTab }]"
        @click.prevent="toWidgetSettingTab(false)"
      >
        <span class="material-icons-outlined align-middle">layers</span>
        <span class="text">Layers</span>
      </div>
    </div>

    <div class="widget-panel scrollbar" v-show="isWidgetSettingTab">
      <LayoutPanel v-if="showLayoutPanel" v-model="layout" />

      <component
        v-if="!showLayoutPanel"
        :key="widgetId"
        :is="widgetPanel"
        :value="widget"
      ></component>
    </div>

    <LayerPanel v-show="!isWidgetSettingTab" />
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';

  import ButtonWidgetPanel from '@/components/layoutDesigner/editor/settingPanel/ButtonWidgetPanel';
  import ClockWidgetPanel from '@/components/layoutDesigner/editor/settingPanel/ClockWidgetPanel';
  import ImageWidgetPanel from '@/components/layoutDesigner/editor/settingPanel/ImageWidgetPanel';
  import LayoutPanel from '@/components/layoutDesigner/editor/settingPanel/LayoutPanel';
  import LayerPanel from '@/components/layoutDesigner/editor/LayerPanel';
  import LayerWidgetPanel from '@/components/layoutDesigner/editor/settingPanel/LayerWidgetPanel';
  import PlaylistWidgetPanel from '@/components/layoutDesigner/editor/settingPanel/PlaylistWidgetPanel';
  import TextWidgetPanel from '@/components/layoutDesigner/editor/settingPanel/TextWidgetPanel';
  import RichtextWidgetPanel from '@/components/layoutDesigner/editor/settingPanel/RichTextWidgetPanel';
  import AppWidgetPanel from '@/components/layoutDesigner/editor/settingPanel/AppWidgetPanel';
  import VideoWidgetPanel from '@/components/layoutDesigner/editor/settingPanel/VideoWidgetPanel';
  import PdfWidgetPanel from '@/components/layoutDesigner/editor/settingPanel/PdfWidgetPanel';
  import TemplateWidgetPanel from '@/components/layoutDesigner/editor/settingPanel/TemplateWidgetPanel';

  import { WIDGET_TYPES } from '@/models/layoutDesigner';

  import { LAYOUT_DESIGNER_TO_SETTING_TAB } from '@/store/actions/layoutDesigner';
  import AudioWidgetPanel from '@/components/layoutDesigner/editor/settingPanel/AudioWidgetPanel.vue';

  const panels = {
    [WIDGET_TYPES.LAYER]: LayerWidgetPanel,
    [WIDGET_TYPES.BUTTON]: ButtonWidgetPanel,
    [WIDGET_TYPES.TEXT]: TextWidgetPanel,
    [WIDGET_TYPES.RICH_TEXT]: RichtextWidgetPanel,
    [WIDGET_TYPES.CLOCK]: ClockWidgetPanel,
    [WIDGET_TYPES.IMAGE]: ImageWidgetPanel,
    [WIDGET_TYPES.PLAYLIST]: PlaylistWidgetPanel,
    [WIDGET_TYPES.APP]: AppWidgetPanel,
    [WIDGET_TYPES.VIDEO]: VideoWidgetPanel,
    [WIDGET_TYPES.AUDIO]: AudioWidgetPanel,
    [WIDGET_TYPES.PDF]: PdfWidgetPanel,
    [WIDGET_TYPES.TEMPLATE]: TemplateWidgetPanel,
  };

  export default {
    name: 'Tabs',
    components: {
      LayerPanel,
      LayoutPanel,
    },

    props: {
      widget: Object,
      layout: Object,
    },

    computed: {
      ...mapState({
        isWidgetSettingTab: (state) => state.layoutDesigner.isWidgetSettingTab,
      }),

      widgetPanel() {
        if (this.widget) {
          return panels[this.widget.object.type || WIDGET_TYPES.PLAYLIST];
        }
        return null;
      },

      widgetId() {
        return this.widget.object.item_id;
      },

      showLayoutPanel() {
        return this.widgetPanel === null;
      },
    },

    methods: {
      ...mapMutations({
        toWidgetSettingTab: LAYOUT_DESIGNER_TO_SETTING_TAB,
      }),
    },
  };
</script>

<style lang="scss" scoped>
  .tabs-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .tabs {
    display: flex;
    margin-bottom: 16px;

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      flex: 1;
      border-bottom: 2px solid #f0f0f0;
      padding: 8px 5px;
      transition: border-color 200ms ease-in-out;
      color: #6a6b6a;
      cursor: pointer;

      &:hover {
        border-color: #d6d6d6;
        color: #555555;
      }
      &.active {
        border-color: #e3003a;
        color: #151515;
      }

      .text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: inherit;
      }
    }
  }

  .widget-panel {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
