import axios from 'axios';
import config from '@/config';

export async function apiCopyContent(
  contentId: string,
  contentType: string,
  organisations: Array<string>,
): Promise<{ message: string }> {
  const response = await axios.post(
    `${config.apiUrl}adminapi/organisation/${contentType}/${contentId}/copy`,
    {
      organisations,
    },
  );
  return response.data;
}
