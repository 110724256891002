<template>
  <div
    class="visible-icon"
    :title="isVisible ? 'Widget visible on Player' : 'Widget hidden on Player'"
    @click="$emit('clicked')"
  >
    <i class="material-icons-outlined">
      {{ isVisible ? 'visibility' : 'visibility_off' }}
    </i>
  </div>
</template>

<script>
  export default {
    name: 'VisibilityIcon',

    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .visible-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    color: $secondaryText;

    &:hover {
      background-color: $highlightGrey;
      color: $primaryText;
    }
  }
</style>
