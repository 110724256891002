import { Colors } from '@/constant/theme';
import { TenantsWidgetObject } from '@/types/widgets/tenantsWidget/tenantsWidget';
import { TimeUnitsType } from '@/types/common';
import { ALIGN_MODES } from '@/models/layoutDesigner';

const { LINE_GREY, TENANTS_DEFAULT_COLOR, WHITE_COLOR } = Colors;

export const tenantsDefaultOptions: Partial<TenantsWidgetObject> = {
  name: 'Tenants',

  address: null,
  folder: null,
  refreshTime: 1,
  timeUnit: TimeUnitsType.DAYS,

  allFloorsVisible: true,
  numbersOnTheLeft: false,
  groupNamesAndNumbers: false,
  paginationInText: false,
  hideNumbers: false,
  floors: null,

  // Slider
  floorsOnSlide: 4,
  verticalMode: false,
  interval: 15,
  designType: 1,
  backgroundColor: WHITE_COLOR,
  opacity: 100,

  // Floors Style
  floorFont: 'Arial',
  textAlignment: ALIGN_MODES.LEFT_ALIGN,
  floorFontSize: 13,
  floorFontColor: TENANTS_DEFAULT_COLOR,

  // Floors Numbers Style
  numberFont: 'Arial',
  numberAlignment: ALIGN_MODES.LEFT_ALIGN,
  numberFontSize: 13,
  numberFontColor: TENANTS_DEFAULT_COLOR,
  numberBackgroundColor: WHITE_COLOR,

  // Rooms Style
  roomsFont: 'Arial',
  roomsFontSize: 13,
  roomsFontColor: TENANTS_DEFAULT_COLOR,

  // Pagination Style
  paginationFont: 'Arial',
  paginationFontSize: 13,
  paginationFontColor: TENANTS_DEFAULT_COLOR,
  paginationAlignment: ALIGN_MODES.CENTER_ALIGN,

  // Title Style
  title: 'Tenants',
  titleAlignment: ALIGN_MODES.LEFT_ALIGN,
  titleFont: 'Arial',
  titleFontSize: 14,
  titleFontColor: TENANTS_DEFAULT_COLOR,
  titleBackgroundColor: LINE_GREY,
};

export const tenantsDemoData = [...Array(5)].map((_, floorIndex) => {
  return {
    displayFloorName: null,
    floor: floorIndex + 1,
    apartments: [...Array(6)].map((_, apartmentIndex) => ({
      apartmentNumber: `0${(floorIndex + 1) * 100 + apartmentIndex + 1}`,
      names: [`Tenant ${apartmentIndex + 1}`],
    })),
    visible: true,
  };
});

export const demoTenantAddress = {
  id: 'demo',
  name: 'Demo Data',
};
