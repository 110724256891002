<template>
  <SidebarContainer>
    <div class="saving-placeholder" :class="{ effect: isSavingTemplate }">
      <img class="logo w-165" src="@/assets/img/sidebar/picflow-logo.svg" alt="Picflow logo" />
      <div class="placeholder-text">Saving Template</div>
    </div>

    <div class="editor-container" :class="{ effect: isSavingTemplate }">
      <WidgetToolbar />

      <Tabs class="flex-grow-1" :widget="selectedWidget" />
    </div>

    <template v-if="!isSavingTemplate && !overrideMode && !isTileMode" v-slot:buttons>
      <button
        v-if="!selectedWidget && isAdmin"
        type="button"
        class="button-2-secondary"
        @click="openPermissionsModal"
      >
        <i class="material-icons-outlined align-middle">edit_attributes</i>
        Manage Permission
      </button>

      <PermissionsManagerModal
        v-if="showPermissionsModal"
        @close="showPermissionsModal = false"
        :itemId="template.template_id"
        :type="ROLE_ACCESS_TYPES.TEMPLATES"
      />

      <template v-if="!selectedWidget && isWidgetSettingTab && deletableTemplate">
        <button type="button" class="button-2-secondary" @click="openDeleteTemplateModal">
          <i class="material-icons-outlined align-middle">delete_outline</i>
          Delete Template
        </button>
      </template>

      <DeleteTemplateModal
        @closeModal="showDeleteTemplateModal = false"
        @deleted="$router.push({ name: 'Templates' })"
        :templateId="template?.template_id"
        v-if="showDeleteTemplateModal"
      />
    </template>
  </SidebarContainer>
</template>

<script>
  import Tabs from '@/components/templates/editor/Tabs.vue';
  import WidgetToolbar from '@/components/templates/editor/WidgetToolbar.vue';
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import DeleteTemplateModal from '@/components/templates/DeleteTemplateModal.vue';
  import PermissionsManagerModal from '@/components/common/PermissionsManagerModal.vue';
  import { ROLE_ACCESS_TYPES } from '@/constant/roleAccess';

  export default {
    name: 'Editor',

    components: {
      Tabs,
      WidgetToolbar,
      SidebarContainer,
      DeleteTemplateModal,
      PermissionsManagerModal,
    },

    props: {
      overrideMode: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        showDeleteTemplateModal: false,
        showPermissionsModal: false,
        ROLE_ACCESS_TYPES,
      };
    },

    computed: {
      selectedWidget() {
        return this.$store.state.templateDesigner.selectedWidget;
      },

      template() {
        return this.$store.state.templateDesigner.template;
      },

      isWidgetSettingTab() {
        return this.$store.state.templateDesigner.isWidgetSettingTab;
      },

      isAdmin() {
        return this.$store.getters.isAdministrator || this.$store.getters.isOrganizationAdmin;
      },

      isSavingTemplate() {
        return this.$store.state.templateDesigner.isSavingTemplate;
      },

      isTileMode() {
        return !!this.$route.query?.tile;
      },

      deletableTemplate() {
        return !this.$store.state.templateDesigner.layoutId && !this.$route.params.tile;
      },
    },

    methods: {
      openDeleteTemplateModal() {
        this.showDeleteTemplateModal = true;
      },

      openPermissionsModal() {
        if (!this.template) return;
        this.showPermissionsModal = true;
      },

      closePermissionsModal() {
        this.$refs.permissionsModal.close();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sidebar-settings .editor-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    &.effect {
      display: none;
    }
  }

  .saving-placeholder {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    animation: skeleton-content 3s infinite;

    img {
      width: 80%;
    }

    .placeholder-text {
      font-size: 24px;
    }

    &.effect {
      display: flex;
    }
  }
</style>
