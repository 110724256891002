<template>
  <div class="text-widget-panel scrollbar">
    <div class="form-group template-input name">
      <label for="text-name">Name</label>
      <input
        class="form-control"
        type="text"
        id="text-name"
        placeholder="Name"
        maxlength="40"
        :disabled="isWidgetLockedByOverride"
        v-model="value.object.name"
      />
    </div>

    <div class="form-group ticker">
      <div class="custom-control custom-switch templates">
        <input
          type="checkbox"
          class="custom-control-input"
          id="text-ticker"
          :disabled="isWidgetLockedByOverride"
          v-model="value.object.ticker"
          @input="onTicker"
        />
        <label class="custom-control-label" for="text-ticker">Ticker</label>
      </div>
    </div>

    <div class="measurements-container">
      <div class="measurements">
        <div class="form-group hidden position">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">X</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-x"
                v-model="xPosition"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">Y</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="yPosition"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>

        <div class="form-group hidden size">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-width"
                v-model="width"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">H</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="height"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="extra-icons">
        <LockIcon v-show="isWidgetLocked" :locked="isWidgetLocked" />
        <VisibilityIcon :isVisible="isWidgetVisible" @clicked="updateVisibility" />
        <PixelIcon :usePixels="usePixels" @clicked="changeValuesMode" />
      </div>
    </div>

    <div class="form-group template-input font-input">
      <label for="text-font-family">
        <span>Font</span>
        <div class="import-link" @click.prevent="showFontsModal = true">
          <i class="material-icons-outlined align-middle button">upload</i>
          Import fonts
        </div>
      </label>
      <FontSelector
        :disabled="isWidgetLockedByOverride"
        :value="value.object.textFont"
        :fonts="userFonts"
        @update:value="
          (newValue) => {
            value.object.textFont = newValue;
          }
        "
      ></FontSelector>
    </div>

    <div class="header-section">
      <div class="form-group">
        <div class="custom-control custom-switch templates">
          <input
            type="checkbox"
            class="custom-control-input"
            id="header-on"
            :disabled="isWidgetLockedByOverride"
            v-model="value.object.headerOn"
          />
          <label class="custom-control-label" for="header-on">Heading</label>
        </div>
      </div>

      <template v-if="value.object.headerOn">
        <div class="form-group template-input name">
          <label for="header-text">Content</label>
          <input
            class="form-control"
            type="text"
            id="header-text"
            placeholder="Title"
            :disabled="isWidgetLockedByOverride"
            v-model="value.object.headerText"
          />
        </div>

        <div class="form-group hidden size">
          <div class="form-row">
            <color-picker
              input-el-id="text-color"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.headerTextColor"
            />

            <div class="col input-group">
              <div class="input-label">
                <span class="input-group-text">
                  <i class="material-icons-outlined align-middle">opacity</i>
                </span>
              </div>
              <input
                type="number"
                class="form-control"
                id="header-text-opacity"
                :disabled="isWidgetLockedByOverride"
                v-model="value.object.headerTextOpacity"
                @change="(event) => limitOpacity(event.target.value, 'headerTextOpacity', 100)"
              />
            </div>
          </div>
        </div>

        <div class="form-group hidden">
          <div class="form-row text-css">
            <div class="col input-group">
              <div class="input-label">
                <img
                  src="@/assets/icon/separation-icon.svg"
                  alt=""
                  style="transform: rotate(90deg)"
                />
              </div>
              <input
                type="number"
                min="0"
                max="120"
                class="form-control"
                id="header-text-separation"
                v-model="headerTextSeparation"
              />
            </div>

            <div class="col input-group">
              <div class="input-label">
                <img src="@/assets/icon/padding-icon.svg" alt="" />
              </div>
              <input
                type="number"
                min="0"
                max="100"
                class="form-control"
                id="header-text-padding"
                v-model="paddingHeader"
              />
            </div>
          </div>
        </div>

        <div class="form-row text-css">
          <div class="col form-group template-input">
            <label for="header-text-size">Font size</label>
            <input
              type="number"
              class="form-control"
              id="header-text-size"
              v-model="headerTextSize"
            />
          </div>
        </div>

        <div class="form-group alignment d-flex">
          <div class="alignments form-inline">
            <div class="alignment-mode align-content-center align-middle">
              <input
                type="radio"
                id="header-text-left-align"
                class="custom-control-input"
                hidden
                :value="ALIGN_MODES.LEFT_ALIGN"
                :disabled="isWidgetLockedByOverride"
                v-model="value.object.headerTextAlignment"
              />
              <label for="header-text-left-align" class="alignment-label">
                <i class="material-icons align-middle">format_align_left</i>
              </label>
            </div>

            <div class="alignment-mode">
              <input
                type="radio"
                id="header-text-center-align"
                class="custom-control-input"
                hidden
                :value="ALIGN_MODES.CENTER_ALIGN"
                :disabled="isWidgetLockedByOverride"
                v-model="value.object.headerTextAlignment"
              />
              <label for="header-text-center-align" class="alignment-label">
                <i class="material-icons align-middle">format_align_center</i>
              </label>
            </div>

            <div class="alignment-mode">
              <input
                type="radio"
                id="header-text-right-align"
                class="custom-control-input"
                hidden
                :value="ALIGN_MODES.RIGHT_ALIGN"
                :disabled="isWidgetLockedByOverride"
                v-model="value.object.headerTextAlignment"
              />
              <label for="header-text-right-align" class="alignment-label">
                <i class="material-icons align-middle">format_align_right</i>
              </label>
            </div>
          </div>
        </div>

        <div class="sub-section-title">Heading Background</div>

        <div class="form-group hidden">
          <div class="form-row background-css">
            <color-picker
              input-el-id="text-background-color"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.headerBackgroundColor"
            />

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">
                  <i class="material-icons-outlined align-middle">opacity</i>
                </span>
              </div>
              <input
                type="number"
                class="form-control"
                id="text-background-opacity"
                :disabled="isWidgetLockedByOverride"
                v-model="value.object.headerBackgroundOpacity"
                @change="(event) => limitOpacity(event.target.value, 'headerBackgroundOpacity', 0)"
              />
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="content-section">
      <div class="section-title">Body</div>

      <div class="form-group hidden">
        <div class="form-row text-css">
          <color-picker
            input-el-id="text-color"
            :disabled="isWidgetLockedByOverride"
            v-model="value.object.textColor"
          />

          <div class="input-group col">
            <div class="input-label">
              <span class="input-group-text">
                <i class="material-icons-outlined align-middle">opacity</i>
              </span>
            </div>
            <input
              type="number"
              class="form-control"
              id="text-opacity"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.opacity"
              @change="(event) => limitOpacity(event.target.value, 'opacity', 100)"
            />
          </div>
        </div>
      </div>

      <div class="form-group hidden">
        <div class="form-row background-css">
          <color-picker
            input-el-id="text-background-color"
            :disabled="isWidgetLockedByOverride"
            v-model="value.object.backgroundColor"
          />

          <div class="col input-group">
            <div class="input-label">
              <span class="input-group-text">
                <i class="material-icons-outlined align-middle">opacity</i>
              </span>
            </div>
            <input
              type="number"
              class="form-control"
              id="text-background-opacity"
              :disabled="isWidgetLockedByOverride"
              v-model="value.object.backgroundOpacity"
              @change="(event) => limitOpacity(event.target.value, 'backgroundOpacity', 0)"
            />
          </div>
        </div>
      </div>

      <div class="form-group hidden">
        <div class="form-row">
          <div class="col input-group">
            <div class="input-label">
              <img src="@/assets/icon/padding-icon.svg" alt="" />
            </div>
            <input
              type="number"
              min="0"
              max="100"
              class="form-control"
              id="text-padding"
              v-model="paddingText"
            />
          </div>

          <div class="form-group alignment d-flex">
            <div class="alignments form-inline">
              <div class="alignment-mode align-content-center align-middle">
                <input
                  type="radio"
                  id="text-left-align"
                  class="custom-control-input"
                  hidden
                  :value="ALIGN_MODES.LEFT_ALIGN"
                  :disabled="isWidgetLockedByOverride"
                  v-model="value.object.alignment"
                />
                <label for="text-left-align" class="alignment-label">
                  <i class="material-icons align-middle">format_align_left</i>
                </label>
              </div>

              <div class="alignment-mode">
                <input
                  type="radio"
                  id="text-center-align"
                  class="custom-control-input"
                  hidden
                  :value="ALIGN_MODES.CENTER_ALIGN"
                  :disabled="isWidgetLockedByOverride"
                  v-model="value.object.alignment"
                />
                <label for="text-center-align" class="alignment-label">
                  <i class="material-icons align-middle">format_align_center</i>
                </label>
              </div>

              <div class="alignment-mode">
                <input
                  type="radio"
                  id="text-right-align"
                  class="custom-control-input"
                  hidden
                  :value="ALIGN_MODES.RIGHT_ALIGN"
                  :disabled="isWidgetLockedByOverride"
                  v-model="value.object.alignment"
                />
                <label for="text-right-align" class="alignment-label">
                  <i class="material-icons align-middle">format_align_right</i>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group layout-input">
        <div class="text-mode">
          <textarea
            class="form-control"
            id="text-user-content"
            rows="3"
            :disabled="isWidgetLockedByOverride"
            v-model="value.object.userText"
          ></textarea>
        </div>
      </div>

      <div class="form-group template-input text-size input-la">
        <label for="text-size">Text size</label>

        <div class="input-group">
          <input
            type="number"
            min="0"
            max="100"
            class="form-control"
            id="text-size"
            v-model="textSize"
          />
        </div>
      </div>
    </div>

    <div v-show="value.object.ticker" class="content-section">
      <div class="form-group ticker">
        <div class="custom-control custom-switch layouts">
          <input
            type="checkbox"
            class="custom-control-input"
            id="text-marquee"
            :disabled="isWidgetLockedByOverride"
            v-model="value.object.marqueeMode"
            @input="onMarquee"
          />
          <label class="custom-control-label" for="text-marquee">Marquee mode</label>
        </div>
      </div>

      <div class="section-title">{{ value.object.marqueeMode ? 'Marquee' : 'Ticker' }}</div>

      <div v-show="!value.object.marqueeMode" class="form-group ticker-duration layout-input">
        <label for="text-ticker-duration">Duration (Secs)</label>
        <input
          id="text-ticker-duration"
          type="number"
          class="form-control"
          v-model="tickerDuration"
        />
      </div>

      <div v-show="!!value.object.marqueeMode" class="ticker-options">
        <div class="form-group layout-input text-size slider-container">
          <label for="marquee-speed" class="slider"> Speed </label>
          <input
            type="range"
            class="custom-range"
            data-slider-min="1"
            :disabled="isWidgetLockedByOverride"
            v-model="value.object.marqueeSpeed"
            data-slider-max="40"
            data-slider-step="1"
            id="marquee-speed"
          />
        </div>
      </div>
    </div>

    <FontsModal @closeModal="showFontsModal = false" v-if="showFontsModal" />
  </div>
</template>

<script>
  import _ from 'lodash';
  import ColorPicker from '@/components/ColorPicker.vue';
  import FontsModal from '@/components/common/FontsModal.vue';
  import PixelIcon from '@/components/layoutDesigner/editor/settingPanel/PixelIcon.vue';
  import LockIcon from '@/components/layoutDesigner/editor/settingPanel/LockIcon.vue';
  import VisibilityIcon from '@/components/layoutDesigner/editor/settingPanel/VisibilityIcon.vue';
  import widgetMixin from '@/components/templates/editor/settingPanel/widgetMixin';
  import { ALIGN_MODES } from '@/models/layoutDesigner';
  import { restrictToBounds } from '@/helpers/draggable';
  import FontSelector from '@/components/common/fontSelector/FontSelector.vue';

  export default {
    name: 'TextWidgetPanel',

    mixins: [widgetMixin],

    components: { FontSelector, ColorPicker, FontsModal, PixelIcon, LockIcon, VisibilityIcon },

    data() {
      return {
        ALIGN_MODES,
        showFontsModal: false,
      };
    },

    computed: {
      textSize: {
        get() {
          return this.value.object.textSize;
        },
        set(value) {
          this.value.object.textSize = Number(value);
        },
      },

      headerTextSize: {
        get() {
          return this.value.object.headerTextSize;
        },
        set(value) {
          this.value.object.headerTextSize = Number(value);
        },
      },

      headerTextSeparation: {
        get() {
          return this.value.object.headerTextSeparation;
        },
        set(value) {
          this.value.object.headerTextSeparation = Number(value);
        },
      },

      paddingHeader: {
        get() {
          return this.value.object.paddingHeader;
        },
        set(value) {
          this.value.object.paddingHeader = Number(value);
        },
      },

      paddingText: {
        get() {
          return this.value.object.paddingText;
        },
        set(value) {
          this.value.object.paddingText = Number(value);
        },
      },

      tickerDuration: {
        get() {
          return this.value.object.tickerDuration;
        },
        set(value) {
          this.$set(this.value.object, 'tickerDuration', restrictToBounds(Number(value), 1));
        },
      },

      userFonts() {
        return this.$store.getters.userFonts;
      },
    },

    filters: {
      capitalize(v) {
        return _.capitalize(v);
      },
    },

    methods: {
      onTicker(event) {
        const isChecked = event.target.checked;
        this.value.object.ticker = isChecked;
      },

      onMarquee(event) {
        const isChecked = event.target.checked;
        this.$set(this.value.object, 'marqueeMode', isChecked);
      },

      limitOpacity(value, key, defaultValue) {
        if (!value & (value !== 0)) {
          this.$set(this.value.object, key, defaultValue);
        }

        if (value > 100) {
          this.$set(this.value.object, key, 100);
        }

        if (value < 0) {
          this.$set(this.value.object, key, 0);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .text-widget-panel {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;

    .rss-mode {
      background-color: #ecf0f1;
    }

    .alignments {
      display: flex;
      gap: 8px;

      .alignment-mode {
        width: 26px;
        margin: 0 2px;

        input:checked + label {
          color: $primaryText;
          background: #f5f5f5;
          padding: 16px;
        }

        input + label {
          width: 30px;
          height: 30px;
          border-radius: 4px;
          color: $secondaryText;
        }

        .alignment-label {
          cursor: pointer;
        }
      }
    }

    .content-section,
    .header-section {
      padding-top: 6px;

      .section-title {
        font-weight: bold;
        padding-top: 16px;
        font-size: 16px;
        border-top: 1px solid $lineGrey;
        margin-bottom: 10px;
      }
    }

    .sub-section-title {
      padding-top: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
    }

    .custom-switch {
      margin-left: 4px;
    }

    .ticker-options .custom-control-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #333332;
    }
  }

  .form-group.mode {
    display: flex;
    align-items: center;
    padding-top: 16px;

    .custom-control-inline {
      display: flex;
      align-items: center;
    }
    .custom-control-label {
      cursor: pointer;

      &::before {
        border: none;
        background-color: $primaryRed;
        top: 2px;
      }

      &::after {
        top: 1.5px;
      }
    }

    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none;
    }
  }

  .font-input {
    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .import-link {
        cursor: pointer;
        border-bottom: 2px solid transparent;

        &:hover {
          border-bottom-color: $primaryText;
        }
      }
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $primaryRed;
    border-color: $primaryRed;
  }
</style>
