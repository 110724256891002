<script lang="ts">
  export default {
    name: 'Pulse',
  };
</script>

<script setup lang="ts">
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
  import PulseMonitoring from './PulseMonitoring.vue';

  import { ref } from 'vue';
  import PulseSettings from './PulseSettings.vue';

  const tabs = ref<Array<String>>(['Monitoring', 'Settings']);
  const activeTab = ref<String | null>(tabs.value[0]);

  function setActiveTab(tab: String) {
    activeTab.value = tab;
  }
</script>

<template>
  <div class="content-body">
    <div class="title">
      <BaseText variant="pageTitle">Pulse</BaseText>
    </div>
    <!-- <div class="tabs">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="['tab', { active: activeTab === tab }]"
        @click="setActiveTab(tab)"
      >
        <BaseText variant="subHeading">{{ tab }}</BaseText>
      </div>
    </div> -->
    <div class="tab-content">
      <PulseMonitoring v-if="activeTab === 'Monitoring'"></PulseMonitoring>
      <PulseSettings v-if="activeTab === 'Settings'"></PulseSettings>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .content-body {
    padding: 48px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    .title,
    .tabs,
    .tab-main-table {
      padding-left: 16px;
    }
    .tabs {
      display: flex;
      .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        flex: 1;
        border-bottom: 2px solid $lineGrey;
        padding: 8px 16px;
        width: 120px;

        transition: border-color 200ms ease-in-out;
        color: #6a6b6a;
        cursor: pointer;

        &:hover {
          border-color: #d6d6d6;
          color: #555555;
        }
        &.active {
          border-color: $primaryRed;
          color: $primaryText;
        }
      }
    }
  }
</style>
