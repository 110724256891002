<script lang="ts">
  export default {
    name: 'ScreenPublishStatus',
  };
</script>

<script setup lang="ts">
  import { useStore } from '@/store';
  import { GET_PUBLISH_STATUS, SET_PUBLISH_BAR_DISPLAY } from '@/store/actions/pulse';
  import { PublishStatus } from '@/types/api/pulse';
  import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
  import ProgressBar from '@/components/ProgressBar.vue';
  import { Colors } from '@/constant/theme';

  const store = useStore();

  const isExpanded = ref(false);
  const intervalId = ref<number | null>(null);
  const hasPendingPublish = ref(true);
  const totalScreens = ref<number>(0);
  const totalPendingScreens = ref<number>(0);
  const totalFailedScreens = ref<number>(0);

  const isPublishBarVisible = computed(() => {
    return store.getters.getShowPublishBar;
  });
  const publishStatus = computed(() => {
    return store.getters.getPublishStatus as Array<PublishStatus>;
  });
  const progressBarColor = computed(() => {
    if (!hasPendingPublish.value && !totalFailedScreens.value) {
      return Colors.GREEN_LIGHT;
    }

    return Colors.PRIMARY_RED;
  });
  const uploadPercent = computed(() => {
    if (totalScreens.value === 0) {
      return 0;
    }

    return ((totalScreens.value - totalPendingScreens.value) * 100) / totalScreens.value;
  });

  watch(publishStatus, () => {
    const pendingPublish = publishStatus.value.filter(
      (pub: PublishStatus) => pub.publishStatus === 'Pending' || !pub.publishStatus,
    );
    const failedPublish = publishStatus.value.filter(
      (pub: PublishStatus) => pub.publishStatus === 'Failed',
    );
    if (pendingPublish.length == 0) {
      hasPendingPublish.value = false;
      destroyTimer();
    }
    totalScreens.value = publishStatus.value.length;
    totalPendingScreens.value = pendingPublish.length;
    totalFailedScreens.value = failedPublish.length;
  });

  watch(isPublishBarVisible, () => {
    if (!isPublishBarVisible.value) {
      destroyTimer();
    }
  });

  onMounted(() => {
    fetchPublishStatus();
    intervalId.value = setInterval(fetchPublishStatus, 5000);
  });

  onUnmounted(() => {
    destroyTimer();
  });

  function closePulseBar() {
    store.dispatch(SET_PUBLISH_BAR_DISPLAY, false);
  }
  function toggleIsExpanded() {
    isExpanded.value = !isExpanded.value;
  }

  async function fetchPublishStatus() {
    await store.dispatch(GET_PUBLISH_STATUS);
  }

  function destroyTimer() {
    if (!intervalId.value) return;
    clearInterval(intervalId.value);
  }
</script>

<template>
  <div class="publish-data-container" v-if="isPublishBarVisible">
    <div class="overlay" v-if="isExpanded"></div>
    <div class="publish-status">
      <div class="bar">
        <img
          class="spin-animation progress-icon"
          v-if="hasPendingPublish"
          src="@/assets/icon/progress-3.png"
          alt="Background Color"
        />
        <img
          class="progress-icon"
          v-else-if="totalFailedScreens"
          src="@/assets/icon/error_outline.svg"
          alt="Background Color"
        />
        <img
          class="progress-icon"
          v-else
          src="@/assets/icon/check_circle_outline.svg"
          alt="Background Color"
        />
        <div class="info">
          <div class="stat" v-if="hasPendingPublish">
            <BaseText variant="title1"
              >{{ totalScreens - totalPendingScreens }} out of {{ totalScreens }}&nbsp;</BaseText
            ><BaseText variant="body2">screens updated</BaseText>
          </div>
          <div class="stat" v-else>
            <BaseText variant="title1"
              >{{
                totalFailedScreens ? totalFailedScreens : totalScreens - totalPendingScreens
              }}
              out of {{ totalScreens }}&nbsp;</BaseText
            ><BaseText variant="body2">{{
              totalFailedScreens ? 'failed to update' : 'screens updated'
            }}</BaseText>
          </div>
          <ProgressBar :percent="uploadPercent" :color="progressBarColor" />
        </div>
        <div class="icons">
          <i
            class="material-icons-outlined"
            :class="isExpanded ? 'arrow-up' : 'arrow-down'"
            @click="toggleIsExpanded"
            >chevron_right</i
          >
          <i class="material-icons-outlined close-icon" @click="closePulseBar">close</i>
        </div>
      </div>
      <div v-if="isExpanded" class="expanded-view scrollbar">
        <div v-if="!publishStatus.length" class="no-screen">
          <BaseText>No screen found</BaseText>
        </div>
        <table v-else>
          <thead>
            <tr>
              <td>Name</td>
              <td>Group</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="screen in publishStatus" :key="screen.screenId">
              <td class="screen-info">
                <span class="screen-status" :class="`color-${screen.connectionStatus ?? 1}`"></span
                >{{ screen.screenName }}
              </td>
              <td class="group">
                <img src="@/assets/icon/icon-group.svg" />{{ screen.screenGroup }}
              </td>
              <td class="status">
                <span :class="screen.publishStatus"></span><span>{{ screen.publishStatus }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .publish-data-container {
    padding-top: 48px;
    .overlay {
      height: 100vh;
      width: 100vw;
      background-color: #000;
      opacity: 0.5;
      z-index: 999;
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  .publish-status {
    z-index: 9999;
    font-family: 'Poppins';
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 10px 0 24px;
    > div {
      width: 100%;
      background: #ffffff;
    }
    .bar {
      border: 2px solid $lineGrey;
      .progress-icon {
        padding: 20px;
      }
      display: flex;
      .info {
        padding: 15px 20px 15px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex-grow: 1;
        .stat {
          display: flex;
          justify-content: flex-start;
          align-items: baseline;
        }
      }
    }
    .expanded-view {
      position: absolute;
      top: 86px;
      width: calc(100% - 34px);
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      border: 2px solid $lineGrey;
      max-height: 400px;
      overflow-y: auto;
      padding: 16px;
      .no-screen {
        text-align: center;
      }
      > table {
        width: 100%;
        thead {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }
        tbody {
          td:not(:first-child) {
            padding: 8px 0;
          }
          .screen-info {
            width: 40%;
            .screen-status {
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              border: 3px solid;
              margin-right: 8px;
              &.color-2 {
                background-color: $greenLight;
                border-color: $greenLightFaded;
              }
              &.color-0 {
                background-color: $redLight;
                border-color: $redLightFaded;
              }
              &.color-1 {
                background-color: $greyLight;
                border-color: $greyLightFaded;
              }
            }
          }
          .group {
            width: 40%;
            color: $secondaryText;
            img {
              margin-right: 8px;
            }
          }
          .status {
            width: 20%;
            > span {
              display: inline-block;
              height: 16px;
              width: 16px;
              margin-right: 8px;
              vertical-align: middle;
            }
            .Pending {
              background-image: url('@/assets/icon/pending.svg');
              + span {
                vertical-align: baseline;
              }
            }
            .Updated {
              background-image: url('@/assets/icon/check.svg');
              + span {
                vertical-align: baseline;
              }
            }
            .Failed {
              background-image: url('@/assets/icon/error.svg');
              + span {
                color: $primaryRed;
                vertical-align: baseline;
              }
            }
          }
        }
      }
    }
  }

  .icons {
    position: absolute;
    right: 24px;
    top: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 8px;
    i {
      cursor: pointer;
    }
    .arrow-down {
      transform: rotate(90deg);
    }
    .arrow-up {
      transform: rotate(270deg);
    }
  }
</style>
