<script lang="ts">
  export default {
    name: 'CopyContentModal',
  };
</script>

<script setup lang="ts">
  import { adminApiGetOrganisations } from '@/api/organisation';
  import { useToasted } from '@/helpers';
  import { Organisation } from '@/types/api/organisation';
  import { OrganisationContentCopy } from '@/types/widgets/copyContent';
  import { computed, defineProps, onMounted, ref, watch } from 'vue';
  import Modal from '@/components/common/Modal.vue';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';
  import Loader from '@/components/common/Loader.vue';
  import moment from 'moment';
  import { apiCopyContent } from '@/api/contentCopy';

  const props = defineProps({
    contentType: {
      type: String,
      required: true,
    },
    contentId: {
      type: String,
      required: true,
    },
  });
  const emits = defineEmits<{
    (event: 'closeModal', value: void): void;
  }>();

  const toasted = useToasted();
  const searchText = ref<string | null>(null);
  const orgs = ref<Array<OrganisationContentCopy>>([]);
  const isLoading = ref<boolean>(false);
  const isSaving = ref(false);
  const selectedOrgs = ref<Array<OrganisationContentCopy>>([]);

  const filteredOrgs = computed(() => {
    if (!searchText.value) return orgs.value;

    return orgs.value.filter(
      (org) => org.name.toLowerCase().indexOf(searchText.value?.toLowerCase() as string) !== -1,
    );
  });

  watch(
    () => orgs.value.map((org) => org.isSelected),
    () => {
      selectedOrgs.value = orgs.value.filter((org) => org.isSelected);
    },
    { deep: true },
  );

  onMounted(async () => {
    isLoading.value = true;
    try {
      const response = await adminApiGetOrganisations();
      orgs.value = response.data.map((org: Organisation) => {
        return {
          ...org,
          isSelected: false,
        };
      });
    } catch (err) {
      console.log('Failed to get organisations');
    }
    isLoading.value = false;
  });

  function closeModal() {
    emits('closeModal');
  }

  function formatDate(value: string) {
    if (!value) return;
    const ts = moment.utc(value);
    return ts.local().format('D/MM/Y');
  }

  async function copyContent() {
    isSaving.value = false;
    try {
      await apiCopyContent(
        props.contentId,
        props.contentType,
        selectedOrgs.value.map((org) => org.organisation_id),
      );
      toasted.global.general_success({
        message: `Copying ${props.contentType} to selected organisations queued for processing`,
      });
      closeModal();
    } catch {
      toasted.global.general_error({
        message: `Error in copying ${props.contentType} to selected organisations`,
      });
    }
    isSaving.value = false;
  }
</script>

<template>
  <Modal
    @closeModal="closeModal"
    :isSaving="isSaving"
    title="Copy to Organisation"
    :okFunction="copyContent"
    :modalStyles="{ width: '768px' }"
    :disabled="selectedOrgs.length === 0"
    okButtonLabel="Copy"
    avoidClickOutside
    noScrollbar
  >
    <template v-slot:body>
      <div class="content-copy-body">
        <div class="search">
          <i class="material-symbols-outlined search-icon">search</i>
          <input type="text" v-model="searchText" placeholder="Search" />
        </div>
        <Loader v-if="isLoading"></Loader>
        <div class="org-table" v-else>
          <div class="header">
            <BaseText variant="subtitle2" class="name">Name</BaseText>
            <BaseText variant="subtitle2" class="users">Users</BaseText>
            <BaseText variant="subtitle2" class="created">Created</BaseText>
            <BaseText variant="subtitle2" class="action"></BaseText>
          </div>
          <div class="org-content scrollbar">
            <div class="item" v-for="org in filteredOrgs" :key="org.organisation_id">
              <BaseText variant="body1" class="name">{{ org.name }}</BaseText>
              <BaseText variant="body1" class="users">{{ org.user_count }}</BaseText>
              <BaseText variant="body1" class="created">{{ formatDate(org.created) }}</BaseText>
              <input v-model="org.isSelected" type="checkbox" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
  .content-copy-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .search {
      position: relative;
      .search-icon {
        position: absolute;
        top: 10px;
        left: 10px;
      }
      > input {
        border: 2px solid #e8e8e8;
        border-radius: 8px;
        padding: 8px 8px 8px 40px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #6a6b6a;
        width: 100%;
      }
    }
    .org-table {
      .header {
        display: flex;
        flex-direction: row;
        padding: 0 24px 0 16px;
        margin-bottom: 8px;
      }
      .org-content {
        max-height: 500px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .item {
          .name,
          .created,
          .users,
          .action {
            color: $primaryText;
          }
          > input {
            margin-left: auto;
          }
          display: flex;
          flex-direction: row;
          height: 56px;
          background-color: #f8f8f8;
          padding: 16px;
          margin-right: 8px;
          > input {
            cursor: pointer;
          }
        }
      }
      .name,
      .created {
        width: 40%;
      }
      .users,
      .action {
        width: 10%;
      }
    }
  }
</style>
