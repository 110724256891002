<template>
  <div id="app">
    <component :is="layout">
      <alert v-if="isViewAsModeActive" :dismissible="false" styleclass="alert-danger view-as-alert">
        <div class="view-as-content">
          <div>
            Logged in as: <b>{{ adminViewUser.username }}</b>
          </div>
          <button class="logout" @click="endViewAsMode">End Mode</button>
        </div>
      </alert>
      <router-view />
    </component>

    <PlayerModal v-if="showPlayerModal" />
  </div>
</template>

<script>
  import { VIEW_AS_USER_EXIT } from '@/store/actions/auth';
  import { ORGANISATION_REQUEST } from '@/store/actions/organisation';

  import Alert from '@/components/Alert.vue';
  import PlayerModal from '@/components/common/PlayerModal.vue';

  window.jQuery = require('jquery');

  window.$ = window.jQuery;

  require('./plugins/jquery.textfill.min');

  window.ekkoLightbox = require('ekko-lightbox');

  const defaultLayout = 'dashboard';

  export default {
    name: 'app',

    components: { Alert, PlayerModal },

    mounted() {
      if (this.$store.getters.isAuthenticated) {
        this.$store.dispatch(ORGANISATION_REQUEST);
      }
    },

    computed: {
      layout() {
        return `${this.$route.meta.layout || defaultLayout}-layout`;
      },
      adminViewUser() {
        if (this.$store.state.auth.viewAsUser) return this.$store.state.auth.viewAsUser;
        // if (this.$store.state.auth.superAdminToken) return this.$store.state.user.user;
        return null;
      },
      isViewAsModeActive() {
        return !!this.adminViewUser;
      },
      showPlayerModal() {
        return this.$store.state.player.showPlayerModal;
      },
    },

    methods: {
      endViewAsMode() {
        this.$store.dispatch(VIEW_AS_USER_EXIT).then(() => {
          // go to organization dashboard
          this.$router.push({ name: 'Organisation' });
          // reload the page
          window.location.reload();
        });
      },
    },
  };
</script>

<style lang="scss">
  .view-as-alert {
    position: fixed;
    top: 5px;
    left: 50%;
    padding: 5px 5px;
    transform: translate(-50%);
    min-width: 400px;
    height: 40px;
    border-radius: 25px;
    z-index: 9999;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    .view-as-content {
      padding-left: 10px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      word-break: break-all;

      b {
        font-size: 14px;
      }
    }

    button.logout {
      background-color: #ffffff;
      border: none;
      color: #000000;
      font-size: 14px;
      font-weight: 700;
      border-radius: 25px;
      padding: 5px 10px;
      cursor: pointer;
      transition: all 0.3s ease;
    }

    button.logout:hover {
      background-color: #fff7bc;
    }
  }
</style>
