<template>
  <vue-draggable-resizable
    v-if="!hideParent"
    :parent="true"
    :x="position.left"
    :y="position.top"
    :w="position.width"
    :h="position.height"
    :draggable="!isSavingTemplate && !isWidgetLocked && !isWidgetLockedByOverride"
    :resizable="!isSavingTemplate && !isWidgetLocked && !isWidgetLockedByOverride"
    :grid="gridScale"
    :snap="isSnapActive"
    :active="isWidgetSelected"
    :z="(isWidgetSelected && keepZIndexUp ? 1000 : 100) + widget.position.zIndex"
    :onDragStart="(x, y) => onChangePosition(true, x, y)"
    :onResizeStart="() => onChangePosition(false)"
    @dragstop="onWidgetDrag"
    @resizestop="onWidgetResize"
    @activated="() => onSelected(widget)"
    @deactivated="() => (keepZIndexUp = false)"
    @refLineParams="getRefLineParams"
  >
    <div class="widget-item trafiklab-container" :id="widget.object.wid" :style="style">
      <TrafiklabViewer
        :viewerStyles="widget.object.viewerStyles"
        :timetable="widget.object.timetable"
        :appId="widget.object.wid"
        :name="widget.object.name"
        alt="Trafiklab app"
      />
    </div>
  </vue-draggable-resizable>
</template>

<script>
  import TrafiklabViewer from '@/components/common/timetable/TrafiklabViewer.vue';
  import VueDraggableResizable from '@/components/common/VueDraggableResizable.vue';

  import draggableWidget from '@/components/templates/mixins/draggableWidget';

  import { simpleTypeMixin } from '@/helpers';
  import { getRBGAColor } from '@/helpers/utils';

  export default {
    name: 'TrafiklabWidget',

    components: {
      VueDraggableResizable,
      TrafiklabViewer,
    },

    mixins: [simpleTypeMixin, draggableWidget],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    computed: {
      style() {
        return {
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          backgroundColor: getRBGAColor(
            this.widget.object.backgroundColor,
            this.widget.object.opacity / 100,
          ),
        };
      },

      position() {
        return this.getPosition(this.widget);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .trafiklab-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
  }
</style>
