<template>
  <div class="sidebar scrollbar">
    <a class="navbar-brand" href="/">
      <img class="logo w-165" src="@/assets/img/sidebar/picflow-logo.svg" alt="Picflow logo" />
    </a>

    <div class="navbar-nav">
      <ul class="main-nav-links">
        <li v-for="page in pages" :key="page.name" class="nav-item cursor-pointer">
          <router-link class="nav-link" :to="{ name: page.name }" :title="page.name">
            <div :class="currentPage == page.name ? 'active' : ''" class="nav-item-container">
              <i
                class="align-middle"
                :class="
                  page.iconTypeSymbol ? 'material-symbols-outlined' : 'material-icons-outlined'
                "
                >{{ page.icon }}</i
              >
              <span class="text">{{ page.name }}</span>
            </div>
          </router-link>
        </li>

        <template v-if="isAdmin">
          <li v-for="page in adminPages" :key="page.name" class="nav-item cursor-pointer">
            <router-link class="nav-link" :to="{ name: page.name }" :title="page.name">
              <div :class="currentPage == page.name ? 'active' : ''" class="nav-item-container">
                <i class="material-icons-outlined align-middle">{{ page.icon }}</i>
                <span class="text">{{ page.name }}</span>
              </div>
            </router-link>
          </li>
        </template>
      </ul>

      <ul class="sub-nav-links">
        <li class="nav-item cursor-pointer">
          <a class="nav-link" href="mailto:support@picler.se" title="Help & Support" rel="help">
            <div class="nav-item-container">
              <i class="material-icons-outlined align-middle">support_agent</i>
              <span>HELP & SUPPORT</span>
            </div>
          </a>
        </li>

        <li class="nav-item cursor-pointer">
          <router-link
            class="nav-link"
            to="/account"
            title="Settings"
            @click.native="leftMenuClick"
          >
            <div :class="currentPage === 'SETTINGS' ? 'active' : ''" class="nav-item-container">
              <i class="material-icons-outlined align-middle">settings</i>
              <span>SETTINGS</span>
            </div>
          </router-link>
        </li>

        <li class="nav-item cursor-pointer">
          <div class="nav-link nav-item-container" title="Log out" @click="logout">
            <i class="material-icons-outlined align-middle icon-flipped logout">logout</i>
            <span>LOG OUT</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { AUTH_LOGOUT } from '@/store/actions/auth';

  const $ = window.jQuery;

  const pages = [
    {
      name: 'Dashboard',
      icon: 'home',
    },
    {
      name: 'Screens',
      icon: 'monitor',
    },
    {
      name: 'Content',
      icon: 'insert_photo',
    },
    {
      name: 'Templates',
      icon: 'photo_size_select_large',
    },
    {
      name: 'Layouts',
      icon: 'dashboard',
    },
    {
      name: 'Applications',
      icon: 'apps',
    },
    {
      name: 'Pulse',
      icon: 'vital_signs',
      iconTypeSymbol: true,
    },
  ];

  const adminPages = [
    {
      name: 'Organisation',
      icon: 'account_tree',
    },
  ];

  export default {
    name: 'LeftSidebar',

    data() {
      return {
        pages,
        adminPages,
      };
    },

    computed: {
      currentPage() {
        return this.$route.name;
      },

      isAdmin() {
        return this.$store.getters.isOrganizationAdmin || this.$store.getters.isAdministrator;
      },
    },

    methods: {
      async logout() {
        try {
          await this.$store.dispatch(AUTH_LOGOUT);
        } catch (error) {
          console.error(error);
          this.$toasted.global.general_error({
            message: 'Ups, something went wrong, please try again later.',
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon-flipped.logout {
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
  }
</style>
