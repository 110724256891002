<template>
  <div class="layer-widget-panel scrollbar">
    <div class="form-group">
      <label for="template-name">Name</label>
      <input
        class="form-control"
        type="text"
        id="template-name"
        placeholder="Name"
        v-model="value.object.name"
        disabled
      />
    </div>

    <div class="measurements-container">
      <div class="measurements">
        <div class="form-group hidden position">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">X</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-x"
                v-model="xPosition"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">Y</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="yPosition"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>

        <div class="form-group hidden size">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-width"
                v-model="width"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">H</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="height"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="extra-icons">
        <LockIcon :locked="isWidgetLocked" />
        <VisibilityIcon :isVisible="isWidgetVisible" @clicked="updateVisibility" />
        <PixelIcon :usePixels="usePixels" @clicked="changeValuesMode" />
      </div>
    </div>

    <div v-if="showStopNameSearch" class="form-group">
      <BaseInput label="Stop Name">
        <Multiselect
          :limit="1"
          :options="trafiklabStops"
          :internal-search="false"
          track-by="name"
          label="name"
          placeholder="Search stops"
          :searchable="true"
          :allow-empty="false"
          open-direction="bottom"
          :loading="loadingStop"
          :showLabels="false"
          @search-change="searchStops"
          @select="updateTrafiklabStop"
        >
          <template v-slot="{ option }">
            <div>{{ option.name }}</div>
          </template>
          <template #noResult>
            <span>No stops found</span>
          </template>
          <template #noOptions>
            <span>Enter a stop name</span>
          </template>
        </Multiselect>
      </BaseInput>
    </div>

    <div v-else class="form-group stop-name">
      <label for="stop-name"> Stop Name </label>
      <input
        class="form-control"
        type="text"
        id="stop-name"
        placeholder="Name"
        v-model="value.object.timetable[0].stop.name"
        disabled
      />
    </div>

    <div class="show-searh-button" @click="showStopNameSearch = !showStopNameSearch">
      {{ showStopNameSearch ? 'Cancel' : 'Change Stop' }}
    </div>

    <div class="form-group layout-input">
      <label for="playTime">Play time</label>
      <select
        class="form-control"
        id="playTime"
        v-model="value.object.timetable[0].playTime"
        :disabled="isWidgetLockedByOverride"
      >
        <option v-for="(time, index) in playTimesOptions" :key="index" :value="time.value">
          {{ time.label }}
        </option>
      </select>
    </div>

    <div class="form-group layout-input">
      <label for="language">Language</label>
      <select
        class="form-control"
        id="language"
        v-model="value.object.timetable[0].language"
        :disabled="isWidgetLockedByOverride"
      >
        <option v-for="key in Object.keys(languages)" :key="key" :value="languages[key]">
          {{ key }}
        </option>
      </select>
    </div>

    <div class="form-group layout-input">
      <label for="hideTimeInMin">Hide departures</label>
      <input
        type="number"
        class="form-control"
        v-model="hideDepartureTime"
        min="1"
        max="60"
        id="hideTimeInMin"
      />
      <div class="hide-time-subtitle">Hide departures that depart within X minutes</div>
    </div>

    <div class="form-group">
      <div class="form-label">Select Traffic Types</div>
      <div class="transport-container scrollbar">
        <div v-if="value.object.timetable[0].stop.availableForBus" class="transport">
          <div class="transport-title">BUS</div>
          <div class="transport-switch">
            <div class="custom-control custom-switch check">
              <input
                type="checkbox"
                class="custom-control-input"
                id="bus-traffic"
                :disabled="checkIfAllowedToSwitch('BUS')"
                v-model="value.object.timetable[0].trafficTypes.BUS"
              />
              <label class="custom-control-label" for="bus-traffic"></label>
            </div>
          </div>
        </div>

        <div v-if="value.object.timetable[0].stop.availableForMetro" class="transport">
          <div class="transport-title">METRO</div>
          <div class="transport-switch">
            <div class="custom-control custom-switch check">
              <input
                type="checkbox"
                class="custom-control-input"
                id="metro-traffic"
                :disabled="checkIfAllowedToSwitch('METRO')"
                v-model="value.object.timetable[0].trafficTypes.METRO"
              />
              <label class="custom-control-label" for="metro-traffic"></label>
            </div>
          </div>
        </div>

        <div v-if="value.object.timetable[0].stop.availableForShip" class="transport">
          <div class="transport-title">SHIP</div>
          <div class="transport-switch">
            <div class="custom-control custom-switch check">
              <input
                type="checkbox"
                class="custom-control-input"
                id="ship-traffic"
                :disabled="checkIfAllowedToSwitch('SHIP')"
                v-model="value.object.timetable[0].trafficTypes.SHIP"
              />
              <label class="custom-control-label" for="ship-traffic"></label>
            </div>
          </div>
        </div>

        <div v-if="value.object.timetable[0].stop.availableForTrain" class="transport">
          <div class="transport-title">TRAIN</div>
          <div class="transport-switch">
            <div class="custom-control custom-switch check">
              <input
                type="checkbox"
                class="custom-control-input"
                id="train-traffic"
                :disabled="checkIfAllowedToSwitch('TRAIN')"
                v-model="value.object.timetable[0].trafficTypes.TRAIN"
              />
              <label class="custom-control-label" for="train-traffic"></label>
            </div>
          </div>
        </div>

        <div v-if="value.object.timetable[0].stop.availableForTram" class="transport">
          <div class="transport-title">TRAM</div>
          <div class="transport-switch">
            <div class="custom-control custom-switch check">
              <input
                type="checkbox"
                class="custom-control-input"
                id="tram-traffic"
                :disabled="checkIfAllowedToSwitch('TRAM')"
                v-model="value.object.timetable[0].trafficTypes.TRAM"
              />
              <label class="custom-control-label" for="tram-traffic"></label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <TrafiklabPanelSetting
      :viewer-styles="value.object.viewerStyles"
      :disabled="isWidgetLockedByOverride"
      @update:viewerStyles="
        (newViewerStyles) => {
          updateViewerStyles(newViewerStyles);
        }
      "
    />
  </div>
</template>

<script>
  import ClickOutside from 'vue-click-outside';
  import Multiselect from 'vue-multiselect';
  import { debounce } from 'lodash';

  import PixelIcon from '@/components/layoutDesigner/editor/settingPanel/PixelIcon.vue';
  import LockIcon from '@/components/layoutDesigner/editor/settingPanel/LockIcon.vue';
  import TrafiklabPanelSetting from '@/components/common/appWidgetPanel/trafiklabSettingPanel/TrafiklabSettingPanel.vue';
  import VisibilityIcon from '@/components/layoutDesigner/editor/settingPanel/VisibilityIcon.vue';

  import widgetMixin from '@/components/templates/editor/settingPanel/widgetMixin';
  import { restrictToBounds } from '@/helpers/draggable';
  import { getStopLocations } from '@/api/transport';

  import { TL_LANGUAGES } from '@/config/constants';

  export default {
    name: 'TrafiklabWidgetPanel',

    mixins: [widgetMixin],

    directives: {
      ClickOutside,
    },

    components: {
      TrafiklabPanelSetting,
      PixelIcon,
      LockIcon,
      Multiselect,
      VisibilityIcon,
    },

    data() {
      return {
        folderName: '',
        trafiklabStops: [],
        playTimesOptions: [
          {
            label: '15 : 00',
            value: '15',
          },
          {
            label: '30 : 00',
            value: '30',
          },
          {
            label: '1 : 00 : 00',
            value: '60',
          },
        ],
        languages: TL_LANGUAGES,
        loadingStop: false,
        showStopNameSearch: false,
      };
    },

    computed: {
      isMinimunTrafficTypeReached() {
        const trafficTypesArray = Object.values(this.value.object.timetable[0].trafficTypes || []);

        return trafficTypesArray.filter((traffic) => !!traffic).length <= 1;
      },

      hideDepartureTime: {
        get() {
          return Number(this.value.object.timetable[0].hideTime) || 1;
        },
        set(value) {
          this.$set(
            this.value.object.timetable[0],
            'hideTime',
            restrictToBounds(Number(value), 0, 60),
          );
        },
      },
    },

    methods: {
      updateViewerStyles(viewerStyles) {
        this.$set(this.value.object, 'viewerStyles', viewerStyles);
      },

      updateTrafiklabStop(value) {
        if (!value?.id) return;

        this.showStopNameSearch = false;

        this.$set(this.value.object.timetable[0], 'stop', value);
      },

      searchStops: debounce(async function (searchValue = '') {
        try {
          if (!searchValue || searchValue.length <= 2) return;

          this.loadingStop = true;

          const stops = await getStopLocations(searchValue);

          this.trafiklabStops = stops.filter((stop) => !!stop.id);
        } catch (error) {
          toasted.global.general_error({ message: 'Failed to fetch the stops.' });
        }

        this.loadingStop = false;
      }, 600),

      checkIfAllowedToSwitch(trafficType) {
        return (
          this.isWidgetLockedByOverride ||
          (this.isMinimunTrafficTypeReached &&
            this.value.object?.timetable[0].trafficTypes[trafficType])
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .show-searh-button {
    width: 100%;
    text-align: right;
    margin-top: -8px;

    &:hover {
      cursor: pointer;
      color: $primaryRed;
    }
  }

  .form-group label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .transport-container {
    border-radius: 8px;
    padding: 10px;
    border: 2px solid $borderGrey;
    max-height: 200px;
    overflow-y: auto;

    .custom-switch {
      margin-right: -8px;
    }

    .transport {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      color: $primaryText;
    }
  }

  .picker {
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 20;
  }

  .layer-widget-panel {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0px;
    padding-right: 10px;

    .button-2-secondary {
      margin: 16px 0;
      width: 100%;

      &.disabled {
        background-color: $backgroundGrey2;
      }
    }
  }

  label {
    margin-bottom: 4px;
  }

  .hide-time-subtitle {
    font-size: 12px;
  }

  ::v-deep {
    .multiselect {
      &__select {
        padding: 0;
        z-index: 501;
      }
      &__tags {
        border: 2px solid #e8e8e8;
        border-radius: 8px;
        font-size: 16px;
        padding: 6px 16px;
        .multiselect__input {
          padding: 0;
          margin: 0;
          font-size: 16px;
          padding-right: 26px;
          box-sizing: border-box;
          z-index: 500;
          border-radius: 0;
          height: 24px;
          &::placeholder {
            color: #95a6a6;
          }
        }
        .multiselect__placeholder {
          margin: 0;
          padding: 0;
          color: #95a6a6;
        }
      }

      &__content-wrapper {
        border-width: 2px;

        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &--above {
        .multiselect__content-wrapper {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &__single {
        margin: 0;
        padding: 0;
        line-height: 24px;
      }

      .multiselect__spinner {
        background: none;
        right: 25px;

        &:before,
        &:after {
          border-top-color: $primaryRed;
        }
      }
    }
  }
</style>
