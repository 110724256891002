import { REFRESH_TEMPLATE_STATUS } from '@/config/constants';
import { restrictToBounds } from '@/helpers/draggable';
import { round } from '@/helpers/utils';
import {
  UPDATE_TEMPLATE_WIDGET_STATE,
  SET_REFRESHING_TEMPLATE_STATE,
} from '@/store/actions/templateDesigner';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  watch: {
    value: {
      deep: true,
      async handler(widget) {
        if (
          this.refreshingState === REFRESH_TEMPLATE_STATUS.SELECTING ||
          this.refreshingState === REFRESH_TEMPLATE_STATUS.UPDATING
        ) {
          this.$store.commit(SET_REFRESHING_TEMPLATE_STATE, REFRESH_TEMPLATE_STATUS.STAND_BY);
          return;
        }

        this.$store.commit(SET_REFRESHING_TEMPLATE_STATE, REFRESH_TEMPLATE_STATUS.UPDATING);
        this.$store.commit(UPDATE_TEMPLATE_WIDGET_STATE, { widget });
      },
    },
  },

  computed: {
    layoutDimensions() {
      return this.$store.state.templateDesigner.template?.settings || { width: 200, height: 200 };
    },

    refreshingState() {
      return this.$store.state.templateDesigner.refreshingTemplateStatus;
    },

    storedWidget() {
      return this.$store.state.templateDesigner.selectedWidget;
    },

    isWidgetLocked() {
      return !!this.value.position.isLocked || this.isWidgetLockedByOverride;
    },

    isWidgetVisible() {
      return !this.value?.position.isHidden;
    },

    inOverrideMode() {
      return !!this.$route.query?.screen || !!this.$route.query?.group;
    },

    isWidgetLockedByOverride() {
      const isScreenOverrideMode = !!this.$route.query.screen && !this.$route.query.group;
      const isGroupOverride = this.value?.object.override?.screen_group;

      return !!(isScreenOverrideMode && isGroupOverride);
    },

    usePixels: {
      get() {
        return !!this.value.position.usePixels;
      },

      set(value) {
        this.$set(this.value.position, 'usePixels', value);
      },
    },

    width: {
      get() {
        const value = this.usePixels ? this.value.position.widthPixels : this.value.position.width;

        return round(value);
      },

      set(value) {
        const numberValue = Number(value);

        this.setDimensions(
          numberValue,
          this.layoutDimensions.width,
          this.xPosition,
          'width',
          'widthPixels',
        );
      },
    },

    height: {
      get() {
        const value = this.usePixels
          ? this.value.position.heightPixels
          : this.value.position.height;

        return round(value);
      },

      set(value) {
        const numberValue = Number(value);

        this.setDimensions(
          numberValue,
          this.layoutDimensions.height,
          this.yPosition,
          'height',
          'heightPixels',
        );
      },
    },

    xPosition: {
      get() {
        const value = this.usePixels ? this.value.position.xPixels : this.value.position.x;

        return round(value);
      },

      set(value) {
        const numberValue = Number(value);

        this.setDimensions(numberValue, this.layoutDimensions.width, this.width, 'x', 'xPixels', 0);
      },
    },

    yPosition: {
      get() {
        const value = this.usePixels ? this.value.position.yPixels : this.value.position.y;

        return round(value);
      },

      set(value) {
        const numberValue = Number(value);

        this.setDimensions(
          numberValue,
          this.layoutDimensions.height,
          this.height,
          'y',
          'yPixels',
          0,
        );
      },
    },
  },

  methods: {
    changeValuesMode() {
      if (this.isWidgetLockedByOverride) return;

      const { xPixels, yPixels, heightPixels, widthPixels } = this.value.position;
      const valueMissing = !xPixels || !yPixels || !heightPixels || !widthPixels;

      if ((!this.usePixels && this.usePixels !== false) || valueMissing) {
        this.updatePositionIntoPixels();
      }

      this.usePixels = !this.usePixels;
    },

    setDimensions(
      value,
      layoutDimmension,
      widgetDimmension,
      percentageKey,
      pixelsKey,
      defaultValue = 1,
    ) {
      let percentageValue;
      let pixelsValue;

      if (this.usePixels) {
        pixelsValue =
          typeof value === 'number'
            ? restrictToBounds(value, 0, layoutDimmension - widgetDimmension)
            : defaultValue;

        percentageValue = (pixelsValue * 100) / layoutDimmension;
      } else {
        percentageValue =
          typeof value === 'number'
            ? restrictToBounds(value, 0, 100 - widgetDimmension)
            : defaultValue;

        pixelsValue = (layoutDimmension * percentageValue) / 100;
      }

      this.$set(this.value.position, percentageKey, round(percentageValue, 3));
      this.$set(this.value.position, pixelsKey, pixelsValue);
    },

    updatePositionIntoPixels() {
      const { x, y, width, height } = this.value.position;

      const layoutHeight = this.layoutDimensions.height;
      const layoutWidth = this.layoutDimensions.width;

      const positionUpdated = {
        ...this.value.position,
        xPixels: (layoutWidth * x) / 100,
        yPixels: (layoutHeight * y) / 100,
        heightPixels: (layoutHeight * height) / 100,
        widthPixels: (layoutWidth * width) / 100,
      };

      this.$set(this.value, 'position', positionUpdated);
    },

    updateVisibility() {
      this.$set(this.value.position, 'isHidden', !this.value.position.isHidden);
    },
  },
};
