<template>
  <div class="group-item" :class="{ active: active, focused }">
    <div class="group-header group-breadcrumb" @click="handleHeaderClick(group)">
      <div v-if="currentCrumbIndex !== 0" class="back-button" @click="navigateBack">
        <i class="material-icons-outlined align-middle"> arrow_back </i>
      </div>
      <div class="group-path">
        <div v-for="pathItem in path" :key="pathItem.id" class="path-item">
          {{ pathItem.name }}
        </div>
      </div>
      <div class="dropdown dropdown-container" v-if="isAdmin">
        <i
          class="material-icons-outlined align-middle more-buttons dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Delete screen group"
          >more_vert</i
        >
        <div class="dropdown-menu dropdown-menu-right">
          <button class="dropdown-item" @click.prevent="handleDeleteGroup(path[currentCrumbIndex])">
            <i class="material-icons-outlined align-middle">delete</i>
            Delete
          </button>
        </div>
      </div>
    </div>
    <div class="group-body">
      <div class="group-body-section">
        <div class="amounts">
          <div class="amount">
            Screens: <span>{{ totalScreens }}</span>
          </div>
          <div class="amount">
            Groups: <span>{{ totalGroups }}</span>
          </div>
        </div>
        <ScreenRotationIcons
          :verticalScreens="verticalScreens"
          :horizontalScreens="horizontalScreens"
          :showEmptyScreens="true"
        />
      </div>
      <div class="group-body-section">
        <ConnectionLights
          :connected="connectedScreens"
          :disconnected="disconnectedScreens"
          :unknown="unknownScreens"
          :showEmptyLights="true"
        />
      </div>
    </div>
    <div class="group-footer" v-if="subGroups.length > 0">
      <div
        v-for="subGroup in subGroups"
        :key="subGroup.id"
        :class="['sub-group', subGroup.locked ? 'locked' : 'active']"
        @click="!subGroup.locked && navigateForward(subGroup)"
      >
        <span class="name">
          {{ subGroup.name }}
        </span>
        <i class="material-icons-outlined align-middle icon">{{
          !subGroup.locked ? 'arrow_forward' : 'lock'
        }}</i>
      </div>
    </div>
  </div>
</template>

<script>
  import { simpleTypeMixin } from '@/helpers';
  import ScreenRotationIcons from '@/components/common/screens/ScreenRotationIcons';
  import ConnectionLights from '@/components/common/screens/ConnectionLights.vue';
  import * as ScreensHelper from '@/helpers/screens';

  export default {
    props: {
      group: { type: Object | null, default: null },
      allGroups: { type: Array, default: [] },
      relatedScreens: { type: Array, default: [] },
      active: { type: Boolean, default: false },
      focused: { type: Boolean, default: false },
      selectedGroup: { type: Object | null, default: null },
      selectedRootGroup: { type: Object | null, default: null },
    },

    emits: ['selectGroup', 'deleteGroup', 'clickHeader'],

    mixins: [simpleTypeMixin],

    components: {
      ScreenRotationIcons,
      ConnectionLights,
    },

    data() {
      return {
        path: this.group !== null ? [this.group] : [],
      };
    },

    watch: {
      active(value) {
        // reset state
        if (!value) {
          this.path = this.group !== null ? [this.group] : [];
        }
      },
    },

    methods: {
      navigateForward(group) {
        this.path.push(group);
        this.$emit('selectGroup', this.group, group);
      },

      navigateBack() {
        this.path.pop();
        const prevGroup = this.path[this.path.length - 1];

        this.$emit('selectGroup', this.group, prevGroup);
      },

      calculatePath() {
        if (this.selectedRootGroup?.id !== this.group.id) return;
        let group = this.selectedGroup;
        let path = [];
        while (group.parent_id) {
          path.push(group);
          group = this.allGroups.find((g) => g.id === group.parent_id);
        }
        path.push(group);
        this.path = path.reverse();
      },

      handleHeaderClick(group) {
        if (!this.active) {
          this.$emit('clickHeader', group);
        }
      },

      handleDeleteGroup(group) {
        this.$emit('deleteGroup', this.group, group);
      },

      findFolderWithPathArray() {
        if (this.path.length === 1) return this.group;
        let currentFolder = this.group;
        for (let i = 1; i < this.path.length; i += 1) {
          currentFolder = currentFolder.contents.find((content) => content.name === this.path[i]);
        }
        return currentFolder;
      },
    },

    computed: {
      currentCrumbIndex() {
        return this.path.length - 1;
      },

      isSelectedChildOfCurrent() {
        return (
          this.selectedGroup?.id !== this.group.id && this.selectedRootGroup?.id === this.group.id
        );
      },

      subGroups() {
        if (this.isSelectedChildOfCurrent) {
          return this.allGroups.filter((g) => g.parent_id === this.selectedGroup.id);
        }
        return this.allGroups.filter((g) => g.parent_id === this.group.id);
      },

      currentRelatedScreens() {
        if (this.isSelectedChildOfCurrent) {
          return ScreensHelper.getRelatedScreens(
            this.allGroups,
            this.relatedScreens,
            this.selectedGroup.id,
          );
        }

        return this.relatedScreens;
      },

      totalScreens() {
        return this.currentRelatedScreens.length;
      },

      totalGroups() {
        return this.subGroups.length;
      },

      verticalScreens() {
        return this.currentRelatedScreens.filter((screen) => {
          return (
            !screen.screen_information?.orientation ||
            ['270d', 'portrait'].includes(screen?.screen_information?.orientation)
          );
        }).length;
      },

      horizontalScreens() {
        return this.currentRelatedScreens.filter((screen) => {
          return (
            !!screen.screen_information &&
            ['180d', 'landscape'].includes(screen?.screen_information?.orientation)
          );
        }).length;
      },

      connectedScreens() {
        return this.screenGroupStatus?.connected || 0;
      },

      disconnectedScreens() {
        return this.screenGroupStatus?.disconnected || 0;
      },

      unknownScreens() {
        return this.screenGroupStatus?.idle || 0;
      },

      screenGroupStatus() {
        return this.currentRelatedScreens.reduce(
          (acc, screen) => {
            let screenStatus = this.$store.state.screens.screensStatus.data.find(
              (s) => s.id === screen.id,
            );

            if (screenStatus) {
              if (screenStatus.connection_status === 0) {
                // screen is disconnected
                acc.disconnected += 1;
              } else if (screenStatus.connection_status === 1) {
                // screen is idle
                acc.idle += 1;
              } else {
                // screen is connected
                acc.connected += 1;
              }
            }

            return acc;
          },
          { connected: 0, disconnected: 0, idle: 0 },
        );
      },
      isAdmin() {
        return this.$store.getters.isOrganizationAdmin || this.$store.getters.isAdministrator;
      },
    },

    mounted() {
      this.calculatePath();
    },
  };
</script>

<style lang="scss" scoped>
  .group-item {
    background-color: white;
    box-shadow: 0px 0px 16px rgba(21, 21, 21, 0.02);
    padding: 16px 20px;
    border: 1px solid transparent;
    &.active {
      box-shadow:
        0px 0px 0px 2px rgba(227, 0, 58, 0.2),
        0px 0px 16px rgb(21 21 21 / 2%);
    }
    &.focused {
      border-color: $primaryRed;
    }

    .group-body {
      width: 100%;
      padding-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 6px;

      .group-body-section {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .amounts {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        font-size: 14px;
        line-height: 21px;
        color: $secondaryText;

        span {
          color: $primaryText;
          font-weight: 500;
        }
      }
    }

    .back-button {
      &:hover {
        cursor: pointer;

        i {
          color: $primaryRed;
        }
      }
    }

    .group-header {
      display: flex;
      gap: 16px;
      padding-bottom: 16px;
      align-items: center;
      cursor: pointer;

      &.group-breadcrumb {
        gap: 8px;

        .group-path {
          display: flex;
          gap: 8px;
          .path-item {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #6a6b6a;

            &:not(:last-child)::after {
              content: '/';
            }
            &:last-child {
              color: $primaryText;
            }
          }
        }
      }
    }

    .dropdown-container {
      margin-left: auto;
    }

    .group-body {
      padding-top: 16px;
      border-top: 1px solid $lineGrey2;

      .screens-total {
        display: block;
      }
    }

    .group-footer {
      padding-top: 16px;
      margin-top: 16px;
      border-top: 1px solid $lineGrey2;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .sub-group {
        border: 1px solid rgba(0, 0, 0, 0.04);
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
        padding: 8px 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        .name {
          flex: 1;
          font-size: 14px;
          line-height: 21px;
          color: $primaryText;
        }
        &.active {
          transition: background-color 0.3s ease;
          &:hover {
            background-color: #ececec;
            .icon {
              transform: translateX(5px);
            }
          }
          .icon {
            transition: transform 0.3s ease;
          }
        }
        &.locked {
          cursor: not-allowed;
        }
      }
    }
  }
</style>
