<template>
  <div class="clock-widget-panel scrollbar">
    <div class="form-group layout-input name">
      <label for="clock-name">Name</label>
      <input
        class="form-control"
        type="text"
        id="clock-name"
        placeholder="Name"
        maxlength="40"
        v-model="value.object.name"
      />
    </div>

    <div class="form-group show-date">
      <div class="custom-control custom-switch layouts">
        <input
          type="checkbox"
          class="custom-control-input"
          id="clock-show-date"
          v-model="value.object.showDate"
        />
        <label class="custom-control-label" for="clock-show-date">Show date</label>
      </div>
    </div>

    <div class="measurements-container">
      <div class="measurements">
        <div class="form-group hidden position">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">X</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-x"
                v-model="xPosition"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">Y</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="yPosition"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>

        <div class="form-group hidden size">
          <div class="form-row">
            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">W</span>
              </div>
              <input
                type="number"
                class="form-control"
                id="layer-width"
                v-model="width"
                :disabled="isWidgetLocked"
              />
            </div>

            <div class="input-group col">
              <div class="input-label">
                <span class="input-group-text">H</span>
              </div>
              <input
                type="number"
                class="form-control"
                v-model="height"
                :disabled="isWidgetLocked"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="extra-icons">
        <LockIcon v-show="isWidgetLocked" :locked="isWidgetLocked" />
        <VisibilityIcon :isVisible="isWidgetVisible" @clicked="updateVisibility" />
        <PixelIcon :usePixels="usePixels" @clicked="changeValuesMode" />
      </div>
    </div>

    <div class="sub-section-title">Text</div>

    <div class="form-group hidden">
      <div class="form-row text-css">
        <color-picker class="col" input-el-id="clock-text-color" v-model="value.object.textColor" />

        <div class="input-group col">
          <div class="input-label">
            <span class="input-group-text">
              <i class="material-icons-outlined align-middle">opacity</i>
            </span>
          </div>
          <input
            type="number"
            class="form-control"
            id="clock-opacity"
            v-model="value.object.opacity"
          />
        </div>
      </div>
    </div>

    <div class="form-group layout-input text-size input-la">
      <label for="text-size">Text size</label>

      <div class="input-group">
        <input
          type="number"
          min="0"
          max="100"
          class="form-control"
          id="text-size"
          v-model="textSize"
        />
      </div>
    </div>

    <div class="sub-section-title">Background</div>

    <div class="form-group hidden">
      <div class="form-row background-css">
        <color-picker
          class="col"
          input-el-id="clock-background-color"
          v-model="value.object.backgroundColor"
        />

        <div class="col input-group">
          <div class="input-label">
            <span class="input-group-text">
              <i class="material-icons-outlined align-middle">opacity</i>
            </span>
          </div>
          <input
            type="number"
            class="form-control"
            id="clock-background-opacity"
            v-model="value.object.backgroundOpacity"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ColorPicker from '@/components/ColorPicker.vue';
  import PixelIcon from '@/components/layoutDesigner/editor/settingPanel/PixelIcon.vue';
  import LockIcon from '@/components/layoutDesigner/editor/settingPanel/LockIcon.vue';
  import VisibilityIcon from '@/components/layoutDesigner/editor/settingPanel/VisibilityIcon.vue';

  import widgetMixin from '@/components/layoutDesigner/editor/settingPanel/widgetMixin';

  export default {
    name: 'ClockWidgetPanel',

    mixins: [widgetMixin],

    components: {
      ColorPicker,
      PixelIcon,
      LockIcon,
      VisibilityIcon,
    },

    computed: {
      textSize: {
        get() {
          return this.value.object.textSize;
        },
        set(value) {
          this.value.object.textSize = Number(value);
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .clock-widget-panel {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0px;
    padding-right: 10px;

    .sub-section-title {
      padding-top: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
    }
  }
</style>
