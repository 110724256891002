<template>
  <div class="layer-panel scrollbar">
    <ul class="layers list-unstyled">
      <draggable v-model="widgets" :item-key="(widget) => widget.object.wid" class="dragArea">
        <li
          v-for="widget in widgets"
          :key="widget.object.wid"
          class="layer"
          :class="{
            active: widget.object.wid === selectedWidget?.object.wid,
            selected: widget.object.wid === selectedLayer?.object.wid,
          }"
          @click="selectLayer(widget)"
        >
          <div class="layer-start">
            <i class="material-icons layer-icon drag-handle" title="Move layer">
              {{ getIconType(widget) }}
            </i>

            <span class="layer-name">{{ widget.object.name }}</span>
          </div>

          <i
            class="material-icons delete-icon"
            title="Delete layer"
            @click.stop="openDeleteWidgetModal(widget)"
          >
            delete_outline
          </i>
        </li>
      </draggable>
    </ul>

    <div v-if="Object.keys(widgets).length === 0" class="no-data">No widgets</div>

    <DeleteWidgetModal
      v-if="showDeleteWidgetModal"
      :widget="selectedWidget"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import DeleteWidgetModal from '@/components/layoutDesigner/designer/DeleteWidgetModal.vue';

  import {
    LAYOUT_DESIGNER_SELECT_WIDGET,
    LAYOUT_DESIGNER_UPDATE_WIDGET,
  } from '@/store/actions/layoutDesigner';

  import { getWidgetIcons } from '@/helpers/mixins';

  export default {
    name: 'LayerPanel',

    components: {
      DeleteWidgetModal,
      draggable,
    },

    data() {
      return {
        showDeleteWidgetModal: false,
        selectedWidget: null,
      };
    },

    computed: {
      layoutRotation() {
        return this.$store.getters.getLayoutRotation;
      },

      sortedWidgets() {
        return this.$store.getters.getWidgetsByZIndex;
      },

      widgets: {
        get() {
          return Object.values(this.$store.state.layoutDesigner.widgets).sort(
            (widget1, widget2) =>
              widget2.position[this.layoutRotation].zIndex -
              widget1.position[this.layoutRotation].zIndex,
          );
        },
        set(value) {
          // Update widget z-indexes after drag
          value.forEach((widget, index) => {
            const updatedWidget = {
              ...widget,
              position: {
                ...widget.position,
                [this.layoutRotation]: {
                  ...widget.position[this.layoutRotation],
                  zIndex: value.length - index,
                },
              },
            };
            this.$store.commit(LAYOUT_DESIGNER_UPDATE_WIDGET, {
              widget: updatedWidget,
              selectWidget: false,
            });
          });
        },
      },

      selectedLayer() {
        return this.$store.state.layoutDesigner.selectedWidget;
      },
    },

    methods: {
      openDeleteWidgetModal(widget) {
        this.selectedWidget = widget;
        this.showDeleteWidgetModal = true;
      },

      selectLayer(widget) {
        this.$store.commit(LAYOUT_DESIGNER_SELECT_WIDGET, widget);
      },

      getIconType(widget) {
        return getWidgetIcons(widget.itemType || widget.object.type);
      },

      closeModal() {
        this.showDeleteWidgetModal = false;
        this.selectedWidget = null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layer-panel {
    overflow-y: auto;
    overflow-x: hidden;

    .layers {
      .dragArea {
        min-height: 10px;
      }

      .layer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        padding: 6px 16px;
        cursor: pointer;

        .layer-start {
          display: flex;
          gap: 16px;
          align-items: center;
          justify-content: flex-start;
        }

        .layer-icon {
          cursor: move;
          color: $secondaryText;
        }

        &:hover {
          background-color: $highlightGrey;

          .layer-icon {
            color: $primaryText;
          }

          i.delete-icon {
            display: block;
          }
        }

        &.selected {
          background-color: $highlightGrey;
        }

        .layer-name {
          display: flex;
          align-items: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          gap: 16px;
          color: $primaryText;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }

        i.delete-icon {
          display: none;
          cursor: pointer;
          width: 24px;
          color: $iconsBlack;
        }
      }
    }
  }
</style>
