<script lang="ts">
  export default {
    name: 'LabelledInput',
  };
</script>

<script setup lang="ts">
  import { defineProps, withDefaults } from 'vue';
  import BaseText from '@ui/atoms/baseText/BaseText.vue';

  const emits = defineEmits<{
    (event: 'input', value: string): void;
  }>();

  const props = withDefaults(
    defineProps<{
      type: string;
      value?: string | null;
      label: string;
      labelColor: string;
      backgroundColor: string;
      labelOpacity: string;
    }>(),
    {
      type: 'text',
      label: 'Input',
      labelColor: '#000',
      backgroundColor: '#f8f8f8',
      labelOpacity: '1',
    },
  );

  function handleInput(e: KeyboardEvent) {
    emits('input', (e.target as HTMLInputElement).value);
  }
</script>

<template>
  <div class="wrapper">
    <BaseText
      class="label"
      variant="subtitle2"
      :color="labelColor"
      :style="{ opacity: labelOpacity }"
      >{{ label }}</BaseText
    >
    <input
      :type="type"
      v-model="value"
      @input="handleInput"
      :style="{ 'background-color': backgroundColor }"
    />
  </div>
</template>

<style lang="scss" scoped>
  .wrapper {
    border-radius: 8px;
    border: 2px solid $borderGrey;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    > input {
      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
      }
      border: none;
      &:focus-visible {
        outline: none;
      }
      font-size: 14px;
      font-weight: 500;
    }
  }
</style>
